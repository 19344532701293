import { ApolloError } from '@apollo/client';
import { FirebaseError } from 'firebase/app';
import localforage from 'localforage';
import { LocalForageDataType } from './types';

/* logger function */
export const logger = (error: Error | ApolloError | FirebaseError): void => {
  console.log('message', error.message);
  console.log('error name', error.name);
  // If ApolloError
  if ((error as ApolloError).graphQLErrors) {
    const apolloError = error as ApolloError;
    console.log('ApolloError.extraInfo', apolloError.extraInfo);
    console.log('ApolloError.graphQLErrors', apolloError.graphQLErrors);
  }

  if ((error as FirebaseError).code) {
    const firebaseErr = error as FirebaseError;
    console.log('FirebaseError.code', firebaseErr.code);
    console.log('FirebaseError.customData', firebaseErr.customData);
  }
  // any other type of error
  else {
    console.log(error.stack);
    console.log(error);
  }
};

/* to get sheet data from local forage */
export const getSheetDataFromLocalForage = async (): Promise<LocalForageDataType | null> => {
  try {
    /* retrieve stored data from local storage */
    const data = await localforage.getItem<LocalForageDataType>('sheetData');
    if (data) {
      return data;
    }
  } catch (error) {
    logger(error as Error);
  }
  return null;
};

/* function to handle save Session(shhet data) data*/
export const handleSaveSessionData = async (
  localForageData: LocalForageDataType,
): Promise<void> => {
  try {
    await localforage.setItem('sheetData', localForageData);
  } catch (err) {
    logger(err as Error);
  }
};
