import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ReactDataSheet from 'react-datasheet';
import { Row, Col, Table, Descriptions, Select } from 'antd';
import { MinusCircleFilled } from '@ant-design/icons';
import DatePickerDataEditorComponent from '../DatePickerDataEditorComponent';
import {
  DeathLossSheetIndividualGridElement,
  PigsAndBarnPigsInSheetGridElement,
  PigsAndBarnPigsSoldSheetGridElement,
  DeathLossSheetGridElement,
  CommonBulkUploadComponentType,
  RowColumnIndexType,
  ValidationErrorFunctionsType,
  SelectedRowColType,
} from '../../utils/types';
import {
  renderDeathLossSheetRows,
  getSerialNumberOfDate,
  renderSelectDataEditor,
  renderInfoTooltip,
  calculateFirstFillDate,
} from '../../utils/sheetHelpers';
import {
  deathReasons,
  dateColumnFormat,
  deathReasonsLabelValueData,
} from '../../utils/sheetGlobals';
import BulkUploadModalComponent from '../BulkUploadModalComponent';
import CommonBulkUploadComponent from '../CommonBulkUploadComponent';
import SheetActionButtonsComponent from '../SheetActionButtonsComponent';
import ShowWarningComponent from '../ShowWarningComponent';
import styles from './Sheets.module.scss';

// type definition for props
type DeathLossSheetPropsType = {
  // value of sheet data
  sheetData: DeathLossSheetGridElement[][];
  // used to update sheet data
  updateSheetData: React.Dispatch<React.SetStateAction<DeathLossSheetGridElement[][]>>;
  // stores the data of PigsAndBarnPigs in sheet
  pigsAndBarnPigsInSheetData: PigsAndBarnPigsInSheetGridElement[][];
  // stores the data of PigsAndBarnPigs sold sheet
  pigsAndBarnPigsSoldSheetData: PigsAndBarnPigsSoldSheetGridElement[][];
  /* variable to store row index and column index where validation has failed */
  deathLossSheetValidationErrors: Array<RowColumnIndexType>;
} & ValidationErrorFunctionsType &
  Omit<
    CommonBulkUploadComponentType,
    | 'isCommonModalVisible'
    | 'updateIsCommonModalVisible'
    | 'deathLossSheetData'
    | 'updateDeathLossSheetData'
  >;

// stores the value required for calculation from in sheet
let valueCalculatedFromInSheet = 0;

const { Option } = Select;

/* React functional component */
const DeathLossSheet: React.FC<DeathLossSheetPropsType> = ({
  sheetData,
  updateSheetData,
  pigsAndBarnPigsInSheetData,
  pigsAndBarnPigsSoldSheetData,
  productionExpensesSheetData,
  feedDeliverySheetData,
  updatePigsAndBarnPigsInSheetData,
  updateProductionExpensesSheetData,
  updateFeedDeliverySheetData,
  updatePigsAndBarnPigsSoldSheetData,
  showBulkUploadModal,
  setShowBulkUploadModal,
  showCommonBulkUploadModal,
  setShowCommonBulkUploadModal,
  activeTabKey,
  deathLossSheetValidationErrors,
  setPigsAndBarnPigsInValidationErrors,
  setDeathLossSheetValidationErrors,
  setPigsAndBarnPigsSoldValidationErrors,
  setProductionExpenseSheetValidationErrors,
  setFeedDeliverySheetValidationErrors,
  sessionType,
  selectedPigWtType,
}): JSX.Element => {
  /* state to store the row and col number */
  const [selectedRowCol, setSelectedRowCol] = useState<SelectedRowColType>({ row: -1, col: -1 });

  // stores the other info table data
  let tableData = deathReasons.map((reason) => {
    return { reason, dead: 0, percent: '0%' };
  });
  // stores the value of total dead
  let totalDead = 0;
  // calculates the value of total dead
  sheetData.forEach((item) => {
    if (item[1].value) {
      totalDead += Number(item[1].value);
    }
  });
  // calculates and updates the table data array with updated #dead column value
  sheetData.forEach((item) => {
    tableData = tableData.map((data) => {
      if (item[3].value === data.reason) {
        return {
          reason: data.reason,
          dead: data.dead + Number(item[1].value),
          percent: '',
        };
      }
      return data;
    });
  });
  // calculates and updates the table data array with updated percent column value
  tableData = tableData.map((data) => {
    // calculates percent value
    const percent = (data.dead / totalDead) * 100;
    return {
      reason: data.reason,
      dead: data.dead,
      percent: isNaN(percent) ? '-' : `${percent.toFixed(2)} %`,
    };
  });

  /* calculate death week on first render */
  const calculateDeathWeekOnFirstRender = useCallback(() => {
    valueCalculatedFromInSheet = getSerialNumberOfDate(
      calculateFirstFillDate(pigsAndBarnPigsInSheetData),
    );

    /* Variable to store sheet data stored in state locally for further operations */
    const grid = sheetData.map((row) => [...row]);

    grid.forEach((item, index) => {
      if (item[0].value && index !== 0) {
        /* Variable to store serial number for date selected by user in data sheet */
        const serialNumberOfDate = getSerialNumberOfDate(grid[index][0].value as string);
        grid[index][4] = {
          ...grid[index][4],
          value: (serialNumberOfDate - valueCalculatedFromInSheet) / 7,
        };
      }
    });
    updateSheetData(grid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pigsAndBarnPigsInSheetData]);

  /* to calculate death week with the help of pigs in sheet data */
  useEffect(() => {
    calculateDeathWeekOnFirstRender();
  }, [calculateDeathWeekOnFirstRender]);

  /* Function to render custom data editor based on column */
  const renderCustomDataEditor = (row: number, col: number, cellValue: string | number | null) => {
    if (col === 0) {
      /* Variable to store sheet data stored in state locally for further operations */
      const grid = sheetData.map((val) => [...val]);

      return (
        <DatePickerDataEditorComponent
          cellValue={cellValue}
          onChange={(date, event) => {
            /* For date column validation is added so that only specific format of date(MM-DD-YY) accepted by corresponding cell */
            grid[row][col] = {
              ...grid[row][col],
              value: date ? dayjs(date as Date).format(dateColumnFormat) : null,
            };

            /* to calculate death week */
            if (grid[row][0].value && valueCalculatedFromInSheet) {
              /* Variable to store serial number for date selected by user in data sheet */
              const serialNumberOfDate = getSerialNumberOfDate(grid[row][0].value as string);

              grid[row][4] = {
                ...grid[row][4],
                value: (serialNumberOfDate - valueCalculatedFromInSheet) / 7,
              };
            }

            if (event && event.type === 'click') {
              updateSheetData(grid);
            }
          }}
          onBlur={() => {
            updateSheetData(grid);
          }}
        />
      );
    }

    return renderSelectDataEditor(cellValue, 'deathLoss', (selectVal) => {
      /* Variable to store selected value from select */
      const value = selectVal.target.value;
      /* Variable to store sheet data stored in state locally for further operations */
      const grid = sheetData.map((val) => [...val]);

      // store selected reason by user
      const selectedReason = deathReasonsLabelValueData.find((val) => val.value === value);
      grid[row][col] = {
        ...grid[row][col],
        value: selectedReason ? selectedReason.label : null,
      };
      updateSheetData(grid);
    });
  };

  /* to calculate sum of dead pigs */
  const calculateSumOfDeadPigs = () => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[1].value) {
        result += Number(item[1].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on data :'HdOut' column */
  const calculateHdOut = (data: PigsAndBarnPigsSoldSheetGridElement[][]): number => {
    let result = 0;
    data.forEach((item) => {
      if (item[2].value) {
        result += Number(item[2].value);
      }
    });
    return result;
  };

  /* to calculate head */
  const calculateHead = () => {
    let result = 0;
    pigsAndBarnPigsInSheetData.forEach((item) => {
      if (item[1].value) {
        result += Number(item[1].value);
      }
    });
    return result;
  };

  /* to calculate running inventory */
  const calculateRunningInventory = () => {
    // sum of head - sum of hd out - sum of #dead [from death loss section]
    const runningInventory =
      calculateHead() - calculateHdOut(pigsAndBarnPigsSoldSheetData) - calculateSumOfDeadPigs();
    return runningInventory;
  };

  return (
    <>
      {activeTabKey === 3 ? (
        <>
          <BulkUploadModalComponent
            isModalVisible={showBulkUploadModal}
            updateIsModalVisible={setShowBulkUploadModal}
            sheetData={sheetData}
            updateSheetData={updateSheetData}
            calledFrom="deathLoss"
            pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
            setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
          />
          {/* common bulk upload */}
          <CommonBulkUploadComponent
            isCommonModalVisible={showCommonBulkUploadModal}
            updateIsCommonModalVisible={setShowCommonBulkUploadModal}
            pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
            updatePigsAndBarnPigsInSheetData={updatePigsAndBarnPigsInSheetData}
            pigsAndBarnPigsSoldSheetData={pigsAndBarnPigsSoldSheetData}
            updatePigsAndBarnPigsSoldSheetData={updatePigsAndBarnPigsSoldSheetData}
            deathLossSheetData={sheetData}
            updateDeathLossSheetData={updateSheetData}
            productionExpensesSheetData={productionExpensesSheetData}
            updateProductionExpensesSheetData={updateProductionExpensesSheetData}
            feedDeliverySheetData={feedDeliverySheetData}
            updateFeedDeliverySheetData={updateFeedDeliverySheetData}
            setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
            setPigsAndBarnPigsSoldValidationErrors={setPigsAndBarnPigsSoldValidationErrors}
            setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
            setProductionExpenseSheetValidationErrors={setProductionExpenseSheetValidationErrors}
            setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
            selectedPigWtType={selectedPigWtType}
            sessionType={sessionType}
          />
        </>
      ) : null}

      {Array.isArray(deathLossSheetValidationErrors) &&
      deathLossSheetValidationErrors.length > 0 ? (
        <ShowWarningComponent message="Please provide valid input in highlighted cells." />
      ) : null}

      <ReactDataSheet<DeathLossSheetGridElement, number>
        data={sheetData}
        valueRenderer={(cell) => cell.value}
        dataRenderer={(cell) => cell.value}
        overflow="nowrap"
        cellRenderer={({ children, row, col, cell, ...props }) => {
          if (row !== 0 && col === 0) {
            return (
              <td
                className={props.className}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{
                  ...props.style,
                  backgroundColor: deathLossSheetValidationErrors.find(
                    (item) => item.columnIndex === col && item.rowIndex === row,
                  )
                    ? '#ffedea'
                    : '#ffffff',
                }}
              >
                {renderCustomDataEditor(row, col, cell.value)}
              </td>
            );
          } else if (col === 3 && row !== 0) {
            return (
              <td
                className={props.className}
                onMouseOver={props.onMouseOver}
                onMouseDown={(event) => {
                  props.onMouseDown(event);
                  setTimeout(() => {
                    setSelectedRowCol({ row: row, col: 3 });
                  }, 200);
                }}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{
                  ...props.style,
                  backgroundColor: deathLossSheetValidationErrors.find(
                    (item) => item.columnIndex === col && item.rowIndex === row,
                  )
                    ? '#ffedea'
                    : '#ffffff',
                }}
              >
                <Select
                  style={{
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    cursor: 'cell',
                  }}
                  onSelect={(selectedVal) => {
                    /* Variable to store selected value from select */
                    const value = selectedVal;
                    /* Variable to store sheet data stored in state locally for further operations */
                    const grid = sheetData.map((val) => [...val]);

                    // store selected reason by user
                    const selectedReason = deathReasonsLabelValueData.find(
                      (val) => val.value === value,
                    );
                    grid[row][col] = {
                      ...grid[row][col],
                      value: selectedReason ? selectedReason.label : null,
                    };
                    updateSheetData(grid);
                    setSelectedRowCol({ row: -1, col: -1 });
                  }}
                  value={cell.value ? cell.value : undefined}
                  placeholder="Select reason"
                  open={selectedRowCol.row === row && selectedRowCol.col === col}
                >
                  {deathReasonsLabelValueData.map((item, index) => (
                    <Option
                      key={index.toString()}
                      value={item.value}
                      onMouseDown={(event: { stopPropagation: () => void }) => {
                        event.stopPropagation();
                      }}
                    >
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </td>
            );
          } else {
            /* Variable to store attributes of cellRenderer in react data sheet */
            const attributes = props.attributesRenderer
              ? props.attributesRenderer(cell, row, col)
              : {};

            return (
              <td
                className={props.className}
                onMouseDown={(event) => {
                  props.onMouseDown(event);

                  if (selectedRowCol.row !== -1 || selectedRowCol.col !== -1) {
                    setTimeout(() => {
                      setSelectedRowCol({ row: -1, col: -1 });
                    }, 100);
                  }
                }}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onTouchEnd={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{
                  ...props.style,
                  backgroundColor: deathLossSheetValidationErrors.find(
                    (item) => item.columnIndex === col && item.rowIndex === row,
                  )
                    ? '#ffedea'
                    : '#ffffff',
                }}
                {...attributes}
              >
                {children}
              </td>
            );
          }
        }}
        onCellsChanged={(props) => {
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = sheetData.map((row) => [...row]);

          props.forEach((item) => {
            /* Variable to store value which user added in cell in data sheet */
            const value: string | number | null = item.value;
            grid[item.row][item.col] =
              item.col > 0 && isNaN(Number(value)) && item.col !== 3
                ? { ...grid[item.row][item.col], value: 0 }
                : {
                    ...grid[item.row][item.col],
                    value,
                  };

            // for death week
            if (grid[item.row][0].value && valueCalculatedFromInSheet) {
              /* Variable to store serial number for date selected by user in data sheet */
              const serialNumberOfDate = getSerialNumberOfDate(grid[item.row][0].value as string);

              grid[item.row][4] = {
                ...grid[item.row][4],
                value: (serialNumberOfDate - valueCalculatedFromInSheet) / 7,
              };
            }
            /* the following logic ensures that user enters the correct fields in the given columns and if enters invalid number we show a red background */
            if (item.col === 1 || item.col === 2) {
              if (value) {
                /* row index is used to store the index of row where validation check has failed */
                let rowIndex: number | null = null;

                /* column index is used to store the column index where validation check has failed */
                let columnIndex: number | null = null;

                if (value <= 0) {
                  rowIndex = item.row;
                  columnIndex = item.col;
                } else if (item.col === 1 && !Number.isInteger(Number(value))) {
                  rowIndex = item.row;
                  columnIndex = item.col;
                } else {
                  setDeathLossSheetValidationErrors((prevState) =>
                    prevState.filter(
                      (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                    ),
                  );
                }
                if (rowIndex && columnIndex) {
                  setDeathLossSheetValidationErrors((prevState) => [
                    ...prevState,
                    { rowIndex: rowIndex as number, columnIndex: columnIndex as number },
                  ]);
                }
              } else {
                setDeathLossSheetValidationErrors((prevState) =>
                  prevState.filter(
                    (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                  ),
                );
              }
            }
          });

          /* Updating sheet data */
          updateSheetData(grid);
        }}
        valueViewer={(item) => {
          if (item.col === 0) {
            return (
              <div className={styles.formattedValuesCols}>
                {item.value ? dayjs(item.value).format(dateColumnFormat) : undefined}
              </div>
            );
          }
          if (item.col === 4 && item.value !== '') {
            if (item.value === 0) {
              return <div className={styles.formattedValuesCols}>{item.value}</div>;
            }
            return (
              <div className={styles.formattedValuesCols}>{Number(item.value).toFixed(2)}</div>
            );
          }
          return <div className={styles.formattedValuesCols}>{item.value}</div>;
        }}
        rowRenderer={(props) => {
          return (
            <tr style={{ height: 18 }}>
              {props.children}
              {props.row !== 0 ? (
                <td className="action-cell">
                  <MinusCircleFilled
                    className={styles.deleteIconBtn}
                    onClick={() => {
                      /* Variable to store sheet data stored in state locally for further operations */
                      const grid: DeathLossSheetGridElement[][] = [];
                      sheetData.forEach((item, index) => {
                        if (index !== props.row) {
                          grid.push(item);
                        }
                        /* after deleting a row updating the validation errors state */
                        const updatedValidationErrorsField = deathLossSheetValidationErrors.filter(
                          (obj) => obj.rowIndex !== props.row,
                        );

                        /* if a user deletes a random row then we have to adjust the row error indexes of rows after the deleted row */
                        const adjustRowIndexAfterDeletingARow = updatedValidationErrorsField.map(
                          (val) => {
                            if (val.rowIndex > props.row) {
                              return { rowIndex: val.rowIndex - 1, columnIndex: val.columnIndex };
                            }
                            return val;
                          },
                        );

                        setDeathLossSheetValidationErrors(adjustRowIndexAfterDeletingARow);
                      });
                      /* Updating sheet data */
                      updateSheetData(grid);
                    }}
                  />
                </td>
              ) : null}
            </tr>
          );
        }}
      />

      {/* SheetActionButtonsComponent */}
      <SheetActionButtonsComponent
        bulkUploadProps={{
          commonBulkUpload: setShowCommonBulkUploadModal,
          bulkUpload: setShowBulkUploadModal,
        }}
        addNewRowFunc={() => {
          /* Variable to store new row data to be added when new row btn clicked */
          const newRowData: DeathLossSheetIndividualGridElement[] = [
            {
              date: '',
              dead: undefined,
              weight: undefined,
              reason: '',
              deathWeek: undefined,
            },
          ];
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = [
            ...sheetData,
            ...newRowData.map((item) => [...renderDeathLossSheetRows(item)]),
          ];
          /* Updating sheet data */
          updateSheetData(grid);
          if (selectedRowCol.row !== -1 || selectedRowCol.col !== -1) {
            setSelectedRowCol({ row: -1, col: -1 });
          }
        }}
      />

      <Row className={styles.finalOpRow} style={{ width: 615 }}>
        <Col className={styles.finalOpCol}>-</Col>
        <Col className={styles.finalOpCol}>{totalDead || '-'}</Col>
        <Col className={styles.finalOpCol} style={{ width: 150 }}>
          -
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 135 }}>
          -
        </Col>
        <Col className={styles.finalOpCol}>-</Col>
      </Row>
      <div style={{ marginTop: 20 }}>
        <Descriptions title="Output Information">
          <Descriptions.Item label="Running Inventory">
            <>
              {renderInfoTooltip(
                <div style={{ color: 'black' }}>
                  Running inventory includes Pigs sold and Death Loss pig count
                </div>,
              )}

              <div className={styles.runningInventoryTxt}>{calculateRunningInventory() || 0}</div>
              {renderInfoTooltip(
                <div style={{ color: 'black' }}>
                  <div>Pigs In: {calculateHead() || '-'}</div>
                  <div>Pigs Out: {calculateHdOut(pigsAndBarnPigsSoldSheetData) || '-'}</div>
                  <div>Pigs Dead: {calculateSumOfDeadPigs() || '-'}</div>
                </div>,
              )}
            </>
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Table
        style={{ marginTop: 10, width: '35%' }}
        columns={[
          {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            align: 'center',
          },
          {
            title: '% of Total',
            dataIndex: 'percent',
            key: 'percent',
            align: 'center',
          },
          {
            title: '# Dead',
            dataIndex: 'dead',
            key: 'dead',
            align: 'center',
          },
        ]}
        dataSource={tableData}
        bordered
        pagination={false}
        size="small"
      />
    </>
  );
};

export default DeathLossSheet;
