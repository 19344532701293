import React from 'react';
import { Button } from 'antd';
import ViewReleaseFormComponent from '../components/ViewReleaseFormComponent';

/* React functional component */
const ReleaseFormPrintPreviewScreen = (): JSX.Element => {
  return (
    <div className="releaseForm" style={{ padding: '10px 40px' }}>
      <ViewReleaseFormComponent calledFrom="printPage" />
      <div style={{ display: 'flex', justifyContent: 'center' }} className="hideWhilePrinting">
        <Button
          size="large"
          onClick={() => {
            window.print();
          }}
        >
          Print
        </Button>
      </div>
    </div>
  );
};

export default ReleaseFormPrintPreviewScreen;
