import React from 'react';
import { WarningFilled } from '@ant-design/icons';
import styles from './ShowWarningComponent.module.scss';

/* ShowWarningComponent props type */
type ShowWarningComponentProps = {
  /* Message to show as warning in the component */
  message: string;
  /* Container styling */
  containerStyle?: React.CSSProperties;
};

/* React functional component */
const ShowWarningComponent = ({
  message,
  containerStyle,
}: ShowWarningComponentProps): JSX.Element => {
  return (
    <div className={styles.container} style={containerStyle}>
      <WarningFilled className={styles.icon} />
      {message}
    </div>
  );
};

export default ShowWarningComponent;
