import { initializeApp, getApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';

/* Firebase config type */
type FirebaseConfig = {
  [key: string]: {
    apiKey: string;
    authDomain: string;
    databaseURL?: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
  };
};

/* Variable to store firebase config from development */
const developmentFirebaseConfig: FirebaseConfig = {
  development: {
    apiKey: 'AIzaSyCId8-Rsz5YrYVARVbOwfN9dLVgyup7y98',
    authDomain: 'ecp38-development.firebaseapp.com',
    projectId: 'ecp38-development',
    storageBucket: 'ecp38-development.appspot.com',
    messagingSenderId: '14128654742',
    appId: '1:14128654742:web:ea8b7a0739c959c14695b7',
  },
  // staging: {},
  production: {
    apiKey: 'AIzaSyBlKlE7oZee6Yq0Rg0kwl48whZB3Qaw9Tw',
    authDomain: 'ecp38-production.firebaseapp.com',
    projectId: 'ecp38-production',
    storageBucket: 'ecp38-production.appspot.com',
    messagingSenderId: '136940507104',
    appId: '1:136940507104:web:8a37aef12e0f600a14e585',
    measurementId: 'G-XSN3NKV91W',
  },
};

export const app =
  getApps().length > 0
    ? getApp()
    : initializeApp(developmentFirebaseConfig[process.env.REACT_APP_ENVIRONMENT || 'development']);

export const firebaseAuth = getAuth(app);

export default app;
