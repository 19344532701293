import React from 'react';
import {
  Radio,
  RadioGroupProps as AntdRadioGroupProps,
  RadioProps,
  RadioChangeEvent,
  CheckboxOptionType,
} from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';

/* display mode type*/
type DisplayModeType = 'row' | 'column';

export interface RadioGroupProps
  extends Omit<AntdRadioGroupProps, 'name' | 'onChange' | 'value' | 'options'> {
  // The name which will be used by React Hook Form
  name: string;
  // The options for radio group
  options: Array<CheckboxOptionType>;
  // Should the radios be shown in row or column view
  displayMode?: DisplayModeType;
  // Props for each of the radio inputs
  radioProps?: RadioProps;
  // Props of React Hook Form controller
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rhfControllerProps: Omit<UseControllerProps<any>, 'name'>;
  // Custom logic for onChange
  onChange?: (e: RadioChangeEvent) => void;
}

// The default values for optional props
const optionalPropsDefaultValues = {
  displayMode: 'row' as DisplayModeType,
  radioProps: {},
  onChange: undefined,
};

const RadioGroup = ({
  displayMode = optionalPropsDefaultValues.displayMode,
  name,
  options,
  radioProps,
  rhfControllerProps,
  onChange,
  ...radioGroupProps
}: RadioGroupProps): JSX.Element => {
  const { field } = useController({
    name,
    ...rhfControllerProps,
  });

  // destructuring onChange function & rhfFields, and then will pass it to input field
  const { onChange: rhfOnChange, ...rhfFields } = field;

  return (
    <Radio.Group
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
        rhfOnChange(e.target.value);
      }}
      {...rhfFields}
      {...radioGroupProps}
    >
      {options.map((option) => {
        let radioStyle: React.CSSProperties = {};
        if (radioProps?.style) {
          radioStyle = radioProps?.style;
        }
        if (displayMode === 'column') {
          radioStyle.display = 'block';
          radioStyle.marginBottom = 5;
        }
        return (
          <Radio
            key={option.value.toString()}
            disabled={option.disabled}
            value={option.value}
            {...radioProps}
            style={radioStyle}
          >
            {option.label}
          </Radio>
        );
      })}
    </Radio.Group>
  );
};

RadioGroup.defaultProps = optionalPropsDefaultValues;

export default RadioGroup;
