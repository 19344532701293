import React from 'react';
import { Bar, BarChart, Label, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';

/* BarChartComponent props type */
type BarChartComponentPropsType = {
  /* Stores chart data to be rendered in the form of bar chart */
  chartData: Array<{
    name: string;
    totalCost: number | null;
  }>;
};

/* Customized axis ticks for bar chart */
const CustomizedAxisTick = (props: any) => {
  /* Props destructuring */
  const { x, y, payload, axis } = props as {
    x: number;
    y: number;
    payload: {
      value: string | number;
    };
    axis: 'x' | 'y';
  };

  return (
    <g
      transform={`translate(${x},${axis === 'x' ? y - 110 : y}) ${
        axis === 'x' ? `rotate(-90 60 60)` : ''
      }`}
    >
      <text x={0} y={0} textAnchor="end" style={{ fontSize: 12, color: '#333333' }}>
        {axis === 'y' ? `$ ${payload.value}` : payload.value}
      </text>
    </g>
  );
};

/* Customized bar label */
const CustomizedBarLabel = (props: any) => {
  /* Props destructuring */
  const { x, y, value, width } = props as {
    x: number;
    y: number;
    value: number;
    width: number;
  };

  return (
    <text
      x={x}
      dx={width / 2}
      y={y ? y - 10 : 0}
      textAnchor="middle"
      style={{ fontSize: 12, color: '#333333', fontWeight: 'bold' }}
    >
      {value ? `$ ${value.toFixed(2)}` : null}
    </text>
  );
};

/* React functional component */
const BarChartComponent = ({ chartData }: BarChartComponentPropsType): JSX.Element => {
  /* Checking if there are values for total cost bar present in chart data */
  const isTotalCostPresent = chartData.some((item) => item.totalCost !== null);

  return (
    <ResponsiveContainer width="100%" height="95%">
      <BarChart data={chartData} margin={{ top: 20 }}>
        {isTotalCostPresent ? (
          <CartesianGrid strokeDasharray="3" vertical={false} stroke="#C0C0C0" />
        ) : null}

        <XAxis
          dataKey="name"
          height={190}
          tickLine={false}
          tick={<CustomizedAxisTick axis="x" />}
          interval={0}
          padding={{ right: 10, left: 5 }}
        >
          <Label value="Accounts" fontWeight="bold" fontSize={16} color="#333333" dy={50} dx={15} />
        </XAxis>
        <YAxis tickLine={false} tick={<CustomizedAxisTick axis="y" />}>
          <Label
            value="Total Cost"
            angle={-90}
            fontWeight="bold"
            fontSize={14}
            color="#333333"
            dx={-15}
            dy={-48}
          />
        </YAxis>

        <Bar dataKey="totalCost" fill="#DFDFDF" stroke="#333333" label={<CustomizedBarLabel />} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
