import React from 'react';
import { Row, Col } from 'antd';
import styles from './FormItem.module.scss';

/* Type for display mode to decide show label and input in one row or not */
type DisplayModeType = 'row' | 'column';

// This is the types of props
type FormItemProps = {
  /* Label of form field */
  label?: string | JSX.Element;
  /* span which is passed in the <Col> for label */
  labelColSpan?: number;
  /* span which is passed in the <Col> for input */
  inputColSpan?: number;
  /* error text of the field */
  errorText?: string;
  /* when field is required then this is true */
  isRequired?: boolean;
  /* JSX element which we define inside this component */
  children: JSX.Element;
  /*  style which we pass from parent component */
  customStyle?: React.CSSProperties;
  /* Should the label and children be shown in row or column view */
  displayMode?: DisplayModeType;
  /*  style which we pass from parent component */
  labelStyle?: React.CSSProperties;
  /* to set the styling of errorText */
  errorTextStyle?: React.CSSProperties;
  /* children container style */
  containerStyle?: React.CSSProperties;
  /* Prop to decide where to show required mark */
  showRequiredMark?: 'before' | 'after';
};

/* React functional component */
const FormItem = ({
  label,
  labelColSpan = 3,
  inputColSpan = 21,
  errorText = '',
  isRequired = false,
  children,
  customStyle = {},
  displayMode = 'row' as DisplayModeType,
  labelStyle = {},
  errorTextStyle = {},
  containerStyle = {},
  showRequiredMark = 'before',
}: FormItemProps): JSX.Element => {
  /* Variables to store required mark class */
  const requiredMarkClass =
    showRequiredMark === 'before' ? 'requiredFieldBefore' : 'requiredFieldAfter';

  return (
    <Row className={styles.inputContainer} style={{ paddingTop: label ? 25 : 5, ...customStyle }}>
      {label && displayMode === 'row' ? (
        <Col
          span={labelColSpan}
          className={`${styles.labelName} ${isRequired ? requiredMarkClass : ''}`}
          style={labelStyle}
        >
          <label className="form-label">{label}</label>
        </Col>
      ) : null}
      <Col span={inputColSpan} style={label ? { paddingLeft: 30, ...containerStyle } : undefined}>
        {label && displayMode === 'column' ? (
          <div style={{ marginBottom: 5 }} className={`${isRequired ? requiredMarkClass : ''}`}>
            <label className="form-label" style={labelStyle}>
              {label}
            </label>
          </div>
        ) : null}
        {children}

        {errorText ? (
          <div className={styles.errorText} style={errorTextStyle}>
            {errorText}
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default FormItem;
