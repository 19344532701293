import React from 'react';
import { Button, Checkbox, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';

/* type for props of ColumnFilterComponent */
type ColumnFilterComponentProps = {
  /* prop type used to store table filter dropdown prop */
  filterDropDownProp: FilterDropdownProps;
};

/* React functional component */
const ColumnFilterComponent = ({ filterDropDownProp }: ColumnFilterComponentProps): JSX.Element => {
  /* destructing filter dropdown prop */
  const { selectedKeys, setSelectedKeys, confirm, clearFilters, filters } = filterDropDownProp;

  return (
    <div style={{ padding: 8 }}>
      {Array.isArray(filters) && filters.length > 0
        ? filters.map((item) => {
            return (
              <div>
                <Checkbox
                  checked={selectedKeys.includes(item.value as React.Key)}
                  onChange={(e) => {
                    /* Variable to store checked value by user */
                    const checkedValue = item.value as React.Key;
                    if (e.target.checked) {
                      if (!selectedKeys.includes(checkedValue)) {
                        setSelectedKeys([...selectedKeys, checkedValue]);
                      }
                    } else {
                      if (selectedKeys.includes(checkedValue)) {
                        setSelectedKeys(selectedKeys.filter((key) => key !== checkedValue));
                      }
                    }
                  }}
                >
                  {item.text}
                </Checkbox>
              </div>
            );
          })
        : null}

      <Space style={{ marginTop: 15 }}>
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
              confirm();
            }
          }}
          size="small"
          style={{ width: 90, borderRadius: 5 }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirm();
          }}
          size="small"
          style={{ width: 90, borderRadius: 5 }}
          className="buttonColorRed"
        >
          Ok
        </Button>
      </Space>
    </div>
  );
};

export default ColumnFilterComponent;
