import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, message, Row, Col } from 'antd';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  AddUserMutation,
  AddUserMutationVariables,
  Enum_User_Role_Enum,
  Enum_User_Type_Enum,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from '../graphql/graphql-types';
import FormItem from '../components/FormItem';
import Input from '../components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import style from './AddAndEditUserScreen.module.scss';
import { UserDataType } from '../utils/types';
import { logger } from '../utils/helpers';
import RadioGroup from '../components/RadioGroup';

/* loading the addUserMutation with the help of loader */
const addUserMutation = loader('../graphql/mutations/addUserMutation.graphql');

/* loading the update UserMutation with the help of loader */
const updateUserMutation = loader('../graphql/mutations/updateUserMutation.graphql');

/*types for  form fields */
type AddAndEditFormFields = Pick<
  User,
  'last_name' | 'first_name' | 'email' | 'account_number' | 'type'
> & {
  role: Enum_User_Role_Enum | undefined;
};

/* types for AddAndEditUserScreenProp */
type AddAndEditUserScreenProps = {
  /* to check which mode of button is active: Add or Save*/
  mode: 'Create' | 'Edit';
  /* to load the user data against which edit button in being clicked*/
  userData?: UserDataType | null;
  /* props to set the visibility of drawer when user hits save or cancel button in edit form*/
  closeDrawer?: () => void;
};

/* Variable to store custom form item style */
const customFormItemStyle = {
  marginBottom: 15,
};

// validations required for each input field
const schema = yup.object({
  first_name: yup
    .string()
    .required('Please enter first name and try again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only '),
  last_name: yup
    .string()
    .required('Please enter last name and try again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only '),
  email: yup
    .string()
    .email('Please enter a valid email id')
    .required('Please enter email id and try again'),
  role: yup.string().required('Please select role and try again'),
  type: yup.string().required('Please select user type and try again').nullable(),
});

/*React Functional Component */
const AddAndEditUserScreen = (props: AddAndEditUserScreenProps): JSX.Element => {
  /* props destructuring */
  const { mode, userData, closeDrawer } = props;

  /* state to set the loading state of button when the user clicks on Add or Save Button  */
  const [showBtnLoader, setShowButtonLoader] = useState<boolean>(false);

  /* mutation to add user */
  const [addUser] = useMutation<AddUserMutation, AddUserMutationVariables>(addUserMutation);

  /* mutation to edit user */
  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    updateUserMutation,
  );

  /*extracting navigate from useNavigate()*/
  const navigate = useNavigate();

  /* to give label Column  span depending upon the mode */
  const labelColSpan = mode === 'Edit' ? 6 : 5;

  /* to give input Column span */
  const inputColSpan = 18;

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<AddAndEditFormFields>({
    defaultValues: {
      first_name: userData ? userData.first_name : '',
      last_name: userData ? userData.last_name : '',
      email: userData ? userData.email : '',
      role: userData ? userData.role : undefined,
      type: userData ? userData.type : undefined,
      account_number: userData ? userData.account_number : '',
    },
    resolver: yupResolver(schema),
  });

  /* onSubmit function to handle the entered user data by the admin */
  const onSubmit = (data: AddAndEditFormFields): void => {
    setShowButtonLoader(true);
    if (mode === 'Create') {
      addUser({
        variables: {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          role: data.role as Enum_User_Role_Enum,
          type: data.type as Enum_User_Type_Enum,
          account_number: data.account_number || null,
        },
        update(cache, { data: addedUser }) {
          /* extracting the new data added by user */
          const dataToAdd = addedUser?.insert_user_one;
          cache.modify({
            fields: {
              user(existingUsers: Array<Reference>) {
                return [...existingUsers, dataToAdd];
              },
            },
          });
        },
      })
        .then(() => {
          reset();
          setShowButtonLoader(false);
          navigate('/users');
        })
        .catch((error: ApolloError) => {
          setShowButtonLoader(false);
          if (
            error.message ===
            'Uniqueness violation. duplicate key value violates unique constraint "user_email_key"'
          ) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('This email ID is already registered');
          }
          logger(error);
        });
    }
    if (mode === 'Edit' && userData)
      updateUser({
        variables: {
          id: userData.id,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          role: data.role,
          type: data.type as Enum_User_Type_Enum,
          account_number: data.account_number || null,
        },
        update(cache, { data: editedUser }) {
          /* extracting the Id of the edited data added by user */
          const dataToEdit = editedUser?.update_user_by_pk?.id;
          cache.modify({
            fields: {
              user(existingUsers: Array<Reference>, { readField }) {
                if (existingUsers.some((ref) => readField('id', ref) === dataToEdit)) {
                  return editedUser;
                }
                return existingUsers;
              },
            },
          });
        },
      })
        .then(() => {
          reset();
          if (closeDrawer) closeDrawer();
          setShowButtonLoader(false);
        })
        .catch((error: ApolloError) => {
          setShowButtonLoader(false);
          if (
            error.message ===
            'Uniqueness violation. duplicate key value violates unique constraint "user_email_key"'
          ) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('This email Id is already registered');
          }
          logger(error);
        });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem
        labelColSpan={labelColSpan}
        inputColSpan={inputColSpan}
        errorText={errors && errors.first_name ? errors.first_name.message : undefined}
        label="User first name:"
        errorTextStyle={{ textAlign: 'start' }}
        customStyle={customFormItemStyle}
        isRequired={true}
      >
        <Input
          customStyles={{ borderRadius: 5, border: '1px solid black' }}
          placeholder="Please enter first name of user"
          name="first_name"
          rhfControllerProps={{
            control,
          }}
        />
      </FormItem>

      <FormItem
        labelColSpan={labelColSpan}
        inputColSpan={inputColSpan}
        errorText={errors && errors.last_name ? errors.last_name.message : undefined}
        label="User last name:"
        errorTextStyle={{ textAlign: 'start' }}
        customStyle={customFormItemStyle}
        isRequired={true}
      >
        <Input
          customStyles={{
            borderRadius: 5,
            border: '1px solid black',
          }}
          name="last_name"
          placeholder="Please enter last name of user"
          rhfControllerProps={{
            control,
          }}
        />
      </FormItem>
      <FormItem
        labelColSpan={labelColSpan}
        inputColSpan={inputColSpan}
        errorText={errors && errors.email ? errors.email.message : undefined}
        label="Email ID:"
        errorTextStyle={{ textAlign: 'start', marginTop: -15 }}
        isRequired={true}
      >
        <>
          <Input
            customStyles={{
              borderRadius: 5,
              border: '1px solid black',
            }}
            name="email"
            placeholder="Please enter email ID of user"
            rhfControllerProps={{
              control,
            }}
          />
          <p className={style.emailUsedForLoginStyle}>This email will be used for login</p>
        </>
      </FormItem>
      <FormItem
        labelColSpan={labelColSpan}
        inputColSpan={inputColSpan}
        errorText={errors && errors.role ? errors.role.message : undefined}
        label="Role:"
        errorTextStyle={{ textAlign: 'start' }}
        isRequired={true}
        customStyle={{ paddingTop: 0, ...customFormItemStyle }}
      >
        <RadioGroup
          name="role"
          rhfControllerProps={{
            control,
          }}
          style={{ marginTop: 5 }}
          options={[
            { label: 'Admin', value: 'app_admin' },
            { label: 'User', value: 'user' },
          ]}
        />
      </FormItem>
      <FormItem
        labelColSpan={labelColSpan}
        inputColSpan={inputColSpan}
        errorText={errors && errors.type ? errors.type.message : undefined}
        label="User type:"
        errorTextStyle={{ textAlign: 'start' }}
        isRequired
        customStyle={{ paddingTop: 0, marginTop: 10 }}
      >
        <RadioGroup
          name="type"
          rhfControllerProps={{ control }}
          style={{ marginTop: 5 }}
          options={[
            { label: 'LOL Employee', value: Enum_User_Type_Enum.LolEmployee },
            { label: 'Coop/Dealer Employee', value: Enum_User_Type_Enum.DealerEmployee },
          ]}
        />
      </FormItem>
      <FormItem
        labelColSpan={labelColSpan}
        inputColSpan={inputColSpan}
        label="Customer Account #:"
        errorTextStyle={{ textAlign: 'start' }}
        customStyle={customFormItemStyle}
      >
        <Input
          customStyles={{
            borderRadius: 5,
            border: '1px solid black',
          }}
          name="account_number"
          placeholder="Please enter account number"
          rhfControllerProps={{ control }}
        />
      </FormItem>
      <Row style={{ marginTop: mode === 'Create' ? 55 : 25, marginBottom: 20 }}>
        <Col offset={mode === 'Create' ? 5 : 7}>
          <Button
            className={style.addAndSaveButtonStyle}
            htmlType="submit"
            loading={showBtnLoader}
            style={{ marginLeft: mode === 'Create' ? 30 : 10 }}
          >
            {mode === 'Create' ? 'Add' : 'Save'}
          </Button>
          <Button
            type="default"
            onClick={() => {
              if (closeDrawer) {
                closeDrawer();
              }
              reset();
              navigate('/users');
            }}
            className={style.cancelButtonStyle}
          >
            Cancel
          </Button>
        </Col>
      </Row>
      {/* </FormItem> */}
    </form>
  );
};

export default AddAndEditUserScreen;
