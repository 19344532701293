import React, { useState, useContext } from 'react';
import { UserContextDataType } from '../utils/types';

/* AppDataContext type */
type AppContextType = {
  /* User variable to store logged in user if any */
  user: UserContextDataType | null;
  /* setState to update logged in users value */
  setUser: React.Dispatch<React.SetStateAction<UserContextDataType | null>>;
};

/* Context created */
export const AppContext: React.Context<AppContextType> = React.createContext<AppContextType>({
  user: null,
  setUser: () => {},
});

/* Used to get user and setUser as a hook from context */
export const useApp = (): AppContextType => useContext<AppContextType>(AppContext);

/* Used to provide the values of context to children */
export const AppProvider = ({ children }: { children: JSX.Element }): JSX.Element | null => {
  /* State to store logged in user details */
  const [user, setUser] = useState<UserContextDataType | null>(null);

  return <AppContext.Provider value={{ user, setUser }}>{children}</AppContext.Provider>;
  return null;
};
