import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { loader } from 'graphql.macro';
import { useApolloClient } from '@apollo/client';
import localforage from 'localforage';
import LoadingScreen from './screens/LoadingScreen';
import LoginScreen from './screens/LoginScreen';
import { firebaseAuth } from './utils/firebase';
import { UserByIdQuery, UserByIdQueryVariables } from './graphql/graphql-types';
import { useApp } from './contexts/AppContext';
import { logger } from './utils/helpers';
import SetNewPasswordScreen from './screens/SetNewPasswordScreen';
import AppRoutes from './AppRoutes';
import './App.scss';
import { UserContextDataType } from './utils/types';

const userByIdQuery = loader('./graphql/queries/userByIdQuery.graphql');

/* React functional component */
const App = (): JSX.Element => {
  const apolloClient = useApolloClient();
  /* useApp context destructuring */
  const { setUser } = useApp();
  /* State holds the value for displaying loader , when app is loading then it shows loader */
  const [isAppLoading, setIsAppLoading] = useState<boolean>(true);
  /* This state to check user is logged in or not */
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  /* State to decide whether to show set new password screen to the user or not based on condition */
  const [showSetNewPasswordScreen, setShowSetNewPasswordScreen] = useState<boolean>(false);

  /* Timeout function that set loading state of application to false after 1 sec so that loading screen will get hide after it */
  const updateLoadingStateTimeoutFunc = () => {
    setTimeout(() => {
      setIsAppLoading(false);
    }, 2000);
  };

  useEffect(() => {
    const unregisterAuthStateChangedObserver = onAuthStateChanged(firebaseAuth, (userData) => {
      if (userData) {
        localforage
          .getItem<UserContextDataType>('user')
          .then((storedData) => {
            if (storedData && storedData.firebaseUid === userData.uid) {
              setUser(storedData);
              setIsUserLoggedIn(true);
              /* Show set new password screen when it's user's first login using email and password */
              if (!storedData.first_login_at) {
                setShowSetNewPasswordScreen(true);
              }

              updateLoadingStateTimeoutFunc();
            } else {
              apolloClient
                .query<UserByIdQuery, UserByIdQueryVariables>({
                  query: userByIdQuery,
                  variables: {
                    id: userData.uid,
                  },
                })
                .then((res) => {
                  if (res && res.data.user_by_pk) {
                    const userDataToSave = {
                      ...res.data.user_by_pk,
                      firebaseUid: userData.uid,
                    };

                    setUser(userDataToSave);

                    localforage
                      .setItem<UserContextDataType>('user', userDataToSave)
                      .then(() => {
                        setIsUserLoggedIn(true);
                        /* Show set new password screen when it's user's first login using email and password */
                        if (res.data.user_by_pk && !res.data.user_by_pk.first_login_at) {
                          setShowSetNewPasswordScreen(true);
                        }

                        updateLoadingStateTimeoutFunc();
                      })
                      .catch((err) => {
                        logger(err);
                        updateLoadingStateTimeoutFunc();
                      });
                  }
                })
                .catch((err) => {
                  logger(err);
                  setIsUserLoggedIn(false);
                });
            }
          })
          .catch((err) => {
            logger(err);
          });
      } else {
        updateLoadingStateTimeoutFunc();
        setIsUserLoggedIn(false);
        localforage.removeItem('user').catch((err) => {
          logger(err);
        });
      }
    });

    return (): void => {
      unregisterAuthStateChangedObserver();
    };
  }, [apolloClient, setUser]);

  /* Condition for display loader while checking for user is logged in or not */
  if (isAppLoading) {
    return <LoadingScreen />;
  }

  /* When user is logging in first time */
  if (showSetNewPasswordScreen) {
    return <SetNewPasswordScreen setShowSetNewPasswordScreen={setShowSetNewPasswordScreen} />;
  }

  /* When user is logged in successfully */
  if (isUserLoggedIn) {
    return <AppRoutes />;
  }

  return <LoginScreen />;
};

export default App;
