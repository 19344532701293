import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

/* React functional component */
const UserTabLayout = (): JSX.Element | null => {
  /* Tabs destructuring */
  const { TabPane } = Tabs;
  /* useLocation destructuring */
  const location = useLocation();
  /*extracting navigate from useNavigate()*/
  const navigate = useNavigate();

  return (
    <div className="userClass">
      <Tabs
        type="card"
        tabBarStyle={{
          marginTop: 40,
          paddingLeft: 20,
        }}
        tabBarGutter={0}
        activeKey={location.pathname.includes('create') ? '2' : '1'}
        onTabClick={(key: string) => {
          if (key === '2') navigate('create');
          else navigate('');
        }}
      >
        <TabPane
          tab="View all users"
          key="1"
          style={{ paddingLeft: 20, marginTop: 20, paddingRight: 20 }}
        >
          <Outlet />
        </TabPane>

        <TabPane tab="Add new user" key="2" style={{ marginTop: 30 }}>
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserTabLayout;
