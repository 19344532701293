import React, { useState } from 'react';
import { Table, Popconfirm, Drawer, Button, message } from 'antd';
import { Reference, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  WarningFilled,
  FilterFilled,
} from '@ant-design/icons';
import {
  AllUsersQuery,
  AllUsersQueryVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  Enum_User_Type_Enum,
} from '../graphql/graphql-types';
import AddUserPage from './AddAndEditUserScreen';
import { UserDataType } from '../utils/types';
import ColumnSearchComponent from '../components/ColumnSearchComponent';
import ColumnFilterComponent from '../components/ColumnFilterComponent';
import { useApp } from '../contexts/AppContext';
import styles from './ViewAllUserScreen.module.scss';

/* loading the allUserQuery  with the help of loader */
const allUsersQuery = loader('../graphql/queries/allUsersQuery.graphql');

/* loading the delete UserMutation with the help of loader */
const deleteUserMutation = loader('../graphql/mutations/deleteUserMutation.graphql');

/* React Functional Component */
const ViewAllUserScreen = (): JSX.Element => {
  /* extracting user from useApp */
  const { user } = useApp();

  /* useQuery to fetch All users data using allUserQuery */
  const { data, error, loading } = useQuery<AllUsersQuery, AllUsersQueryVariables>(allUsersQuery);

  /* delete user mutation */
  const [deleteUser] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    deleteUserMutation,
  );

  /* when user clicks on edit button,the state helps to store the selected user data */
  const [selectedUserData, setSelectedUserData] = useState<UserDataType | null>(null);

  /* to check weather the drawer should be visible or not */
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  /* useState to store selected user id when user hits delete button which will then use for loading indication*/
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);

  /*function to set drawer Visible to false and also to pass as a prop to Add And EditUser screen*/
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  /* function to delete the user selected by running mutation query and updating cache */
  const deleteSelectedUser = (deletedId: string) => {
    deleteUser({
      variables: { id: deletedId },
      update(cache, { data: deleteData }) {
        /* to get the id of the data to be deleted */
        const idToRemove = deleteData?.delete_user_by_pk?.id;
        cache.modify({
          fields: {
            user(existingUsers: Array<Reference>, { readField }) {
              if (idToRemove) {
                return existingUsers.filter((userData) => idToRemove !== readField('id', userData));
              }
              return existingUsers;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteUserId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('User has been successfully deleted');
      })
      .catch(() => {
        setDeleteUserId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('There was some problem in deleting the user. Pls try again');
      });
  };

  if (error) {
    return <div style={{ color: 'red' }}>{error.message}</div>;
  }

  return (
    <>
      <h3 style={{ fontWeight: 400, fontSize: 20 }}>Users Summary</h3>
      <hr style={{ backgroundColor: '#bab7b7', opacity: 0.3 }} />
      <Drawer
        title="Edit User profile"
        visible={isDrawerVisible}
        placement="right"
        width="37%"
        onClose={onClose}
        mask={false}
        style={{
          height: '100%',
        }}
        bodyStyle={{ padding: 0 }}
      >
        {isDrawerVisible ? (
          <AddUserPage
            mode="Edit"
            userData={selectedUserData ? selectedUserData : null}
            closeDrawer={onClose}
          />
        ) : null}
      </Drawer>
      <Table<UserDataType>
        dataSource={data && data.user ? data.user : []}
        pagination={false}
        className={styles.tableStyle}
        bordered
        loading={loading}
      >
        <Table.Column<UserDataType>
          key="name"
          title="Name"
          dataIndex="name"
          align="center"
          render={(text, record) => (
            <span
              style={{ textTransform: 'capitalize' }}
            >{`${record.first_name}  ${record.last_name}`}</span>
          )}
          onFilter={(value, record) =>
            record.first_name || record.last_name
              ? record.first_name
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase()) ||
                record.last_name.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false
          }
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? '#ce0e2d' : 'grey' }} />
          )}
        />
        <Table.Column key="email" title="Email" dataIndex="email" align="center" />
        <Table.Column<UserDataType>
          key="role"
          title="Role"
          dataIndex="role"
          align="center"
          render={(text, record) => (record.role === 'app_admin' ? 'Admin' : 'User')}
        />
        <Table.Column<UserDataType>
          key="type"
          title="User type"
          dataIndex="type"
          align="center"
          render={(text: Enum_User_Type_Enum) => {
            if (text) {
              return text === Enum_User_Type_Enum.LolEmployee
                ? 'LOL Employee'
                : 'Coop/Dealer Employee';
            }
            return '-';
          }}
          filters={[
            { text: 'LOL Employee', value: Enum_User_Type_Enum.LolEmployee },
            { text: 'Coop/Dealer Employee', value: Enum_User_Type_Enum.DealerEmployee },
          ]}
          onFilter={(value, record) => (record.type ? record.type === value : false)}
          filterIcon={(filtered) => (
            <FilterFilled style={{ color: filtered ? '#ce0e2d' : 'grey' }} />
          )}
          filterDropdown={(filterDropDownProp) => (
            <ColumnFilterComponent filterDropDownProp={filterDropDownProp} />
          )}
        />
        <Table.Column<UserDataType>
          key="first_login_at"
          title="Activation Date"
          dataIndex="first_login_at"
          align="center"
          render={(text, record) =>
            record.first_login_at ? dayjs(record.first_login_at).format('MM-DD-YYYY') : '-'
          }
        />
        <Table.Column<UserDataType>
          key="account_number"
          title="Account #"
          dataIndex="account_number"
          align="center"
          render={(text, record) => record.account_number || '-'}
        />
        <Table.Column<UserDataType>
          key="Options"
          title="Options"
          dataIndex="Options"
          align="center"
          render={(text, record) => {
            return (
              <>
                <Button
                  className={styles.editButtonStyle}
                  key={record.id}
                  onClick={() => {
                    setIsDrawerVisible(true);
                    setSelectedUserData(record);
                  }}
                  icon={<EditOutlined />}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete User. Are you sure?"
                  onConfirm={() => {
                    if (user && user.id === record.id) {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      message.error('Cannot delete own account');
                    } else {
                      deleteSelectedUser(record.id);
                      setDeleteUserId(record.id);
                    }
                  }}
                  okText="Yes"
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: '#CE0E2D' }} />}
                >
                  <Button
                    key={record.id}
                    onClick={() => {
                      setSelectedUserData(record);
                    }}
                    icon={<DeleteOutlined />}
                    className={styles.deleteButtonStyle}
                    loading={record.id === deleteUserId ? true : false}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </>
            );
          }}
        />
      </Table>
    </>
  );
};

export default ViewAllUserScreen;
