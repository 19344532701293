import React, { useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import dayjs from 'dayjs';
import { Control } from 'react-hook-form';
import { Col, Row, Select } from 'antd';
import { MinusCircleFilled } from '@ant-design/icons';
import DatePickerDataEditorComponent from '../DatePickerDataEditorComponent';
import { renderFeedDeliverySheetRows, renderSelectDataEditor } from '../../utils/sheetHelpers';
import {
  FeedDeliverySheetIndividualGridElement,
  FeedDeliverySheetGridElement,
  CommonBulkUploadComponentType,
  RowColumnIndexType,
  ValidationErrorFunctionsType,
  GeneralInformationFormFieldsType,
  SelectedRowColType,
} from '../../utils/types';
import { dateColumnFormat, feedTypeLabelValueData } from '../../utils/sheetGlobals';
import BulkUploadModalComponent from '../BulkUploadModalComponent';
import CommonBulkUploadComponent from '../CommonBulkUploadComponent';
import SheetActionButtonsComponent from '../SheetActionButtonsComponent';
import ShowWarningComponent from '../ShowWarningComponent';
import styles from './Sheets.module.scss';
import FormItem from '../FormItem';
import InputNumber from '../InputNumber';

/* FeedDeliverySheet props type */
type FeedDeliverySheetPropsType = {
  /* Variable to store sheet data to be rendered */
  sheetData: FeedDeliverySheetGridElement[][];
  /* Function to update sheet data */
  updateSheetData: React.Dispatch<React.SetStateAction<FeedDeliverySheetGridElement[][]>>;
  /* variable to store row index and column index where validation has failed */
  feedDeliverySheetValidationErrors: Array<RowColumnIndexType>;
  /* Form control for input information */
  formControl: Control<GeneralInformationFormFieldsType, any>;
} & ValidationErrorFunctionsType &
  Omit<
    CommonBulkUploadComponentType,
    | 'isCommonModalVisible'
    | 'updateIsCommonModalVisible'
    | 'feedDeliverySheetData'
    | 'updateFeedDeliverySheetData'
  >;

const { Option } = Select;

/* React functional component */
const FeedDeliverySheet = ({
  sheetData,
  updateSheetData,
  pigsAndBarnPigsInSheetData,
  productionExpensesSheetData,
  updatePigsAndBarnPigsInSheetData,
  updateProductionExpensesSheetData,
  pigsAndBarnPigsSoldSheetData,
  updatePigsAndBarnPigsSoldSheetData,
  deathLossSheetData,
  updateDeathLossSheetData,
  showBulkUploadModal,
  setShowBulkUploadModal,
  showCommonBulkUploadModal,
  setShowCommonBulkUploadModal,
  activeTabKey,
  setFeedDeliverySheetValidationErrors,
  feedDeliverySheetValidationErrors,
  setPigsAndBarnPigsInValidationErrors,
  setDeathLossSheetValidationErrors,
  setPigsAndBarnPigsSoldValidationErrors,
  setProductionExpenseSheetValidationErrors,
  formControl,
  selectedPigWtType,
  sessionType,
}: FeedDeliverySheetPropsType): JSX.Element => {
  /* state to store the row and col number */
  const [selectedRowCol, setSelectedRowCol] = useState<SelectedRowColType>({ row: -1, col: -1 });

  /* Function to do calculation based on sheet data 'Pounds Delivered' column */
  const calculateTotalPoundsDelivered = (): number => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[3].value) {
        result += Number(item[3].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on sheet data 'Actual Feed Cost' column */
  const calculateTotalActualFeedCost = (): number => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[4].value) {
        result += Number(item[4].value);
      }
    });
    return result;
  };

  /* function to do calculation based on 'pounds of corn' column */
  const calculateTotalPoundsOfCorn = () => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[5].value) {
        result += Number(item[5].value);
      }
    });
    return result;
  };

  /* function to do calculation based on 'cost of corn' column */
  const calculateTotalCostOfCorn = () => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[6].value) {
        result += Number(item[6].value);
      }
    });
    return result;
  };

  /* Function to render custom data editor based on column */
  const renderCustomDataEditor = (row: number, col: number, cellValue: string | number | null) => {
    if (col === 0) {
      /* Variable to store sheet data stored in state locally for further operations */
      const grid = sheetData.map((val) => [...val]);

      return (
        <DatePickerDataEditorComponent
          cellValue={cellValue}
          onChange={(date, event) => {
            /* For date column validation is added so that only specific format of date(MM-DD-YY) accepted by corresponding cell */
            grid[row][col] = {
              ...grid[row][col],
              value: date ? dayjs(date as Date).format(dateColumnFormat) : null,
            };

            if (event && event.type === 'click') {
              updateSheetData(grid);
            }
          }}
          onBlur={() => {
            updateSheetData(grid);
          }}
        />
      );
    }

    return renderSelectDataEditor(cellValue, 'feedDelivery', (selectVal) => {
      /* Variable to store selected value from select */
      const value = selectVal.target.value;
      /* Variable to store sheet data stored in state locally for further operations */
      const grid = sheetData.map((val) => [...val]);
      /* Variable to store selected feed type by user */
      const selectedFeedType = feedTypeLabelValueData.find((val) => val.value === value);

      grid[row][col] = {
        ...grid[row][col],
        value: selectedFeedType ? selectedFeedType.label : null,
      };

      updateSheetData(grid);
    });
  };

  return (
    <>
      {activeTabKey === 5 ? (
        <>
          <BulkUploadModalComponent
            isModalVisible={showBulkUploadModal}
            updateIsModalVisible={setShowBulkUploadModal}
            sheetData={sheetData}
            updateSheetData={updateSheetData}
            calledFrom="feedDelivery"
            setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
          />

          {/* common bulk upload modal */}
          <CommonBulkUploadComponent
            isCommonModalVisible={showCommonBulkUploadModal}
            updateIsCommonModalVisible={setShowCommonBulkUploadModal}
            pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
            updatePigsAndBarnPigsInSheetData={updatePigsAndBarnPigsInSheetData}
            pigsAndBarnPigsSoldSheetData={pigsAndBarnPigsSoldSheetData}
            updatePigsAndBarnPigsSoldSheetData={updatePigsAndBarnPigsSoldSheetData}
            deathLossSheetData={deathLossSheetData}
            updateDeathLossSheetData={updateDeathLossSheetData}
            productionExpensesSheetData={productionExpensesSheetData}
            updateProductionExpensesSheetData={updateProductionExpensesSheetData}
            feedDeliverySheetData={sheetData}
            updateFeedDeliverySheetData={updateSheetData}
            setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
            setPigsAndBarnPigsSoldValidationErrors={setPigsAndBarnPigsSoldValidationErrors}
            setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
            setProductionExpenseSheetValidationErrors={setProductionExpenseSheetValidationErrors}
            setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
            selectedPigWtType={selectedPigWtType}
            sessionType={sessionType}
          />
        </>
      ) : null}

      {Array.isArray(feedDeliverySheetValidationErrors) &&
      feedDeliverySheetValidationErrors.length > 0 ? (
        <ShowWarningComponent message="Please provide valid input in highlighted cells." />
      ) : null}

      {/* React data sheet */}
      <ReactDataSheet<FeedDeliverySheetGridElement, number>
        data={sheetData}
        valueRenderer={(cell) => cell.value}
        dataRenderer={(cell) => cell.value}
        overflow="nowrap"
        cellRenderer={({ children, row, col, cell, ...props }) => {
          if (row !== 0 && col === 0) {
            return (
              <td
                className={props.className}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{ ...props.style, backgroundColor: '#ffffff' }}
              >
                {renderCustomDataEditor(row, col, cell.value)}
              </td>
            );
          } else if (row !== 0 && col === 1) {
            return (
              <td
                className={props.className}
                onMouseOver={props.onMouseOver}
                onMouseDown={(event) => {
                  props.onMouseDown(event);
                  setTimeout(() => {
                    setSelectedRowCol({ row: row, col: 1 });
                  }, 200);
                }}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{
                  ...props.style,
                  backgroundColor: feedDeliverySheetValidationErrors.find(
                    (item) => item.columnIndex === col && item.rowIndex === row,
                  )
                    ? '#ffedea'
                    : '#ffffff',
                }}
              >
                <Select
                  style={{
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    cursor: 'cell',
                  }}
                  onSelect={(selectedVal) => {
                    /* Variable to store selected value from select */
                    const value = selectedVal;
                    /* Variable to store sheet data stored in state locally for further operations */
                    const grid = sheetData.map((val) => [...val]);

                    // store selected feed type
                    const selectedFeedType = feedTypeLabelValueData.find(
                      (val) => val.value === value,
                    );
                    grid[row][col] = {
                      ...grid[row][col],
                      value: selectedFeedType ? selectedFeedType.label : null,
                    };
                    updateSheetData(grid);
                    setSelectedRowCol({ row: -1, col: -1 });
                  }}
                  value={cell.value ? cell.value : undefined}
                  placeholder="Select feed type"
                  open={selectedRowCol.row === row && selectedRowCol.col === col}
                >
                  {feedTypeLabelValueData.map((item, index) => (
                    <Option
                      key={index.toString()}
                      value={item.value}
                      onMouseDown={(event: { stopPropagation: () => void }) => {
                        event.stopPropagation();
                      }}
                    >
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </td>
            );
          } else {
            /* Variable to store attributes of cellRenderer in react data sheet */
            const attributes = props.attributesRenderer
              ? props.attributesRenderer(cell, row, col)
              : {};

            return (
              <td
                className={props.className}
                onMouseDown={(event) => {
                  props.onMouseDown(event);

                  if (selectedRowCol.row !== -1 || selectedRowCol.col !== -1) {
                    setTimeout(() => {
                      setSelectedRowCol({ row: -1, col: -1 });
                    }, 100);
                  }
                }}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onTouchEnd={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{
                  ...props.style,
                  backgroundColor: feedDeliverySheetValidationErrors.find(
                    (item) => item.columnIndex === col && item.rowIndex === row,
                  )
                    ? '#ffedea'
                    : '#ffffff',
                }}
                {...attributes}
              >
                {children}
              </td>
            );
          }
        }}
        onCellsChanged={(props) => {
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = sheetData.map((row) => [...row]);

          props.forEach((item) => {
            /* Variable to store entered value by user in specific cell */
            const value: string | number | null = item.value;

            /* Following condition is added for those columns where user should only enter number */
            /* here if user enters anything other than number like string or character then that cell's value will be updated with 0 */

            grid[item.row][item.col] =
              item.col > 2 && isNaN(Number(value))
                ? { ...grid[item.row][item.col], value: 0 }
                : {
                    ...grid[item.row][item.col],
                    value,
                  };

            /* Code commented for future use */
            /* Below logic is added for delivery input validation as entered value in it should be a integer grater than 0 */
            // if (item.col === 0) {
            //   if (value) {
            //     if (isNaN(Number(value))) {
            //       setFeedDeliverySheetValidationErrors((prevState) => [
            //         ...prevState,
            //         { rowIndex: item.row, columnIndex: item.col },
            //       ]);
            //     } else if (Number(value) <= 0 || !Number.isInteger(Number(value))) {
            //       setFeedDeliverySheetValidationErrors((prevState) => [
            //         ...prevState,
            //         { rowIndex: item.row, columnIndex: item.col },
            //       ]);
            //     } else {
            //       setFeedDeliverySheetValidationErrors((prevState) =>
            //         prevState.filter(
            //           (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
            //         ),
            //       );
            //     }

            //     /* Following logic insures that if user enters 1.0 or 2.0000 then it should be converted to 1 while storing it in sheet data */
            //     grid[item.row][item.col] = {
            //       ...grid[item.row][item.col],
            //       value:
            //         !isNaN(Number(value) % 1) && Number(value) % 1 === 0
            //           ? Math.trunc(Number(value))
            //           : value,
            //     };
            //   } else {
            //     setFeedDeliverySheetValidationErrors((prevState) =>
            //       prevState.filter(
            //         (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
            //       ),
            //     );
            //   }
            // }

            /* the following logic ensures that user enters the correct fields in the given columns and if enters invalid number we show a red background */
            if (item.col === 3 || item.col === 4 || item.col === 5 || item.col === 6) {
              if (value) {
                /* row index is used to store the index of row where validation check has failed */
                let rowIndex: number | null = null;

                /* column index is used to store the column index where validation check has failed */
                let columnIndex: number | null = null;

                if (value < 0 && (item.col === 4 || item.col === 6)) {
                  rowIndex = item.row;
                  columnIndex = item.col;
                } else if (value <= 0 && (item.col === 3 || item.col === 5)) {
                  rowIndex = item.row;
                  columnIndex = item.col;
                } else {
                  setFeedDeliverySheetValidationErrors((prevState) =>
                    prevState.filter(
                      (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                    ),
                  );
                }
                if (rowIndex && columnIndex) {
                  setFeedDeliverySheetValidationErrors((prevState) => [
                    ...prevState,
                    { rowIndex: rowIndex as number, columnIndex: columnIndex as number },
                  ]);
                }
              } else {
                setFeedDeliverySheetValidationErrors((prevState) =>
                  prevState.filter(
                    (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                  ),
                );
              }
            }
          });
          /* Updating sheet data */
          updateSheetData(grid);
        }}
        rowRenderer={(props) => {
          return (
            <tr style={{ height: 18 }}>
              {props.children}
              {props.row !== 0 ? (
                <td className="action-cell">
                  <MinusCircleFilled
                    className={styles.deleteIconBtn}
                    onClick={() => {
                      /* Variable to store sheet data stored in state locally for further operations */
                      const grid: FeedDeliverySheetGridElement[][] = [];
                      sheetData.forEach((item, index) => {
                        if (index !== props.row) {
                          grid.push(item);
                        }
                      });

                      /* after deleting a row updating the validation errors state */
                      const updatedValidationErrorsField = feedDeliverySheetValidationErrors.filter(
                        (item) => item.rowIndex !== props.row,
                      );

                      /* if a user deletes a random row then we have to adjust the row error indexes of rows after the deleted row */
                      const adjustRowIndexAfterDeletingARow = updatedValidationErrorsField.map(
                        (item) => {
                          if (item.rowIndex > props.row) {
                            return { rowIndex: item.rowIndex - 1, columnIndex: item.columnIndex };
                          }
                          return item;
                        },
                      );

                      setFeedDeliverySheetValidationErrors(adjustRowIndexAfterDeletingARow);

                      /* Updating sheet data */
                      updateSheetData(grid);
                    }}
                  />
                </td>
              ) : null}
            </tr>
          );
        }}
        valueViewer={(props) => {
          if (props.col === 0) {
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? dayjs(props.value).format(dateColumnFormat) : undefined}
              </div>
            );
          }
          if (props.col === 3 || props.col === 4 || props.col === 6) {
            if (props.value === 0) {
              return <div className={styles.formattedValuesCols}>{props.value}</div>;
            }
            return (
              <div className={styles.formattedValuesCols}>
                {props.value
                  ? `${props.col !== 3 ? '$' : ''} ${Number(props.value).toFixed(2)}`
                  : undefined}
              </div>
            );
          }

          return <div className={styles.formattedValuesCols}>{props.value}</div>;
        }}
      />

      {/* SheetActionButtonsComponent */}
      <SheetActionButtonsComponent
        bulkUploadProps={{
          commonBulkUpload: setShowCommonBulkUploadModal,
          bulkUpload: setShowBulkUploadModal,
        }}
        addNewRowFunc={() => {
          /* Variable to store new row data to be added when new row btn clicked */
          const newRowData: FeedDeliverySheetIndividualGridElement[] = [
            {
              // delivery: undefined,
              date: '',
              feedType: '',
              poundsDelivered: undefined,
              actualFeedCost: undefined,
              feedDetails: '',
              poundsOfCorn: undefined,
              costOfCorn: undefined,
            },
          ];
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = [
            ...sheetData,
            ...newRowData.map((item) => [...renderFeedDeliverySheetRows(item)]),
          ];
          /* Updating sheet data */
          updateSheetData(grid);
          if (selectedRowCol.row !== -1 || selectedRowCol.col !== -1) {
            setSelectedRowCol({ row: -1, col: -1 });
          }
        }}
      />

      {/* Columns calculated values */}
      <Row className={styles.finalOpRow} style={{ width: 1230, marginBottom: 20 }}>
        {/* <Col className={styles.finalOpCol}>-</Col> */}
        <Col className={styles.finalOpCol}>-</Col>
        <Col className={styles.finalOpCol} style={{ width: 145 }}>
          -
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 165 }}>
          -
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 155 }}>
          {calculateTotalPoundsDelivered() ? `${calculateTotalPoundsDelivered().toFixed(2)}` : '-'}
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 165 }}>
          {calculateTotalActualFeedCost() ? `$ ${calculateTotalActualFeedCost().toFixed(2)}` : '-'}
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 165 }}>
          {calculateTotalPoundsOfCorn() ? calculateTotalPoundsOfCorn().toFixed(2) : '-'}
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 185 }}>
          {calculateTotalCostOfCorn() ? `$ ${calculateTotalCostOfCorn().toFixed(2)}` : '-'}
        </Col>
      </Row>

      <div style={{ marginBottom: 80, width: 900 }}>
        <div style={{ fontSize: 18, fontWeight: 'bold' }}>Feed information </div>
        <FormItem label="Feed Left in Bin" labelStyle={{ textAlign: 'start', alignSelf: 'center' }}>
          <InputNumber
            name="feedLeft"
            rhfControllerProps={{ control: formControl }}
            placeholder="Please enter feed left"
            customStyles={{ width: 300 }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
      </div>
    </>
  );
};

export default FeedDeliverySheet;
