import React, { useState, useEffect, useCallback } from 'react';
import ReactDataSheet from 'react-datasheet';
import { Col, Row, Select } from 'antd';
import { MinusCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import DatePickerDataEditorComponent from '../DatePickerDataEditorComponent';
import BulkUploadModalComponent from '../BulkUploadModalComponent';
import CommonBulkUploadComponent from '../CommonBulkUploadComponent';
import SheetActionButtonsComponent from '../SheetActionButtonsComponent';
import BarChartComponent from '../charts/BarChartComponent';
import {
  ProductionExpensesSheetGridElement,
  ProductionExpensesSheetIndividualGridElement,
  CommonBulkUploadComponentType,
  RowColumnIndexType,
  ValidationErrorFunctionsType,
  SelectedRowColType,
} from '../../utils/types';
import {
  renderProductionExpensesSheetRows,
  renderSelectDataEditor,
} from '../../utils/sheetHelpers';
import {
  chartOfAccounts,
  chartOfAccountsLabelValueData,
  dateColumnFormat,
} from '../../utils/sheetGlobals';
import ShowWarningComponent from '../ShowWarningComponent';
import styles from './Sheets.module.scss';

/* ProductionExpensesSheet props type */
type ProductionExpensesSheetPropsType = {
  /* Variable to store sheet data to be rendered */
  sheetData: ProductionExpensesSheetGridElement[][];
  /* Function to update sheet data */
  updateSheetData: React.Dispatch<React.SetStateAction<ProductionExpensesSheetGridElement[][]>>;
  /* variable to store row index and column index where validation has failed */
  productionExpenseSheetValidationErrors: Array<RowColumnIndexType>;
} & ValidationErrorFunctionsType &
  Omit<
    CommonBulkUploadComponentType,
    | 'isCommonModalVisible'
    | 'updateIsCommonModalVisible'
    | 'productionExpensesSheetData'
    | 'updateProductionExpensesSheetData'
  >;

const { Option } = Select;

/* React functional functional */
const ProductionExpensesSheet = ({
  sheetData,
  updateSheetData,
  pigsAndBarnPigsInSheetData,
  feedDeliverySheetData,
  updatePigsAndBarnPigsInSheetData,
  updateFeedDeliverySheetData,
  pigsAndBarnPigsSoldSheetData,
  updatePigsAndBarnPigsSoldSheetData,
  deathLossSheetData,
  updateDeathLossSheetData,
  showBulkUploadModal,
  setShowBulkUploadModal,
  showCommonBulkUploadModal,
  setShowCommonBulkUploadModal,
  activeTabKey,
  setPigsAndBarnPigsInValidationErrors,
  setDeathLossSheetValidationErrors,
  setPigsAndBarnPigsSoldValidationErrors,
  setProductionExpenseSheetValidationErrors,
  setFeedDeliverySheetValidationErrors,
  productionExpenseSheetValidationErrors,
  selectedPigWtType,
  sessionType,
}: ProductionExpensesSheetPropsType): JSX.Element => {
  /* state to store the row and col number */
  const [selectedRowCol, setSelectedRowCol] = useState<SelectedRowColType>({ row: -1, col: -1 });

  /* State to store data to be shown in the form of bar chart */
  const [barChartData, setBarChartData] = useState<
    Array<{ name: string; totalCost: number | null }>
  >([]);

  /* Function to do calculation based on sheet data 'Total Value' column */
  const calculateTotalCost = useCallback((): number => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[3].value && item[2].value !== 'Yardage-Revenue') {
        result += Number(item[3].value);
      } else {
        result -= Number(item[3].value);
      }
    });
    return result;
  }, [sheetData]);

  useEffect(() => {
    /* Variable to store chart data after calculations (initially empty array) */
    const data: Array<{ name: string; totalCost: number | null }> = [];
    /* Here for all type of accounts total cost will get calculated whenever sheet data gets updated */
    chartOfAccounts.forEach((val) => {
      if (val !== 'Total') {
        if (val !== 'Yardage-Revenue') {
          let total = 0;
          sheetData.slice(1).forEach((item) => {
            if (item[2].value && item[2].value === val) {
              total = item[3].value ? total + Number(item[3].value) : total + 0;
            }
          });
          data.push({
            name: val,
            totalCost: total || null,
          });
        }
      } else {
        /* This calculation is for total field added only in bar chart as last bar */
        data.push({
          name: val,
          totalCost: calculateTotalCost() || null,
        });
      }
    });

    /* Updating bar chart data state */
    setBarChartData(data);
  }, [calculateTotalCost, sheetData]);

  /* Function to render custom data editor based on column */
  const renderCustomDataEditor = (row: number, col: number, cellValue: string | number | null) => {
    if (col === 0) {
      /* storing sheet data in locally */
      const grid = sheetData.map((val) => [...val]);

      return (
        <DatePickerDataEditorComponent
          cellValue={cellValue}
          onChange={(date, event) => {
            /* For date column validation is added so that only specific format of date(MM-DD-YY) accepted by corresponding cell */
            grid[row][col] = {
              ...grid[row][col],
              value: date ? dayjs(date as Date).format(dateColumnFormat) : null,
            };

            if (event && event.type === 'click') {
              updateSheetData(grid);
            }
          }}
          onBlur={() => {
            updateSheetData(grid);
          }}
        />
      );
    }

    return renderSelectDataEditor(cellValue, 'productionExpenses', (selectVal) => {
      /* Variable to store selected value from select */
      const value = selectVal.target.value;
      /* Variable to store sheet data stored in state locally for further operations */
      const grid = sheetData.map((val) => [...val]);

      /* Variable to store selected account by user */
      const selectedAccount = chartOfAccountsLabelValueData.find((val) => val.value === value);
      grid[row][col] = {
        ...grid[row][col],
        value: selectedAccount ? selectedAccount.label : null,
      };

      updateSheetData(grid);
    });
  };

  return (
    <>
      {activeTabKey === 4 ? (
        <>
          {/* Bulk Upload modal */}
          <BulkUploadModalComponent
            isModalVisible={showBulkUploadModal}
            updateIsModalVisible={setShowBulkUploadModal}
            sheetData={sheetData}
            updateSheetData={updateSheetData}
            calledFrom="productionExpenses"
            setProductionExpenseSheetValidationErrors={setProductionExpenseSheetValidationErrors}
          />

          {/* Common bulk upload modal */}
          <CommonBulkUploadComponent
            isCommonModalVisible={showCommonBulkUploadModal}
            updateIsCommonModalVisible={setShowCommonBulkUploadModal}
            pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
            updatePigsAndBarnPigsInSheetData={updatePigsAndBarnPigsInSheetData}
            pigsAndBarnPigsSoldSheetData={pigsAndBarnPigsSoldSheetData}
            updatePigsAndBarnPigsSoldSheetData={updatePigsAndBarnPigsSoldSheetData}
            deathLossSheetData={deathLossSheetData}
            updateDeathLossSheetData={updateDeathLossSheetData}
            productionExpensesSheetData={sheetData}
            updateProductionExpensesSheetData={updateSheetData}
            feedDeliverySheetData={feedDeliverySheetData}
            updateFeedDeliverySheetData={updateFeedDeliverySheetData}
            setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
            setPigsAndBarnPigsSoldValidationErrors={setPigsAndBarnPigsSoldValidationErrors}
            setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
            setProductionExpenseSheetValidationErrors={setProductionExpenseSheetValidationErrors}
            setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
            selectedPigWtType={selectedPigWtType}
            sessionType={sessionType}
          />
        </>
      ) : null}

      {Array.isArray(productionExpenseSheetValidationErrors) &&
      productionExpenseSheetValidationErrors.length > 0 ? (
        <ShowWarningComponent message="Please provide valid input in highlighted cells." />
      ) : null}

      {/* React data sheet */}
      <ReactDataSheet<ProductionExpensesSheetGridElement, number>
        data={sheetData}
        valueRenderer={(cell) => cell.value}
        dataRenderer={(cell) => cell.value}
        overflow="nowrap"
        cellRenderer={({ children, row, col, cell, ...props }) => {
          if (row !== 0 && col === 0) {
            return (
              <td
                className={props.className}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{ ...props.style, backgroundColor: '#ffffff' }}
              >
                {renderCustomDataEditor(row, col, cell.value)}
              </td>
            );
          } else if (row !== 0 && col === 2) {
            return (
              <td
                className={props.className}
                onMouseOver={props.onMouseOver}
                onMouseDown={(event) => {
                  props.onMouseDown(event);
                  setTimeout(() => {
                    setSelectedRowCol({ row: row, col: 2 });
                  }, 200);
                }}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{
                  ...props.style,
                  backgroundColor: productionExpenseSheetValidationErrors.find(
                    (item) => item.columnIndex === col && item.rowIndex === row,
                  )
                    ? '#ffedea'
                    : '#ffffff',
                }}
              >
                <Select
                  style={{
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    cursor: 'cell',
                  }}
                  onSelect={(selectedVal) => {
                    /* Variable to store selected value from select */
                    const value = selectedVal;
                    /* Variable to store sheet data stored in state locally for further operations */
                    const grid = sheetData.map((val) => [...val]);

                    // store selected account
                    const selectedAccount = chartOfAccountsLabelValueData.find(
                      (val) => val.value === value,
                    );
                    grid[row][col] = {
                      ...grid[row][col],
                      value: selectedAccount ? selectedAccount.label : null,
                    };
                    updateSheetData(grid);
                    setSelectedRowCol({ row: -1, col: -1 });
                  }}
                  value={cell.value ? cell.value : undefined}
                  placeholder="Select account"
                  open={selectedRowCol.row === row && selectedRowCol.col === col}
                >
                  {chartOfAccountsLabelValueData.map((item, index) => (
                    <Option
                      key={index.toString()}
                      value={item.value}
                      onMouseDown={(event: { stopPropagation: () => void }) => {
                        event.stopPropagation();
                      }}
                    >
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </td>
            );
          } else {
            /* Variable to store attributes of cellRenderer in react data sheet */
            const attributes = props.attributesRenderer
              ? props.attributesRenderer(cell, row, col)
              : {};

            return (
              <td
                className={props.className}
                onMouseDown={(event) => {
                  props.onMouseDown(event);

                  if (selectedRowCol.row !== -1 || selectedRowCol.col !== -1) {
                    setTimeout(() => {
                      setSelectedRowCol({ row: -1, col: -1 });
                    }, 100);
                  }
                }}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onTouchEnd={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{
                  ...props.style,
                  backgroundColor: productionExpenseSheetValidationErrors.find(
                    (item) => item.columnIndex === col && item.rowIndex === row,
                  )
                    ? '#ffedea'
                    : '#ffffff',
                }}
                {...attributes}
              >
                {children}
              </td>
            );
          }
        }}
        onCellsChanged={(props) => {
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = sheetData.map((row) => [...row]);

          props.forEach((item) => {
            /* Variable to store entered value by user in specific cell */
            const value: string | number | null = item.value;

            /* Following condition is added for those columns where user should only enter number */
            /* here if user enters anything other than number like string or character then that cell's value will be updated with 0 */
            grid[item.row][item.col] =
              item.col > 2 && isNaN(Number(value))
                ? { ...grid[item.row][item.col], value: 0 }
                : {
                    ...grid[item.row][item.col],
                    value,
                  };
            /* the following logic ensures that user enters the correct fields in the given columns and if enters invalid number we show a red background */
            if (item.col === 3) {
              if (value) {
                if (Number(value) && value < 0) {
                  setProductionExpenseSheetValidationErrors((prevState) => [
                    ...prevState,
                    { rowIndex: item.row, columnIndex: item.col },
                  ]);
                } else {
                  setProductionExpenseSheetValidationErrors((prevState) =>
                    prevState.filter(
                      (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                    ),
                  );
                }
              } else {
                setProductionExpenseSheetValidationErrors((prevState) =>
                  prevState.filter(
                    (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                  ),
                );
              }
            }
          });

          /* Updating sheet data */
          updateSheetData(grid);
        }}
        rowRenderer={(props) => {
          return (
            <tr style={{ height: 18 }}>
              {props.children}
              {props.row !== 0 ? (
                <td className="action-cell">
                  <MinusCircleFilled
                    className={styles.deleteIconBtn}
                    onClick={() => {
                      /* Variable to store sheet data stored in state locally for further operations */
                      const grid: ProductionExpensesSheetGridElement[][] = [];
                      sheetData.forEach((item, index) => {
                        if (index !== props.row) {
                          grid.push(item);
                        }
                      });
                      /* after deleting a row updating the validation errors state */
                      const updatedValidationErrorsField =
                        productionExpenseSheetValidationErrors.filter(
                          (item) => item.rowIndex !== props.row,
                        );

                      /* if a user deletes a random row then we have to adjust the row error indexes of rows after the deleted row */
                      const adjustRowIndexAfterDeletingARow = updatedValidationErrorsField.map(
                        (item) => {
                          if (item.rowIndex > props.row) {
                            return { rowIndex: item.rowIndex - 1, columnIndex: item.columnIndex };
                          }
                          return item;
                        },
                      );

                      setProductionExpenseSheetValidationErrors(adjustRowIndexAfterDeletingARow);

                      /* Updating sheet data */
                      updateSheetData(grid);
                    }}
                  />
                </td>
              ) : null}
            </tr>
          );
        }}
        valueViewer={(props) => {
          if (props.col === 0) {
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? dayjs(props.value).format(dateColumnFormat) : undefined}
              </div>
            );
          }
          if (props.col === 3) {
            if (props.value === 0) {
              return <div className={styles.formattedValuesCols}>{props.value}</div>;
            }
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? `$ ${Number(props.value).toFixed(2)}` : undefined}
              </div>
            );
          }
          return <div className={styles.formattedValuesCols}>{props.value}</div>;
        }}
      />

      {/* SheetActionButtonsComponent */}
      <SheetActionButtonsComponent
        bulkUploadProps={{
          commonBulkUpload: setShowCommonBulkUploadModal,
          bulkUpload: setShowBulkUploadModal,
        }}
        addNewRowFunc={() => {
          /* Variable to store new row data to be added when new row btn clicked */
          const newRowData: ProductionExpensesSheetIndividualGridElement[] = [
            {
              date: '',
              description: '',
              account: '',
              totalCost: undefined,
            },
          ];
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = [
            ...sheetData,
            ...newRowData.map((item) => [...renderProductionExpensesSheetRows(item)]),
          ];
          /* Updating sheet data */
          updateSheetData(grid);
          if (selectedRowCol.row !== -1 || selectedRowCol.col !== -1) {
            setSelectedRowCol({ row: -1, col: -1 });
          }
        }}
      />

      {/* Columns calculated values */}
      <Row className={styles.finalOpRow} style={{ width: 860 }}>
        <Col className={styles.finalOpCol}>-</Col>
        <Col className={styles.finalOpCol} style={{ width: 295 }}>
          -
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 245 }}>
          -
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 195 }}>
          {calculateTotalCost() ? `$ ${calculateTotalCost().toFixed(2)}` : '-'}
        </Col>
      </Row>

      {barChartData.length > 0 ? (
        <div style={{ marginBottom: 50 }}>
          <div className={styles.outputInfoHeader}>Output Information</div>
          <div style={{ width: 860, height: 650 }}>
            <div className={styles.chartHeader}>Production Expenses</div>
            <BarChartComponent chartData={barChartData} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductionExpensesSheet;
