import React from 'react';
import ReactDataSheet from 'react-datasheet';
import { Row, Col } from 'antd';
import { MinusCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import DatePickerDataEditorComponent from '../DatePickerDataEditorComponent';
import {
  PigsAndBarnPigsInSheetGridElement,
  PigsAndBarnPigsInSheetIndividualGridElement,
  CommonBulkUploadComponentType,
  RowColumnIndexType,
  ValidationErrorFunctionsType,
} from '../../utils/types';
import BulkUploadModalComponent from '../BulkUploadModalComponent';
import CommonBulkUploadComponent from '../CommonBulkUploadComponent';
import { renderPigsAndBarnPigsInSheetRows, calculateFirstFillDate } from '../../utils/sheetHelpers';
import { dateColumnFormat } from '../../utils/sheetGlobals';
import SheetActionButtonsComponent from '../SheetActionButtonsComponent';
import ShowWarningComponent from '../ShowWarningComponent';
import styles from './Sheets.module.scss';

/* pigs and barn pigs in prop types */
type PigsAndBarnPigsInSheetPropsType = {
  /* Variable to store sheet data to be rendered */
  sheetData: PigsAndBarnPigsInSheetGridElement[][];
  /* Function to update sheet data */
  updateSheetData: React.Dispatch<React.SetStateAction<PigsAndBarnPigsInSheetGridElement[][]>>;
  /* variable to store row index and column index where validation has failed */
  pigsAndBarnPigsInValidationErrors: Array<RowColumnIndexType>;
} & ValidationErrorFunctionsType &
  Omit<
    CommonBulkUploadComponentType,
    | 'isCommonModalVisible'
    | 'updateIsCommonModalVisible'
    | 'pigsAndBarnPigsInSheetData'
    | 'updatePigsAndBarnPigsInSheetData'
  >;

/* React functional component */
const PigsAndBarnPigsInSheet = ({
  sheetData,
  updateSheetData,
  pigsAndBarnPigsSoldSheetData,
  updatePigsAndBarnPigsSoldSheetData,
  deathLossSheetData,
  updateDeathLossSheetData,
  productionExpensesSheetData,
  updateProductionExpensesSheetData,
  feedDeliverySheetData,
  updateFeedDeliverySheetData,
  showBulkUploadModal,
  setShowBulkUploadModal,
  showCommonBulkUploadModal,
  setShowCommonBulkUploadModal,
  activeTabKey,
  pigsAndBarnPigsInValidationErrors,
  setPigsAndBarnPigsInValidationErrors,
  setDeathLossSheetValidationErrors,
  setPigsAndBarnPigsSoldValidationErrors,
  setProductionExpenseSheetValidationErrors,
  setFeedDeliverySheetValidationErrors,
  selectedPigWtType,
  sessionType,
}: PigsAndBarnPigsInSheetPropsType): JSX.Element => {
  /* Function to do calculation based on sheet data 'Head' column */
  const calculateHead = (): number => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[1].value) {
        result += Number(item[1].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on sheet data 'Weight' column */
  const calculateWeight = (): number => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[2].value) {
        result += Number(item[2].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on sheet data 'Average Wt.' column */
  const calculateAvgWeight = (): number => {
    let result = 0;
    if (calculateWeight() && calculateHead()) {
      result = calculateWeight() / calculateHead();
    }
    return result;
  };

  /* Function to do calculation based on sheet data 'Total Value' column */
  const calculateTotalVal = (): number => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[4].value) {
        result += Number(item[4].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on sheet data 'Average' column */
  const calculateAvg = (): number => {
    let result = 0;
    if (calculateTotalVal() && calculateHead()) {
      result = calculateTotalVal() / calculateHead();
    }
    return result;
  };

  return (
    <>
      {activeTabKey === 1 ? (
        <>
          {/* Bulk Upload modal */}
          <BulkUploadModalComponent
            isModalVisible={showBulkUploadModal}
            updateIsModalVisible={setShowBulkUploadModal}
            sheetData={sheetData}
            updateSheetData={updateSheetData}
            calledFrom="pigsIn"
            setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
          />

          {/* Common Bulk Upload modal */}
          <CommonBulkUploadComponent
            isCommonModalVisible={showCommonBulkUploadModal}
            updateIsCommonModalVisible={setShowCommonBulkUploadModal}
            pigsAndBarnPigsInSheetData={sheetData}
            updatePigsAndBarnPigsInSheetData={updateSheetData}
            pigsAndBarnPigsSoldSheetData={pigsAndBarnPigsSoldSheetData}
            updatePigsAndBarnPigsSoldSheetData={updatePigsAndBarnPigsSoldSheetData}
            deathLossSheetData={deathLossSheetData}
            updateDeathLossSheetData={updateDeathLossSheetData}
            productionExpensesSheetData={productionExpensesSheetData}
            updateProductionExpensesSheetData={updateProductionExpensesSheetData}
            feedDeliverySheetData={feedDeliverySheetData}
            updateFeedDeliverySheetData={updateFeedDeliverySheetData}
            setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
            setPigsAndBarnPigsSoldValidationErrors={setPigsAndBarnPigsSoldValidationErrors}
            setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
            setProductionExpenseSheetValidationErrors={setProductionExpenseSheetValidationErrors}
            setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
            selectedPigWtType={selectedPigWtType}
            sessionType={sessionType}
          />
        </>
      ) : null}

      {Array.isArray(pigsAndBarnPigsInValidationErrors) &&
      pigsAndBarnPigsInValidationErrors.length > 0 ? (
        <ShowWarningComponent message="Please provide valid input in highlighted cells." />
      ) : null}

      {/* React data sheet */}
      <ReactDataSheet<PigsAndBarnPigsInSheetGridElement, number>
        data={sheetData}
        valueRenderer={(cell) => cell.value}
        dataRenderer={(cell) => cell.value}
        overflow="nowrap"
        cellRenderer={({ children, row, col, cell, ...props }) => {
          if (row !== 0 && col === 0) {
            /* Variable to store sheet data stored in state locally for further operations */
            const grid = sheetData.map((val) => [...val]);

            return (
              <td
                className={props.className}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{ ...props.style, backgroundColor: '#ffffff' }}
              >
                <DatePickerDataEditorComponent
                  cellValue={cell.value}
                  onChange={(date, event) => {
                    /* For date column validation is added so that only specific format of date(MM-DD-YY) accepted by corresponding cell */
                    grid[row][col] = {
                      ...grid[row][col],
                      value: date ? dayjs(date as Date).format(dateColumnFormat) : null,
                    };

                    if (event && event.type === 'click') {
                      updateSheetData(grid);
                    }
                  }}
                  onBlur={() => {
                    updateSheetData(grid);
                  }}
                />
              </td>
            );
          }

          /* Variable to store attributes of cellRenderer in react data sheet */
          const attributes = props.attributesRenderer
            ? props.attributesRenderer(cell, row, col)
            : {};

          return (
            <td
              className={props.className}
              onMouseDown={props.onMouseDown}
              onMouseOver={props.onMouseOver}
              onDoubleClick={props.onDoubleClick}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onTouchEnd={props.onDoubleClick}
              onContextMenu={props.onContextMenu}
              colSpan={cell.colSpan}
              rowSpan={cell.rowSpan}
              style={{
                ...props.style,
                backgroundColor: pigsAndBarnPigsInValidationErrors.find(
                  (item) => item.columnIndex === col && item.rowIndex === row,
                )
                  ? '#ffedea'
                  : '#ffffff',
              }}
              {...attributes}
            >
              {children}
            </td>
          );
        }}
        onCellsChanged={(props) => {
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = sheetData.map((row) => [...row]);

          props.forEach((item) => {
            /* Variable to store entered value by user in specific cell */
            const value: string | number | null = item.value;

            /* Following condition is added for those columns where user should only enter number */
            /* here if user enters anything other than number like string or character then that cell's value will be updated with 0 */
            grid[item.row][item.col] =
              item.col > 0 && isNaN(Number(value))
                ? { ...grid[item.row][item.col], value: 0 }
                : {
                    ...grid[item.row][item.col],
                    value,
                  };

            /* Below calculations are added to change values based on pig weight type */
            if (selectedPigWtType === 'totalPigWt') {
              /* For avg wt calculated field */
              if (grid[item.row][1].value && item.col === 2) {
                grid[item.row][3] = isNaN(Number(value))
                  ? { ...grid[item.row][3], value: 0 }
                  : {
                      ...grid[item.row][3],
                      value: (Number(value) / Number(grid[item.row][1].value)).toFixed(2),
                    };
              }
              if (grid[item.row][2].value && item.col === 1) {
                grid[item.row][3] = isNaN(Number(value))
                  ? { ...grid[item.row][3], value: 0 }
                  : {
                      ...grid[item.row][3],
                      value: (Number(grid[item.row][2].value) / Number(value)).toFixed(2),
                    };
              }
            }

            if (selectedPigWtType === 'avgPigWt') {
              /* For body wt calculated field */
              if (grid[item.row][1].value && item.col === 3) {
                grid[item.row][2] = isNaN(Number(value))
                  ? { ...grid[item.row][2], value: 0 }
                  : {
                      ...grid[item.row][2],
                      value: (Number(value) * Number(grid[item.row][1].value)).toFixed(2),
                    };
              }
              if (grid[item.row][3].value && item.col === 1) {
                grid[item.row][2] = isNaN(Number(value))
                  ? { ...grid[item.row][2], value: 0 }
                  : {
                      ...grid[item.row][2],
                      value: (Number(grid[item.row][3].value) * Number(value)).toFixed(2),
                    };
              }
            }

            if (selectedPigWtType === 'totalPigWt') {
              /* For average calculated field */
              if (grid[item.row][1].value && item.col === 4) {
                grid[item.row][5] = isNaN(Number(value))
                  ? { ...grid[item.row][5], value: 0 }
                  : {
                      ...grid[item.row][5],
                      value: (Number(value) / Number(grid[item.row][1].value)).toFixed(2),
                    };
              }
              if (grid[item.row][4].value && item.col === 1) {
                grid[item.row][5] = isNaN(Number(value))
                  ? { ...grid[item.row][5], value: 0 }
                  : {
                      ...grid[item.row][5],
                      value: (Number(grid[item.row][4].value) / Number(value)).toFixed(2),
                    };
              }
            }

            if (selectedPigWtType === 'avgPigWt') {
              /* For total value field */
              if (grid[item.row][1].value && item.col === 5) {
                grid[item.row][4] = isNaN(Number(value))
                  ? { ...grid[item.row][4], value: 0 }
                  : {
                      ...grid[item.row][4],
                      value: (Number(value) * Number(grid[item.row][1].value)).toFixed(2),
                    };
              }
              if (grid[item.row][5].value && item.col === 1) {
                grid[item.row][4] = isNaN(Number(value))
                  ? { ...grid[item.row][4], value: 0 }
                  : {
                      ...grid[item.row][4],
                      value: (Number(grid[item.row][4].value) * Number(value)).toFixed(2),
                    };
              }
            }
            /* the following logic ensures that user enters the correct fields in the given columns and if enters invalid number we show a red background */
            if (item.col === 1 || item.col === 2 || item.col === 4) {
              if (value) {
                /* row index is used to store the index of row where validation check has failed */
                let rowIndex: number | null = null;

                /* column index is used to store the column index where validation check has failed */
                let columnIndex: number | null = null;

                if (value <= 0 && (item.col === 1 || item.col === 2)) {
                  rowIndex = item.row;
                  columnIndex = item.col;
                } else if (value < 0 && item.col === 4) {
                  rowIndex = item.row;
                  columnIndex = item.col;
                } else if (item.col === 1 && !Number.isInteger(Number(value))) {
                  rowIndex = item.row;
                  columnIndex = item.col;
                } else {
                  setPigsAndBarnPigsInValidationErrors((prevState) =>
                    prevState.filter(
                      (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                    ),
                  );
                }
                if (rowIndex && columnIndex) {
                  setPigsAndBarnPigsInValidationErrors((prevState) => [
                    ...prevState,
                    { rowIndex: rowIndex as number, columnIndex: columnIndex as number },
                  ]);
                }
              } else {
                setPigsAndBarnPigsInValidationErrors((prevState) =>
                  prevState.filter(
                    (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                  ),
                );
              }
            }
          });

          /* Updating sheet data */
          updateSheetData(grid);
        }}
        rowRenderer={(props) => {
          return (
            <tr style={{ height: 18 }}>
              {props.children}
              {props.row !== 0 ? (
                <td className="action-cell">
                  <MinusCircleFilled
                    className={styles.deleteIconBtn}
                    onClick={() => {
                      /* Variable to store sheet data stored in state locally for further operations */
                      const grid: PigsAndBarnPigsInSheetGridElement[][] = [];
                      sheetData.forEach((item, index) => {
                        if (index !== props.row) {
                          grid.push(item);
                        }
                      });

                      /* after deleting a row updating the validation errors state */
                      const updatedValidationErrorsField = pigsAndBarnPigsInValidationErrors.filter(
                        (item) => item.rowIndex !== props.row,
                      );

                      /* if a user deletes a random row then we have to adjust the row error indexes of rows after the deleted row */
                      const adjustRowIndexAfterDeletingARow = updatedValidationErrorsField.map(
                        (item) => {
                          if (item.rowIndex > props.row) {
                            return { rowIndex: item.rowIndex - 1, columnIndex: item.columnIndex };
                          }
                          return item;
                        },
                      );
                      setPigsAndBarnPigsInValidationErrors(adjustRowIndexAfterDeletingARow);

                      /* Updating sheet data */
                      updateSheetData(grid);
                    }}
                  />
                </td>
              ) : null}
            </tr>
          );
        }}
        valueViewer={(props) => {
          if (props.col === 0) {
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? dayjs(props.value).format(dateColumnFormat) : undefined}
              </div>
            );
          }
          if (props.col === 4 || props.col === 5) {
            if (props.value === 0) {
              return <div className={styles.formattedValuesCols}>{props.value}</div>;
            }
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? `$ ${Number(props.value).toFixed(2)}` : undefined}
              </div>
            );
          }

          return <div className={styles.formattedValuesCols}>{props.value}</div>;
        }}
      />
      {/* SheetActionButtonsComponent */}
      <SheetActionButtonsComponent
        bulkUploadProps={{
          commonBulkUpload: setShowCommonBulkUploadModal,
          bulkUpload: setShowBulkUploadModal,
        }}
        addNewRowFunc={() => {
          /* Variable to store new row data to be added when new row btn clicked */
          const newRowData: PigsAndBarnPigsInSheetIndividualGridElement[] = [
            {
              date: '',
              head: undefined,
              weight: undefined,
              avgWt: undefined,
              totalVal: undefined,
              avg: undefined,
            },
          ];
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = [
            ...sheetData,
            ...newRowData.map((item) => [
              ...renderPigsAndBarnPigsInSheetRows(
                item,
                selectedPigWtType === 'avgPigWt' ? [2, 4] : [],
              ),
            ]),
          ];
          /* Updating sheet data */
          updateSheetData(grid);
        }}
      />
      {/* Columns calculated values */}
      <Row className={styles.finalOpRow} style={{ marginBottom: 60, width: 856, paddingLeft: 0 }}>
        <Col className={styles.finalOpCol} style={{ width: 105 }}>
          {calculateFirstFillDate(sheetData) || '-'}
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 100 }}>
          {calculateHead() || '-'}
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 160 }}>
          {calculateWeight() || '-'}
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 135 }}>
          {calculateAvgWeight() ? calculateAvgWeight().toFixed(2) : '-'}
        </Col>
        <Col style={{ wordWrap: 'break-word', width: 141 }} className={styles.finalOpCol}>
          {calculateTotalVal() ? `$ ${calculateTotalVal().toFixed(2)}` : '-'}
        </Col>
        <Col style={{ wordWrap: 'break-word', width: 185 }} className={styles.finalOpCol}>
          {calculateAvg() ? `$ ${calculateAvg().toFixed(2)}` : '-'}
        </Col>
      </Row>
    </>
  );
};

export default PigsAndBarnPigsInSheet;
