import React, { ChangeEvent } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Input as AntdInput } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import styles from './Input.module.scss';

/* Input props type */
type InputPropsType = {
  /* Name is the name of form field */
  name: string;
  /* default value of our input */
  defaultValue?: string | undefined;
  /* placeholder for text input */
  placeholder: string;
  /* prop to decide input is password input or not */
  isPasswordInput?: boolean;
  /* controller props of react-hook-form */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rhfControllerProps: Omit<UseControllerProps<any>, 'name' | 'defaultValue'>;
  /* custom style for input box */
  customStyles?: React.CSSProperties;
  /* props for input field */
  inputProps?: Omit<InputProps, 'onChange' | 'value' | 'onBlur' | 'ref'>;
  /* Function is called when functionality is different from the regular onChange */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (rhfOnChange: (...event: any[]) => void, val: string) => void;
};

/* React functional component */
const Input: React.FC<InputPropsType> = ({
  name,
  placeholder,
  customStyles = {},
  defaultValue = undefined,
  rhfControllerProps,
  inputProps = {},
  onChange,
  isPasswordInput = false,
}) => {
  /* useController hook destructuring */
  const { field } = useController({
    name,
    defaultValue,
    ...rhfControllerProps,
  });

  /* destructuring onChange function & rhfFields, and then will pass it to input field */
  const { onChange: rhfOnChange, ...rhfFields } = field;

  /* common props which is used in input password  and input */
  const commonProps = {
    placeholder,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(rhfOnChange, e.target.value);
      } else {
        rhfOnChange(e.target.value);
      }
    },
    className: styles.textInput,
    style: { width: 350, ...customStyles },
    ...rhfFields,
    ...inputProps,
  };

  return isPasswordInput ? <AntdInput.Password {...commonProps} /> : <AntdInput {...commonProps} />;
};

export default Input;
