export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  numeric: any;
  timestamptz: Date | string;
  uuid: string;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "data" */
export type Data = {
  __typename?: 'data';
  created_at: Scalars['timestamptz'];
  created_by_id: Scalars['uuid'];
  death_loss?: Maybe<Scalars['jsonb']>;
  feed_delivery?: Maybe<Scalars['jsonb']>;
  feed_left?: Maybe<Scalars['numeric']>;
  group_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pigs_and_barn_pigs_in?: Maybe<Scalars['jsonb']>;
  pigs_and_barn_pigs_sold?: Maybe<Scalars['jsonb']>;
  producer_name: Scalars['String'];
  production_expenses?: Maybe<Scalars['jsonb']>;
  session_type?: Maybe<Enum_Session_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  updated_by_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
};


/** columns and relationships of "data" */
export type DataDeath_LossArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "data" */
export type DataFeed_DeliveryArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "data" */
export type DataPigs_And_Barn_Pigs_InArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "data" */
export type DataPigs_And_Barn_Pigs_SoldArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "data" */
export type DataProduction_ExpensesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "data" */
export type Data_Aggregate = {
  __typename?: 'data_aggregate';
  aggregate?: Maybe<Data_Aggregate_Fields>;
  nodes: Array<Data>;
};

/** aggregate fields of "data" */
export type Data_Aggregate_Fields = {
  __typename?: 'data_aggregate_fields';
  avg?: Maybe<Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Data_Max_Fields>;
  min?: Maybe<Data_Min_Fields>;
  stddev?: Maybe<Data_Stddev_Fields>;
  stddev_pop?: Maybe<Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Data_Stddev_Samp_Fields>;
  sum?: Maybe<Data_Sum_Fields>;
  var_pop?: Maybe<Data_Var_Pop_Fields>;
  var_samp?: Maybe<Data_Var_Samp_Fields>;
  variance?: Maybe<Data_Variance_Fields>;
};


/** aggregate fields of "data" */
export type Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Data_Append_Input = {
  death_loss?: Maybe<Scalars['jsonb']>;
  feed_delivery?: Maybe<Scalars['jsonb']>;
  pigs_and_barn_pigs_in?: Maybe<Scalars['jsonb']>;
  pigs_and_barn_pigs_sold?: Maybe<Scalars['jsonb']>;
  production_expenses?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Data_Avg_Fields = {
  __typename?: 'data_avg_fields';
  feed_left?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "data". All fields are combined with a logical 'AND'. */
export type Data_Bool_Exp = {
  _and?: Maybe<Array<Data_Bool_Exp>>;
  _not?: Maybe<Data_Bool_Exp>;
  _or?: Maybe<Array<Data_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  death_loss?: Maybe<Jsonb_Comparison_Exp>;
  feed_delivery?: Maybe<Jsonb_Comparison_Exp>;
  feed_left?: Maybe<Numeric_Comparison_Exp>;
  group_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pigs_and_barn_pigs_in?: Maybe<Jsonb_Comparison_Exp>;
  pigs_and_barn_pigs_sold?: Maybe<Jsonb_Comparison_Exp>;
  producer_name?: Maybe<String_Comparison_Exp>;
  production_expenses?: Maybe<Jsonb_Comparison_Exp>;
  session_type?: Maybe<Enum_Session_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "data" */
export enum Data_Constraint {
  /** unique or primary key constraint */
  DataNameKey = 'data_name_key',
  /** unique or primary key constraint */
  DataPkey = 'data_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Data_Delete_At_Path_Input = {
  death_loss?: Maybe<Array<Scalars['String']>>;
  feed_delivery?: Maybe<Array<Scalars['String']>>;
  pigs_and_barn_pigs_in?: Maybe<Array<Scalars['String']>>;
  pigs_and_barn_pigs_sold?: Maybe<Array<Scalars['String']>>;
  production_expenses?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Data_Delete_Elem_Input = {
  death_loss?: Maybe<Scalars['Int']>;
  feed_delivery?: Maybe<Scalars['Int']>;
  pigs_and_barn_pigs_in?: Maybe<Scalars['Int']>;
  pigs_and_barn_pigs_sold?: Maybe<Scalars['Int']>;
  production_expenses?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Data_Delete_Key_Input = {
  death_loss?: Maybe<Scalars['String']>;
  feed_delivery?: Maybe<Scalars['String']>;
  pigs_and_barn_pigs_in?: Maybe<Scalars['String']>;
  pigs_and_barn_pigs_sold?: Maybe<Scalars['String']>;
  production_expenses?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "data" */
export type Data_Inc_Input = {
  feed_left?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "data" */
export type Data_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  death_loss?: Maybe<Scalars['jsonb']>;
  feed_delivery?: Maybe<Scalars['jsonb']>;
  feed_left?: Maybe<Scalars['numeric']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pigs_and_barn_pigs_in?: Maybe<Scalars['jsonb']>;
  pigs_and_barn_pigs_sold?: Maybe<Scalars['jsonb']>;
  producer_name?: Maybe<Scalars['String']>;
  production_expenses?: Maybe<Scalars['jsonb']>;
  session_type?: Maybe<Enum_Session_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Data_Max_Fields = {
  __typename?: 'data_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  feed_left?: Maybe<Scalars['numeric']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  producer_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Data_Min_Fields = {
  __typename?: 'data_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  feed_left?: Maybe<Scalars['numeric']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  producer_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "data" */
export type Data_Mutation_Response = {
  __typename?: 'data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Data>;
};

/** on conflict condition type for table "data" */
export type Data_On_Conflict = {
  constraint: Data_Constraint;
  update_columns?: Array<Data_Update_Column>;
  where?: Maybe<Data_Bool_Exp>;
};

/** Ordering options when selecting data from "data". */
export type Data_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  death_loss?: Maybe<Order_By>;
  feed_delivery?: Maybe<Order_By>;
  feed_left?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pigs_and_barn_pigs_in?: Maybe<Order_By>;
  pigs_and_barn_pigs_sold?: Maybe<Order_By>;
  producer_name?: Maybe<Order_By>;
  production_expenses?: Maybe<Order_By>;
  session_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
};

/** primary key columns input for table: data */
export type Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Data_Prepend_Input = {
  death_loss?: Maybe<Scalars['jsonb']>;
  feed_delivery?: Maybe<Scalars['jsonb']>;
  pigs_and_barn_pigs_in?: Maybe<Scalars['jsonb']>;
  pigs_and_barn_pigs_sold?: Maybe<Scalars['jsonb']>;
  production_expenses?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "data" */
export enum Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeathLoss = 'death_loss',
  /** column name */
  FeedDelivery = 'feed_delivery',
  /** column name */
  FeedLeft = 'feed_left',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  PigsAndBarnPigsIn = 'pigs_and_barn_pigs_in',
  /** column name */
  PigsAndBarnPigsSold = 'pigs_and_barn_pigs_sold',
  /** column name */
  ProducerName = 'producer_name',
  /** column name */
  ProductionExpenses = 'production_expenses',
  /** column name */
  SessionType = 'session_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** input type for updating data in table "data" */
export type Data_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  death_loss?: Maybe<Scalars['jsonb']>;
  feed_delivery?: Maybe<Scalars['jsonb']>;
  feed_left?: Maybe<Scalars['numeric']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pigs_and_barn_pigs_in?: Maybe<Scalars['jsonb']>;
  pigs_and_barn_pigs_sold?: Maybe<Scalars['jsonb']>;
  producer_name?: Maybe<Scalars['String']>;
  production_expenses?: Maybe<Scalars['jsonb']>;
  session_type?: Maybe<Enum_Session_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Data_Stddev_Fields = {
  __typename?: 'data_stddev_fields';
  feed_left?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Data_Stddev_Pop_Fields = {
  __typename?: 'data_stddev_pop_fields';
  feed_left?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Data_Stddev_Samp_Fields = {
  __typename?: 'data_stddev_samp_fields';
  feed_left?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Data_Sum_Fields = {
  __typename?: 'data_sum_fields';
  feed_left?: Maybe<Scalars['numeric']>;
};

/** update columns of table "data" */
export enum Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeathLoss = 'death_loss',
  /** column name */
  FeedDelivery = 'feed_delivery',
  /** column name */
  FeedLeft = 'feed_left',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  PigsAndBarnPigsIn = 'pigs_and_barn_pigs_in',
  /** column name */
  PigsAndBarnPigsSold = 'pigs_and_barn_pigs_sold',
  /** column name */
  ProducerName = 'producer_name',
  /** column name */
  ProductionExpenses = 'production_expenses',
  /** column name */
  SessionType = 'session_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** aggregate var_pop on columns */
export type Data_Var_Pop_Fields = {
  __typename?: 'data_var_pop_fields';
  feed_left?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Data_Var_Samp_Fields = {
  __typename?: 'data_var_samp_fields';
  feed_left?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Data_Variance_Fields = {
  __typename?: 'data_variance_fields';
  feed_left?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "enum_session_type" */
export type Enum_Session_Type = {
  __typename?: 'enum_session_type';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_session_type" */
export type Enum_Session_Type_Aggregate = {
  __typename?: 'enum_session_type_aggregate';
  aggregate?: Maybe<Enum_Session_Type_Aggregate_Fields>;
  nodes: Array<Enum_Session_Type>;
};

/** aggregate fields of "enum_session_type" */
export type Enum_Session_Type_Aggregate_Fields = {
  __typename?: 'enum_session_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Session_Type_Max_Fields>;
  min?: Maybe<Enum_Session_Type_Min_Fields>;
};


/** aggregate fields of "enum_session_type" */
export type Enum_Session_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Session_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_session_type". All fields are combined with a logical 'AND'. */
export type Enum_Session_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Session_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Session_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Session_Type_Bool_Exp>>;
  display_name?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_session_type" */
export enum Enum_Session_Type_Constraint {
  /** unique or primary key constraint */
  EnumSessionTypePkey = 'enum_session_type_pkey'
}

export enum Enum_Session_Type_Enum {
  /** Grow to finish */
  GrowFinish = 'growFinish',
  /** Nursery */
  Nursery = 'nursery',
  /** Wean to finish */
  WeanFinish = 'weanFinish'
}

/** Boolean expression to compare columns of type "enum_session_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Session_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Session_Type_Enum>;
  _in?: Maybe<Array<Enum_Session_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Session_Type_Enum>;
  _nin?: Maybe<Array<Enum_Session_Type_Enum>>;
};

/** input type for inserting data into table "enum_session_type" */
export type Enum_Session_Type_Insert_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Session_Type_Max_Fields = {
  __typename?: 'enum_session_type_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Session_Type_Min_Fields = {
  __typename?: 'enum_session_type_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_session_type" */
export type Enum_Session_Type_Mutation_Response = {
  __typename?: 'enum_session_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Session_Type>;
};

/** on conflict condition type for table "enum_session_type" */
export type Enum_Session_Type_On_Conflict = {
  constraint: Enum_Session_Type_Constraint;
  update_columns?: Array<Enum_Session_Type_Update_Column>;
  where?: Maybe<Enum_Session_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_session_type". */
export type Enum_Session_Type_Order_By = {
  display_name?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_session_type */
export type Enum_Session_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_session_type" */
export enum Enum_Session_Type_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_session_type" */
export type Enum_Session_Type_Set_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_session_type" */
export enum Enum_Session_Type_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "enum_user_role" */
export type Enum_User_Role = {
  __typename?: 'enum_user_role';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_user_role" */
export type Enum_User_Role_Aggregate = {
  __typename?: 'enum_user_role_aggregate';
  aggregate?: Maybe<Enum_User_Role_Aggregate_Fields>;
  nodes: Array<Enum_User_Role>;
};

/** aggregate fields of "enum_user_role" */
export type Enum_User_Role_Aggregate_Fields = {
  __typename?: 'enum_user_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_User_Role_Max_Fields>;
  min?: Maybe<Enum_User_Role_Min_Fields>;
};


/** aggregate fields of "enum_user_role" */
export type Enum_User_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_User_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_role". All fields are combined with a logical 'AND'. */
export type Enum_User_Role_Bool_Exp = {
  _and?: Maybe<Array<Enum_User_Role_Bool_Exp>>;
  _not?: Maybe<Enum_User_Role_Bool_Exp>;
  _or?: Maybe<Array<Enum_User_Role_Bool_Exp>>;
  display_name?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_role" */
export enum Enum_User_Role_Constraint {
  /** unique or primary key constraint */
  EnumUserRolePkey = 'enum_user_role_pkey'
}

export enum Enum_User_Role_Enum {
  AppAdmin = 'app_admin',
  User = 'user'
}

/** Boolean expression to compare columns of type "enum_user_role_enum". All fields are combined with logical 'AND'. */
export type Enum_User_Role_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_User_Role_Enum>;
  _in?: Maybe<Array<Enum_User_Role_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_User_Role_Enum>;
  _nin?: Maybe<Array<Enum_User_Role_Enum>>;
};

/** input type for inserting data into table "enum_user_role" */
export type Enum_User_Role_Insert_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Role_Max_Fields = {
  __typename?: 'enum_user_role_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_User_Role_Min_Fields = {
  __typename?: 'enum_user_role_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_user_role" */
export type Enum_User_Role_Mutation_Response = {
  __typename?: 'enum_user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_User_Role>;
};

/** on conflict condition type for table "enum_user_role" */
export type Enum_User_Role_On_Conflict = {
  constraint: Enum_User_Role_Constraint;
  update_columns?: Array<Enum_User_Role_Update_Column>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_user_role". */
export type Enum_User_Role_Order_By = {
  display_name?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_user_role */
export type Enum_User_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_role" */
export enum Enum_User_Role_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_user_role" */
export type Enum_User_Role_Set_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_user_role" */
export enum Enum_User_Role_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "enum_user_type" */
export type Enum_User_Type = {
  __typename?: 'enum_user_type';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_user_type" */
export type Enum_User_Type_Aggregate = {
  __typename?: 'enum_user_type_aggregate';
  aggregate?: Maybe<Enum_User_Type_Aggregate_Fields>;
  nodes: Array<Enum_User_Type>;
};

/** aggregate fields of "enum_user_type" */
export type Enum_User_Type_Aggregate_Fields = {
  __typename?: 'enum_user_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_User_Type_Max_Fields>;
  min?: Maybe<Enum_User_Type_Min_Fields>;
};


/** aggregate fields of "enum_user_type" */
export type Enum_User_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_User_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_type". All fields are combined with a logical 'AND'. */
export type Enum_User_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_User_Type_Bool_Exp>>;
  _not?: Maybe<Enum_User_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_User_Type_Bool_Exp>>;
  display_name?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_type" */
export enum Enum_User_Type_Constraint {
  /** unique or primary key constraint */
  EnumUserTypePkey = 'enum_user_type_pkey'
}

export enum Enum_User_Type_Enum {
  /** Coop/Dealer Employee */
  DealerEmployee = 'dealer_employee',
  /** LOL Employee */
  LolEmployee = 'lol_employee'
}

/** Boolean expression to compare columns of type "enum_user_type_enum". All fields are combined with logical 'AND'. */
export type Enum_User_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_User_Type_Enum>;
  _in?: Maybe<Array<Enum_User_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_User_Type_Enum>;
  _nin?: Maybe<Array<Enum_User_Type_Enum>>;
};

/** input type for inserting data into table "enum_user_type" */
export type Enum_User_Type_Insert_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Type_Max_Fields = {
  __typename?: 'enum_user_type_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_User_Type_Min_Fields = {
  __typename?: 'enum_user_type_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_user_type" */
export type Enum_User_Type_Mutation_Response = {
  __typename?: 'enum_user_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_User_Type>;
};

/** on conflict condition type for table "enum_user_type" */
export type Enum_User_Type_On_Conflict = {
  constraint: Enum_User_Type_Constraint;
  update_columns?: Array<Enum_User_Type_Update_Column>;
  where?: Maybe<Enum_User_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_user_type". */
export type Enum_User_Type_Order_By = {
  display_name?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_user_type */
export type Enum_User_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_type" */
export enum Enum_User_Type_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_user_type" */
export type Enum_User_Type_Set_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_user_type" */
export enum Enum_User_Type_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "data" */
  delete_data?: Maybe<Data_Mutation_Response>;
  /** delete single row from the table: "data" */
  delete_data_by_pk?: Maybe<Data>;
  /** delete data from the table: "enum_session_type" */
  delete_enum_session_type?: Maybe<Enum_Session_Type_Mutation_Response>;
  /** delete single row from the table: "enum_session_type" */
  delete_enum_session_type_by_pk?: Maybe<Enum_Session_Type>;
  /** delete data from the table: "enum_user_role" */
  delete_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** delete single row from the table: "enum_user_role" */
  delete_enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** delete data from the table: "enum_user_type" */
  delete_enum_user_type?: Maybe<Enum_User_Type_Mutation_Response>;
  /** delete single row from the table: "enum_user_type" */
  delete_enum_user_type_by_pk?: Maybe<Enum_User_Type>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** insert data into the table: "data" */
  insert_data?: Maybe<Data_Mutation_Response>;
  /** insert a single row into the table: "data" */
  insert_data_one?: Maybe<Data>;
  /** insert data into the table: "enum_session_type" */
  insert_enum_session_type?: Maybe<Enum_Session_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_session_type" */
  insert_enum_session_type_one?: Maybe<Enum_Session_Type>;
  /** insert data into the table: "enum_user_role" */
  insert_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** insert a single row into the table: "enum_user_role" */
  insert_enum_user_role_one?: Maybe<Enum_User_Role>;
  /** insert data into the table: "enum_user_type" */
  insert_enum_user_type?: Maybe<Enum_User_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_user_type" */
  insert_enum_user_type_one?: Maybe<Enum_User_Type>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** update data of the table: "data" */
  update_data?: Maybe<Data_Mutation_Response>;
  /** update single row of the table: "data" */
  update_data_by_pk?: Maybe<Data>;
  /** update data of the table: "enum_session_type" */
  update_enum_session_type?: Maybe<Enum_Session_Type_Mutation_Response>;
  /** update single row of the table: "enum_session_type" */
  update_enum_session_type_by_pk?: Maybe<Enum_Session_Type>;
  /** update data of the table: "enum_user_role" */
  update_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** update single row of the table: "enum_user_role" */
  update_enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** update data of the table: "enum_user_type" */
  update_enum_user_type?: Maybe<Enum_User_Type_Mutation_Response>;
  /** update single row of the table: "enum_user_type" */
  update_enum_user_type_by_pk?: Maybe<Enum_User_Type>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
};


/** mutation root */
export type Mutation_RootDelete_DataArgs = {
  where: Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Session_TypeArgs = {
  where: Enum_Session_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Session_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_User_RoleArgs = {
  where: Enum_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_User_TypeArgs = {
  where: Enum_User_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_User_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_DataArgs = {
  objects: Array<Data_Insert_Input>;
  on_conflict?: Maybe<Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Data_OneArgs = {
  object: Data_Insert_Input;
  on_conflict?: Maybe<Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Session_TypeArgs = {
  objects: Array<Enum_Session_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Session_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Session_Type_OneArgs = {
  object: Enum_Session_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Session_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_User_RoleArgs = {
  objects: Array<Enum_User_Role_Insert_Input>;
  on_conflict?: Maybe<Enum_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_User_Role_OneArgs = {
  object: Enum_User_Role_Insert_Input;
  on_conflict?: Maybe<Enum_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_User_TypeArgs = {
  objects: Array<Enum_User_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_User_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_User_Type_OneArgs = {
  object: Enum_User_Type_Insert_Input;
  on_conflict?: Maybe<Enum_User_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_DataArgs = {
  _append?: Maybe<Data_Append_Input>;
  _delete_at_path?: Maybe<Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Data_Delete_Elem_Input>;
  _delete_key?: Maybe<Data_Delete_Key_Input>;
  _inc?: Maybe<Data_Inc_Input>;
  _prepend?: Maybe<Data_Prepend_Input>;
  _set?: Maybe<Data_Set_Input>;
  where: Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Data_By_PkArgs = {
  _append?: Maybe<Data_Append_Input>;
  _delete_at_path?: Maybe<Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Data_Delete_Elem_Input>;
  _delete_key?: Maybe<Data_Delete_Key_Input>;
  _inc?: Maybe<Data_Inc_Input>;
  _prepend?: Maybe<Data_Prepend_Input>;
  _set?: Maybe<Data_Set_Input>;
  pk_columns: Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Session_TypeArgs = {
  _set?: Maybe<Enum_Session_Type_Set_Input>;
  where: Enum_Session_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Session_Type_By_PkArgs = {
  _set?: Maybe<Enum_Session_Type_Set_Input>;
  pk_columns: Enum_Session_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_RoleArgs = {
  _set?: Maybe<Enum_User_Role_Set_Input>;
  where: Enum_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_Role_By_PkArgs = {
  _set?: Maybe<Enum_User_Role_Set_Input>;
  pk_columns: Enum_User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_TypeArgs = {
  _set?: Maybe<Enum_User_Type_Set_Input>;
  where: Enum_User_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_Type_By_PkArgs = {
  _set?: Maybe<Enum_User_Type_Set_Input>;
  pk_columns: Enum_User_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "data" */
  data: Array<Data>;
  /** fetch aggregated fields from the table: "data" */
  data_aggregate: Data_Aggregate;
  /** fetch data from the table: "data" using primary key columns */
  data_by_pk?: Maybe<Data>;
  /** fetch data from the table: "enum_session_type" */
  enum_session_type: Array<Enum_Session_Type>;
  /** fetch aggregated fields from the table: "enum_session_type" */
  enum_session_type_aggregate: Enum_Session_Type_Aggregate;
  /** fetch data from the table: "enum_session_type" using primary key columns */
  enum_session_type_by_pk?: Maybe<Enum_Session_Type>;
  /** fetch data from the table: "enum_user_role" */
  enum_user_role: Array<Enum_User_Role>;
  /** fetch aggregated fields from the table: "enum_user_role" */
  enum_user_role_aggregate: Enum_User_Role_Aggregate;
  /** fetch data from the table: "enum_user_role" using primary key columns */
  enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** fetch data from the table: "enum_user_type" */
  enum_user_type: Array<Enum_User_Type>;
  /** fetch aggregated fields from the table: "enum_user_type" */
  enum_user_type_aggregate: Enum_User_Type_Aggregate;
  /** fetch data from the table: "enum_user_type" using primary key columns */
  enum_user_type_by_pk?: Maybe<Enum_User_Type>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


export type Query_RootDataArgs = {
  distinct_on?: Maybe<Array<Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Data_Order_By>>;
  where?: Maybe<Data_Bool_Exp>;
};


export type Query_RootData_AggregateArgs = {
  distinct_on?: Maybe<Array<Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Data_Order_By>>;
  where?: Maybe<Data_Bool_Exp>;
};


export type Query_RootData_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEnum_Session_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Session_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Session_Type_Order_By>>;
  where?: Maybe<Enum_Session_Type_Bool_Exp>;
};


export type Query_RootEnum_Session_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Session_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Session_Type_Order_By>>;
  where?: Maybe<Enum_Session_Type_Bool_Exp>;
};


export type Query_RootEnum_Session_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_User_RoleArgs = {
  distinct_on?: Maybe<Array<Enum_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Role_Order_By>>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};


export type Query_RootEnum_User_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Role_Order_By>>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};


export type Query_RootEnum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_User_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_User_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Type_Order_By>>;
  where?: Maybe<Enum_User_Type_Bool_Exp>;
};


export type Query_RootEnum_User_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_User_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Type_Order_By>>;
  where?: Maybe<Enum_User_Type_Bool_Exp>;
};


export type Query_RootEnum_User_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "data" */
  data: Array<Data>;
  /** fetch aggregated fields from the table: "data" */
  data_aggregate: Data_Aggregate;
  /** fetch data from the table: "data" using primary key columns */
  data_by_pk?: Maybe<Data>;
  /** fetch data from the table: "enum_session_type" */
  enum_session_type: Array<Enum_Session_Type>;
  /** fetch aggregated fields from the table: "enum_session_type" */
  enum_session_type_aggregate: Enum_Session_Type_Aggregate;
  /** fetch data from the table: "enum_session_type" using primary key columns */
  enum_session_type_by_pk?: Maybe<Enum_Session_Type>;
  /** fetch data from the table: "enum_user_role" */
  enum_user_role: Array<Enum_User_Role>;
  /** fetch aggregated fields from the table: "enum_user_role" */
  enum_user_role_aggregate: Enum_User_Role_Aggregate;
  /** fetch data from the table: "enum_user_role" using primary key columns */
  enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** fetch data from the table: "enum_user_type" */
  enum_user_type: Array<Enum_User_Type>;
  /** fetch aggregated fields from the table: "enum_user_type" */
  enum_user_type_aggregate: Enum_User_Type_Aggregate;
  /** fetch data from the table: "enum_user_type" using primary key columns */
  enum_user_type_by_pk?: Maybe<Enum_User_Type>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


export type Subscription_RootDataArgs = {
  distinct_on?: Maybe<Array<Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Data_Order_By>>;
  where?: Maybe<Data_Bool_Exp>;
};


export type Subscription_RootData_AggregateArgs = {
  distinct_on?: Maybe<Array<Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Data_Order_By>>;
  where?: Maybe<Data_Bool_Exp>;
};


export type Subscription_RootData_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEnum_Session_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Session_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Session_Type_Order_By>>;
  where?: Maybe<Enum_Session_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Session_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Session_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Session_Type_Order_By>>;
  where?: Maybe<Enum_Session_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Session_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_User_RoleArgs = {
  distinct_on?: Maybe<Array<Enum_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Role_Order_By>>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};


export type Subscription_RootEnum_User_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Role_Order_By>>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};


export type Subscription_RootEnum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_User_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_User_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Type_Order_By>>;
  where?: Maybe<Enum_User_Type_Bool_Exp>;
};


export type Subscription_RootEnum_User_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_User_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Type_Order_By>>;
  where?: Maybe<Enum_User_Type_Bool_Exp>;
};


export type Subscription_RootEnum_User_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  account_number?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  role: Enum_User_Role_Enum;
  type?: Maybe<Enum_User_Type_Enum>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<User_Bool_Exp>>;
  account_number?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_login_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  role?: Maybe<Enum_User_Role_Enum_Comparison_Exp>;
  type?: Maybe<Enum_User_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Enum_User_Role_Enum>;
  type?: Maybe<Enum_User_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  account_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_login_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstLoginAt = 'first_login_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Enum_User_Role_Enum>;
  type?: Maybe<Enum_User_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstLoginAt = 'first_login_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type AddDataMutationVariables = Exact<{
  producer_name: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['String']>;
  pigs_and_barn_pigs_in?: Maybe<Scalars['jsonb']>;
  pigs_and_barn_pigs_sold?: Maybe<Scalars['jsonb']>;
  death_loss?: Maybe<Scalars['jsonb']>;
  production_expenses?: Maybe<Scalars['jsonb']>;
  feed_delivery?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  session_type: Enum_Session_Type_Enum;
  feed_left?: Maybe<Scalars['numeric']>;
}>;


export type AddDataMutation = { __typename?: 'mutation_root', insert_data_one?: { __typename?: 'data', id: string, name: string, location?: string | null | undefined, group_id?: string | null | undefined, session_type?: Enum_Session_Type_Enum | null | undefined, pigs_and_barn_pigs_in?: any | null | undefined, pigs_and_barn_pigs_sold?: any | null | undefined, producer_name: string, production_expenses?: any | null | undefined, feed_delivery?: any | null | undefined, death_loss?: any | null | undefined, feed_left?: any | null | undefined } | null | undefined };

export type AddUserMutationVariables = Exact<{
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  role: Enum_User_Role_Enum;
  type: Enum_User_Type_Enum;
  account_number?: Maybe<Scalars['String']>;
}>;


export type AddUserMutation = { __typename?: 'mutation_root', insert_user_one?: { __typename?: 'user', id: string, last_name: string, role: Enum_User_Role_Enum, first_name: string, email: string, type?: Enum_User_Type_Enum | null | undefined, account_number?: string | null | undefined, first_login_at?: Date | string | null | undefined } | null | undefined };

export type DeleteSessionByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteSessionByPkMutation = { __typename?: 'mutation_root', delete_data_by_pk?: { __typename?: 'data', id: string } | null | undefined };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserMutation = { __typename?: 'mutation_root', delete_user_by_pk?: { __typename?: 'user', id: string } | null | undefined };

export type SetUserFirstLoginMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SetUserFirstLoginMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', id: string, first_login_at?: Date | string | null | undefined } | null | undefined };

export type UpdateSessionByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  sessionData: Data_Set_Input;
}>;


export type UpdateSessionByPkMutation = { __typename?: 'mutation_root', update_data_by_pk?: { __typename?: 'data', id: string, name: string, location?: string | null | undefined, group_id?: string | null | undefined, session_type?: Enum_Session_Type_Enum | null | undefined, pigs_and_barn_pigs_in?: any | null | undefined, pigs_and_barn_pigs_sold?: any | null | undefined, producer_name: string, production_expenses?: any | null | undefined, feed_delivery?: any | null | undefined, death_loss?: any | null | undefined, feed_left?: any | null | undefined } | null | undefined };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Enum_User_Role_Enum>;
  type: Enum_User_Type_Enum;
  account_number?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', id: string, last_name: string, role: Enum_User_Role_Enum, first_name: string, email: string, type?: Enum_User_Type_Enum | null | undefined, account_number?: string | null | undefined, first_login_at?: Date | string | null | undefined } | null | undefined };

export type AllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUsersQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: string, first_name: string, last_name: string, email: string, role: Enum_User_Role_Enum, type?: Enum_User_Type_Enum | null | undefined, account_number?: string | null | undefined, first_login_at?: Date | string | null | undefined }> };

export type GetNurserySessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNurserySessionsQuery = { __typename?: 'query_root', data: Array<{ __typename?: 'data', id: string, name: string }> };

export type GetSavedSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSavedSessionsQuery = { __typename?: 'query_root', data: Array<{ __typename?: 'data', id: string, name: string, session_type?: Enum_Session_Type_Enum | null | undefined, created_at: Date | string, updated_at: Date | string, user: { __typename?: 'user', id: string, first_name: string, last_name: string } }> };

export type SessionByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SessionByIdQuery = { __typename?: 'query_root', data_by_pk?: { __typename?: 'data', id: string, name: string, location?: string | null | undefined, group_id?: string | null | undefined, session_type?: Enum_Session_Type_Enum | null | undefined, pigs_and_barn_pigs_in?: any | null | undefined, pigs_and_barn_pigs_sold?: any | null | undefined, producer_name: string, production_expenses?: any | null | undefined, feed_delivery?: any | null | undefined, death_loss?: any | null | undefined, feed_left?: any | null | undefined } | null | undefined };

export type UserByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserByIdQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', id: string, role: Enum_User_Role_Enum, first_name: string, last_name: string, email: string, first_login_at?: Date | string | null | undefined } | null | undefined };
