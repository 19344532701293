import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import localforage from 'localforage';
import { useApp } from '../contexts/AppContext';
import { firebaseAuth } from '../utils/firebase';
import apolloClient from '../graphql/apolloClient';
import { logger } from '../utils/helpers';
import logo from '../assets/images/abacus_logo.svg';
import styles from './AppLayout.module.scss';

/* React functional component */
const AppLayout = (): JSX.Element => {
  /* useLocation destructuring */
  const location = useLocation();

  /* useApp() context destructuring */
  const { user } = useApp();

  /* Function to handle user logout */
  const handleLogout = () => {
    firebaseAuth
      .signOut()
      .then(() => {
        apolloClient.resetStore().catch((apolloErr: ApolloError) => {
          logger(apolloErr);
        });
        localforage.removeItem('user').catch((err) => {
          logger(err);
        });
      })
      .catch((err) => {
        logger(err);
      });
  };

  return (
    <div className={`${styles.headerContainer} hideWhilePrinting`}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="purinaEquine" height="50" width="50" />
      </div>
      <div className={styles.layoutContainer}>
        <div className={styles.menuList}>
          <Link
            to="/help"
            className={location.pathname.includes('help') ? styles.linkActive : styles.link}
          >
            Instructions
          </Link>
          <Link
            className={location.pathname.includes('data') ? styles.linkActive : styles.link}
            to="/data"
          >
            Data
          </Link>
          {user && user.role === 'app_admin' ? (
            <Link
              className={location.pathname.includes('users') ? styles.linkActive : styles.link}
              to="/users"
            >
              Users
            </Link>
          ) : null}
          <Link
            className={location.pathname.includes('settings') ? styles.linkActive : styles.link}
            to="/settings"
          >
            Settings
          </Link>
          <Link
            to="/history"
            className={location.pathname.includes('history') ? styles.linkActive : styles.link}
          >
            History
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <Button
            onClick={(): void => {
              handleLogout();
            }}
            className={styles.logoutContainer}
            type="text"
          >
            <LogoutOutlined />
            <span className={styles.logoutText}>Logout</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
