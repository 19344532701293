import React from 'react';
import ReactDataSheet from 'react-datasheet';
import { Row, Col, Table, Descriptions, Tooltip } from 'antd';
import { MinusCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import DatePickerDataEditorComponent from '../DatePickerDataEditorComponent';
import {
  PigsAndBarnPigsSoldSheetGridElement,
  PigsAndBarnPigsSoldSheetIndividualGridElement,
  PigsAndBarnPigsInSheetGridElement,
  CommonBulkUploadComponentType,
  RowColumnIndexType,
  ValidationErrorFunctionsType,
} from '../../utils/types';
import BulkUploadModalComponent from '../BulkUploadModalComponent';
import CommonBulkUploadComponent from '../CommonBulkUploadComponent';
import SheetActionButtonsComponent from '../SheetActionButtonsComponent';
import {
  renderPigsAndBarnPigsSoldSheetRows,
  renderPigsAndBarnPigsSoldSheetRowsForNurserySession,
  getSerialNumberOfDate,
  renderInfoTooltip,
  calculateMaxOutputDate,
} from '../../utils/sheetHelpers';
import { dateColumnFormat, seriesStartDate } from '../../utils/sheetGlobals';
import { Enum_Session_Type_Enum } from '../../graphql/graphql-types';
import ShowWarningComponent from '../ShowWarningComponent';
import styles from './Sheets.module.scss';

/* PigsAndBarnPigsSoldSheet props type */
type PigsAndBarnPigsSoldSheetPropsType = {
  /* Variable to store sheet data to be rendered */
  sheetData: PigsAndBarnPigsSoldSheetGridElement[][];
  /* Function to update sheet data */
  updateSheetData: React.Dispatch<React.SetStateAction<PigsAndBarnPigsSoldSheetGridElement[][]>>;
  /* variable to store row index and column index where validation has failed */
  pigsAndBarnPigsSoldValidationErrors: Array<RowColumnIndexType>;
} & ValidationErrorFunctionsType &
  Omit<
    CommonBulkUploadComponentType,
    | 'isCommonModalVisible'
    | 'updateIsCommonModalVisible'
    | 'pigsAndBarnPigsSoldSheetData'
    | 'updatePigsAndBarnPigsSoldSheetData'
  >;

/* Actual and Projected data type */
type ActualAndProjectedTableType = {
  /* to calculate pig cost / hd*/
  pigCostByHd: number;
  /* to calculate feed cost /Hd */
  feedCostByHd: number;
  /* to calculate production cost / Hd */
  productionCostByHd: number;
  /* to calculate total cost / Hd */
  totalCostByHd: number;
  /* to calculate total Receipts / Hd */
  totalReceiptsByHd: number;
  /* to calculate profit / loss per hd */
  profitLossPerHd: number;
  /* to calculate profit / loss per group */
  profitLossPerGroup: number;
};

/* React functional component */
const PigsAndBarnPigsSoldSheet = ({
  sheetData,
  updateSheetData,
  pigsAndBarnPigsInSheetData,
  deathLossSheetData,
  productionExpensesSheetData,
  feedDeliverySheetData,
  updatePigsAndBarnPigsInSheetData,
  updateDeathLossSheetData,
  updateProductionExpensesSheetData,
  updateFeedDeliverySheetData,
  showBulkUploadModal,
  setShowBulkUploadModal,
  showCommonBulkUploadModal,
  setShowCommonBulkUploadModal,
  activeTabKey,
  sessionType,
  pigsAndBarnPigsSoldValidationErrors,
  setPigsAndBarnPigsInValidationErrors,
  setDeathLossSheetValidationErrors,
  setPigsAndBarnPigsSoldValidationErrors,
  setProductionExpenseSheetValidationErrors,
  setFeedDeliverySheetValidationErrors,
  selectedPigWtType,
}: PigsAndBarnPigsSoldSheetPropsType): JSX.Element => {
  /* to calculate sum of pig Cost*/
  const calculatePigCost = () => {
    let result = 0;
    pigsAndBarnPigsInSheetData.forEach((item) => {
      if (item[4].value) {
        result += Number(item[4].value);
      }
    });
    return result;
  };

  /* to calculate head */
  const calculateHead = () => {
    let result = 0;
    pigsAndBarnPigsInSheetData.forEach((item) => {
      if (item[1].value) {
        result += Number(item[1].value);
      }
    });
    return result;
  };

  /* to calculate sum production cost */
  const calculateProductionCost = () => {
    let result = 0;
    productionExpensesSheetData.forEach((item) => {
      if (item[3].value) {
        result += Number(item[3].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on data :'HdOut' column */
  const calculateHdOut = (
    data: PigsAndBarnPigsInSheetGridElement[][] | PigsAndBarnPigsSoldSheetGridElement[][],
    columnIndex: number,
  ): number => {
    let result = 0;
    data.forEach((item) => {
      if (item[columnIndex].value) {
        result += Number(item[columnIndex].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on sheet data 'Carcass Wt' column */
  const calculateCarCassWeight = () => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[3].value) {
        result += Number(item[3].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on sheet data 'Live wt' column */
  const calculateLiveWt = (columnIndex?: number) => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[columnIndex ? columnIndex : 4].value) {
        result += Number(item[columnIndex ? columnIndex : 4].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on sheet data 'Yield' column */
  const calculateYield = () => {
    let result = 0;
    if (calculateCarCassWeight() && calculateLiveWt()) {
      result = (calculateCarCassWeight() * 100) / calculateLiveWt();
    }
    return result;
  };

  /* Function to do calculation based on sheet data 'Average Wt.' column */
  const calculateAvgWeight = (): number => {
    let result = 0;
    if (
      calculateLiveWt(sessionType === Enum_Session_Type_Enum.Nursery ? 3 : 4) &&
      calculateHdOut(sheetData, 2)
    ) {
      result =
        calculateLiveWt(sessionType === Enum_Session_Type_Enum.Nursery ? 3 : 4) /
        calculateHdOut(sheetData, 2);
    }
    return result;
  };

  /* Function to do calculation based on sheet data  'Total value' column */
  const calculateTotalValue = () => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[sessionType !== Enum_Session_Type_Enum.Nursery ? 7 : 5].value) {
        result += Number(item[sessionType !== Enum_Session_Type_Enum.Nursery ? 7 : 5].value);
      }
    });
    return result;
  };

  /* Function to do calculation based on sheet data 'Average $' column */
  const calculateAvg = () => {
    let result = 0;
    if (calculateTotalValue() && calculateHdOut(sheetData, 2)) {
      result = calculateTotalValue() / calculateHdOut(sheetData, 2);
    }
    return result;
  };

  /* to calculate sum of dead pigs */
  const calculateSumOfDeadPigs = () => {
    let result = 0;
    deathLossSheetData.forEach((item) => {
      if (item[1].value) {
        result += Number(item[1].value);
      }
    });
    return result;
  };

  /* to calculate running inventory */
  const calculateRunningInventory = () => {
    // sum of head - sum of hd out - sum of #dead [from death loss section]
    const runningInventory =
      calculateHead() - calculateHdOut(sheetData, 2) - calculateSumOfDeadPigs();
    return runningInventory;
  };

  /* to calculate the latest date on which pigs were sold */
  const calculateLatestDateOfPigsSold = () => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[0].value) {
        /* Variable to store serial number for date selected by user in data sheet */
        const serialNumberOfDate: number = getSerialNumberOfDate(item[0].value as string);
        if (result < serialNumberOfDate) {
          result = serialNumberOfDate;
        }
      }
    });
    if (result) {
      return result
        ? dayjs(seriesStartDate)
            .add(Math.floor(result) - 1, 'day')
            .format(dateColumnFormat)
        : '';
    }
  };

  /* to calculate sum of feed cost */
  const calculateFeedCost = () => {
    let result = 0;
    feedDeliverySheetData.forEach((item) => {
      if (item[5].value) {
        result += Number(item[5].value);
      }
    });
    return result;
  };

  /* to calculate total cost */
  const calculateTotalCost = () => {
    if (calculateHead() && calculateHdOut(sheetData, 2)) {
      const result =
        calculatePigCost() / calculateHead() +
        calculateFeedCost() / calculateHdOut(sheetData, 2) +
        calculateProductionCost() / calculateHead();
      return result;
    }
    return 0;
  };

  /* to calculate total receipts */
  const calculateTotalReceiptsByHd = () => {
    const result = calculateTotalValue() / calculateHdOut(sheetData, 2);
    return result;
  };

  /* to calculate profit / loss per head */
  const calculateProfitLossPerHead = () => {
    const result = calculateTotalReceiptsByHd() - calculateTotalCost();
    return result;
  };

  /* to calculate profit / loss per group */
  const calculateProfitLossPerGroup = () => {
    const result = calculateProfitLossPerHead() * calculateHdOut(sheetData, 2);
    return result;
  };

  /* to calculate pigs culled head out */
  const calculatePigsCulledHeadOut = () => {
    let result = 0;
    sheetData.forEach((item) => {
      if (item[1].value && item[2].value) {
        result += Number(item[2].value);
      }
    });
    return result;
  };

  /* calculation of data  for actual and projected table */
  const actualAndProjectedTableData: ActualAndProjectedTableType[] = [
    {
      pigCostByHd: calculatePigCost() / calculateHead() || 0,
      feedCostByHd: calculateFeedCost() / calculateHdOut(sheetData, 2) || 0,
      productionCostByHd: calculateProductionCost() / calculateHead() || 0,
      totalCostByHd: calculateTotalCost() || 0,
      totalReceiptsByHd: calculateTotalReceiptsByHd() || 0,
      profitLossPerHd: calculateProfitLossPerHead() || 0,
      profitLossPerGroup: calculateProfitLossPerGroup() || 0,
    },
  ];

  return (
    <>
      {activeTabKey === 2 ? (
        <>
          {/* Bulk Upload modal */}
          <BulkUploadModalComponent
            isModalVisible={showBulkUploadModal}
            updateIsModalVisible={setShowBulkUploadModal}
            sheetData={sheetData}
            updateSheetData={updateSheetData}
            calledFrom="pigsSold"
            pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
            sessionType={sessionType}
            setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsSoldValidationErrors}
          />

          {/* common bulk upload modal */}
          <CommonBulkUploadComponent
            isCommonModalVisible={showCommonBulkUploadModal}
            updateIsCommonModalVisible={setShowCommonBulkUploadModal}
            pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
            updatePigsAndBarnPigsInSheetData={updatePigsAndBarnPigsInSheetData}
            pigsAndBarnPigsSoldSheetData={sheetData}
            updatePigsAndBarnPigsSoldSheetData={updateSheetData}
            deathLossSheetData={deathLossSheetData}
            updateDeathLossSheetData={updateDeathLossSheetData}
            productionExpensesSheetData={productionExpensesSheetData}
            updateProductionExpensesSheetData={updateProductionExpensesSheetData}
            feedDeliverySheetData={feedDeliverySheetData}
            updateFeedDeliverySheetData={updateFeedDeliverySheetData}
            sessionType={sessionType}
            setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
            setPigsAndBarnPigsSoldValidationErrors={setPigsAndBarnPigsSoldValidationErrors}
            setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
            setProductionExpenseSheetValidationErrors={setProductionExpenseSheetValidationErrors}
            setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
            selectedPigWtType={selectedPigWtType}
          />
        </>
      ) : null}

      {Array.isArray(pigsAndBarnPigsSoldValidationErrors) &&
      pigsAndBarnPigsSoldValidationErrors.length > 0 ? (
        <ShowWarningComponent message="Please provide valid input in highlighted cells." />
      ) : null}

      <ReactDataSheet<PigsAndBarnPigsSoldSheetGridElement, number>
        data={sheetData}
        valueRenderer={(cell) => cell.value}
        dataRenderer={(cell) => cell.value}
        cellRenderer={({ children, row, col, cell, ...props }) => {
          if (row !== 0 && col === 0) {
            /* Variable to store sheet data stored in state locally for further operations */
            const grid = sheetData.map((val) => [...val]);

            return (
              <td
                className={props.className}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                style={{ ...props.style, backgroundColor: '#ffffff' }}
              >
                <DatePickerDataEditorComponent
                  cellValue={cell.value}
                  onChange={(date, event) => {
                    /* For date column validation is added so that only specific format of date(MM-DD-YY) accepted by corresponding cell */
                    grid[row][col] = {
                      ...grid[row][col],
                      value: date ? dayjs(date as Date).format(dateColumnFormat) : null,
                    };

                    if (event && event.type === 'click') {
                      updateSheetData(grid);
                    }
                  }}
                  onBlur={() => {
                    updateSheetData(grid);
                  }}
                />
              </td>
            );
          }

          if (col === 1 && row !== 0) {
            /* Variable to store sheet data stored in state locally for further operations */
            const grid = sheetData.map((val) => [...val]);

            /* this is to check whether the isCull checkbox is checked or not */
            const checkedValue = sheetData[row][1].value === 'true' ? true : false;

            return (
              <td
                style={{
                  backgroundColor: '#ffffff',
                  ...props.style,
                }}
                className={props.className}
                onMouseOver={props.onMouseOver}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
              >
                <input
                  type="checkbox"
                  style={{
                    paddingTop: 2,
                    width: '100%',
                  }}
                  checked={checkedValue}
                  onChange={(e) => {
                    grid[row][1] = { ...grid[row][1], value: e.target.checked ? 'true' : null };
                    updateSheetData(grid);
                  }}
                />
              </td>
            );
          }

          /* Variable to store attributes of cellRenderer in react data sheet */
          const attributes = props.attributesRenderer
            ? props.attributesRenderer(cell, row, col)
            : {};

          return (
            <td
              className={props.className}
              onMouseDown={props.onMouseDown}
              onMouseOver={props.onMouseOver}
              onDoubleClick={props.onDoubleClick}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onTouchEnd={props.onDoubleClick}
              onContextMenu={props.onContextMenu}
              colSpan={cell.colSpan}
              rowSpan={cell.rowSpan}
              style={{
                ...props.style,
                backgroundColor: pigsAndBarnPigsSoldValidationErrors.find(
                  (item) => item.columnIndex === col && item.rowIndex === row,
                )
                  ? '#ffedea'
                  : '#ffffff',
              }}
              {...attributes}
            >
              {children}
            </td>
          );
        }}
        onCellsChanged={(props) => {
          /* Variable to store sheet data stored in state locally for further operations */
          const grid = sheetData.map((row) => [...row]);

          props.forEach((item) => {
            /* Variable to store entered value by user in specific cell */
            const value: string | number | null = item.value;

            grid[item.row][item.col] =
              item.col > 0 && isNaN(Number(value))
                ? { ...grid[item.row][item.col], value: 0 }
                : {
                    ...grid[item.row][item.col],
                    value,
                  };

            if (sessionType !== Enum_Session_Type_Enum.Nursery) {
              if (selectedPigWtType === 'totalPigWt') {
                /* For yield calculation */
                if (grid[item.row][3].value && item.col === 4) {
                  grid[item.row][5] = isNaN(Number(value))
                    ? { ...grid[item.row][5], value: 0 }
                    : {
                        ...grid[item.row][5],
                        value: ((Number(grid[item.row][3].value) * 100) / Number(value)).toFixed(2),
                      };
                }

                if (grid[item.row][4].value && item.col === 3) {
                  grid[item.row][5] = isNaN(Number(value))
                    ? { ...grid[item.row][5], value: 0 }
                    : {
                        ...grid[item.row][5],
                        value: ((Number(value) * 100) / Number(grid[item.row][4].value)).toFixed(2),
                      };
                }
              }

              if (selectedPigWtType === 'avgPigWt') {
                /* For yield calculation */
                if (grid[item.row][3].value && item.col === 6 && grid[item.row][2].value) {
                  grid[item.row][5] = isNaN(Number(value))
                    ? { ...grid[item.row][5], value: 0 }
                    : {
                        ...grid[item.row][5],
                        value: (
                          (Number(grid[item.row][3].value) * 100) /
                          (Number(value) * Number(grid[item.row][2].value))
                        ).toFixed(2),
                      };
                }

                if (grid[item.row][4].value && item.col === 3) {
                  grid[item.row][5] = isNaN(Number(value))
                    ? { ...grid[item.row][5], value: 0 }
                    : {
                        ...grid[item.row][5],
                        value: ((Number(value) * 100) / Number(grid[item.row][4].value)).toFixed(2),
                      };
                }
              }
            }
            /* live wt column index */
            const liveWtColumnIndex = sessionType !== Enum_Session_Type_Enum.Nursery ? 4 : 3;

            /* avg body weight index */
            const avgBodyWeightIndex = sessionType !== Enum_Session_Type_Enum.Nursery ? 6 : 4;

            /* Below calculations are added to change values based on pig weight type */
            if (selectedPigWtType === 'totalPigWt') {
              /* For average weight calculation */
              if (grid[item.row][2].value && item.col === liveWtColumnIndex) {
                grid[item.row][avgBodyWeightIndex] = isNaN(Number(value))
                  ? { ...grid[item.row][avgBodyWeightIndex], value: 0 }
                  : {
                      ...grid[item.row][avgBodyWeightIndex],
                      value: (Number(value) / Number(grid[item.row][2].value)).toFixed(2),
                    };
              }

              if (grid[item.row][liveWtColumnIndex].value && item.col === 2) {
                grid[item.row][avgBodyWeightIndex] = isNaN(Number(value))
                  ? { ...grid[item.row][avgBodyWeightIndex], value: 0 }
                  : {
                      ...grid[item.row][avgBodyWeightIndex],
                      value: (
                        Number(grid[item.row][liveWtColumnIndex].value) / Number(value)
                      ).toFixed(2),
                    };
              }
            }

            if (selectedPigWtType === 'avgPigWt') {
              grid[item.row][avgBodyWeightIndex] = isNaN(Number(value))
                ? { ...grid[item.row][avgBodyWeightIndex], value: 0 }
                : {
                    ...grid[item.row][avgBodyWeightIndex],
                    value: Number(grid[item.row][avgBodyWeightIndex].value).toFixed(2),
                  };

              /* For live weight calculation */
              if (grid[item.row][2].value && item.col === avgBodyWeightIndex) {
                grid[item.row][liveWtColumnIndex] = isNaN(Number(value))
                  ? { ...grid[item.row][liveWtColumnIndex], value: 0 }
                  : {
                      ...grid[item.row][liveWtColumnIndex],
                      value: (Number(value) * Number(grid[item.row][2].value)).toFixed(2),
                    };
              }

              if (grid[item.row][avgBodyWeightIndex].value && item.col === 2) {
                grid[item.row][liveWtColumnIndex] = isNaN(Number(value))
                  ? { ...grid[item.row][liveWtColumnIndex], value: 0 }
                  : {
                      ...grid[item.row][liveWtColumnIndex],
                      value: (
                        Number(grid[item.row][avgBodyWeightIndex].value) * Number(value)
                      ).toFixed(2),
                    };
              }
            }

            /* total val  column index */
            const totalValueColumnIndex = sessionType !== Enum_Session_Type_Enum.Nursery ? 7 : 5;

            /* avg value column index */
            const avgValueColumnIndex = sessionType !== Enum_Session_Type_Enum.Nursery ? 8 : 6;

            if (selectedPigWtType === 'totalPigWt') {
              /* For average calculation  */
              if (grid[item.row][2].value && item.col === totalValueColumnIndex) {
                grid[item.row][avgValueColumnIndex] = isNaN(Number(value))
                  ? { ...grid[item.row][avgValueColumnIndex], value: 0 }
                  : {
                      ...grid[item.row][avgValueColumnIndex],
                      value: (Number(value) / Number(grid[item.row][2].value)).toFixed(2),
                    };
              }

              if (grid[item.row][totalValueColumnIndex].value && item.col === 2) {
                grid[item.row][avgValueColumnIndex] = isNaN(Number(value))
                  ? { ...grid[item.row][avgValueColumnIndex], value: 0 }
                  : {
                      ...grid[item.row][avgValueColumnIndex],
                      value: (
                        Number(grid[item.row][totalValueColumnIndex].value) / Number(value)
                      ).toFixed(2),
                    };
              }
            }

            if (selectedPigWtType === 'avgPigWt') {
              /* For total value calculation  */
              if (grid[item.row][2].value && item.col === avgValueColumnIndex) {
                grid[item.row][totalValueColumnIndex] = isNaN(Number(value))
                  ? { ...grid[item.row][totalValueColumnIndex], value: 0 }
                  : {
                      ...grid[item.row][totalValueColumnIndex],
                      value: (Number(value) * Number(grid[item.row][2].value)).toFixed(2),
                    };
              }

              if (grid[item.row][avgValueColumnIndex].value && item.col === 2) {
                grid[item.row][totalValueColumnIndex] = isNaN(Number(value))
                  ? { ...grid[item.row][totalValueColumnIndex], value: 0 }
                  : {
                      ...grid[item.row][totalValueColumnIndex],
                      value: (
                        Number(grid[item.row][totalValueColumnIndex].value) * Number(value)
                      ).toFixed(2),
                    };
              }
            }

            if (sessionType !== Enum_Session_Type_Enum.Nursery) {
              /* the following logic ensures that user enters the correct fields in the given columns and if enters invalid number we show a red background */
              if (item.col === 2 || item.col === 3 || item.col === 4 || item.col === 7) {
                if (value) {
                  /* row index is used to store the index of row where validation check has failed */
                  let rowIndex: number | null = null;

                  /* column index is used to store the column index where validation check has failed */
                  let columnIndex: number | null = null;

                  if (value <= 0 && (item.col === 2 || item.col === 3 || item.col === 4)) {
                    rowIndex = item.row;
                    columnIndex = item.col;
                  } else if (item.col === 2 && !Number.isInteger(Number(value))) {
                    rowIndex = item.row;
                    columnIndex = item.col;
                  } else if (value < 0 && item.col === 7) {
                    rowIndex = item.row;
                    columnIndex = item.col;
                  } else {
                    setPigsAndBarnPigsSoldValidationErrors((prevState) =>
                      prevState.filter(
                        (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                      ),
                    );
                  }
                  if (rowIndex && columnIndex) {
                    setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                      ...prevState,
                      { rowIndex: rowIndex as number, columnIndex: columnIndex as number },
                    ]);
                  }
                } else {
                  setPigsAndBarnPigsSoldValidationErrors((prevState) =>
                    prevState.filter(
                      (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                    ),
                  );
                }
              }
            } else {
              if (item.col === 2 || item.col === 3 || item.col === 5) {
                if (value) {
                  /* row index is used to store the index of row where validation check has failed */
                  let rowIndex: number | null = null;

                  /* column index is used to store the column index where validation check has failed */
                  let columnIndex: number | null = null;

                  if (value <= 0 && (item.col === 2 || item.col === 3)) {
                    rowIndex = item.row;
                    columnIndex = item.col;
                  } else if (item.col === 2 && !Number.isInteger(Number(value))) {
                    rowIndex = item.row;
                    columnIndex = item.col;
                  } else if (value < 0 && item.col === 5) {
                    rowIndex = item.row;
                    columnIndex = item.col;
                  } else {
                    setPigsAndBarnPigsSoldValidationErrors((prevState) =>
                      prevState.filter(
                        (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                      ),
                    );
                  }
                  if (rowIndex && columnIndex) {
                    setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                      ...prevState,
                      { rowIndex: rowIndex as number, columnIndex: columnIndex as number },
                    ]);
                  }
                } else {
                  setPigsAndBarnPigsSoldValidationErrors((prevState) =>
                    prevState.filter(
                      (obj) => obj.rowIndex !== item.row || obj.columnIndex !== item.col,
                    ),
                  );
                }
              }
            }
          });
          /* Updating sheet data */
          updateSheetData(grid);
        }}
        rowRenderer={(props) => {
          return (
            <tr style={{ height: 18 }}>
              {props.children}
              {props.row !== 0 ? (
                <td className="action-cell">
                  <MinusCircleFilled
                    className={styles.deleteIconBtn}
                    onClick={() => {
                      /* Variable to store sheet data stored in state locally for further operations */
                      const grid: PigsAndBarnPigsSoldSheetGridElement[][] = [];

                      sheetData.forEach((item, index) => {
                        if (index !== props.row) {
                          grid.push(item);
                        }
                      });

                      /* after deleting a row updating the validation errors state */
                      const updatedValidationErrorsField =
                        pigsAndBarnPigsSoldValidationErrors.filter(
                          (item) => item.rowIndex !== props.row,
                        );

                      /* if a user deletes a random row then we have to adjust the row error indexes of rows after the deleted row */
                      const adjustRowIndexAfterDeletingARow = updatedValidationErrorsField.map(
                        (item) => {
                          if (item.rowIndex > props.row) {
                            return { rowIndex: item.rowIndex - 1, columnIndex: item.columnIndex };
                          }
                          return item;
                        },
                      );

                      setPigsAndBarnPigsSoldValidationErrors(adjustRowIndexAfterDeletingARow);

                      /* Updating sheet data */
                      updateSheetData(grid);
                    }}
                  />
                </td>
              ) : null}
            </tr>
          );
        }}
        valueViewer={(props) => {
          if (props.col === 0) {
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? dayjs(props.value).format(dateColumnFormat) : undefined}
              </div>
            );
          }
          if (props.col === (sessionType === Enum_Session_Type_Enum.Nursery ? 3 : 4)) {
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? `${Number(props.value).toFixed(2)}` : undefined}
              </div>
            );
          }
          if (props.col === 5 && sessionType !== Enum_Session_Type_Enum.Nursery) {
            if (props.value === 0) {
              return <div className={styles.formattedValuesCols}>{props.value}</div>;
            }
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? `${Number(props.value).toFixed(2)} %` : undefined}
              </div>
            );
          }
          if (
            props.col === (sessionType !== Enum_Session_Type_Enum.Nursery ? 8 : 6) ||
            props.col === (sessionType !== Enum_Session_Type_Enum.Nursery ? 7 : 5)
          ) {
            if (props.value === 0) {
              return <div className={styles.formattedValuesCols}>{props.value}</div>;
            }
            return (
              <div className={styles.formattedValuesCols}>
                {props.value ? `$ ${Number(props.value).toFixed(2)}` : undefined}
              </div>
            );
          }
          return <div className={styles.formattedValuesCols}>{props.value}</div>;
        }}
      />

      {/* SheetActionButtonsComponent */}
      <SheetActionButtonsComponent
        bulkUploadProps={{
          commonBulkUpload: setShowCommonBulkUploadModal,
          bulkUpload: setShowBulkUploadModal,
        }}
        addNewRowFunc={() => {
          if (sessionType !== Enum_Session_Type_Enum.Nursery) {
            /* Variable to store new row data to be added when new row btn clicked */
            const newRowData: PigsAndBarnPigsSoldSheetIndividualGridElement[] = [
              {
                date: '',
                isCull: null,
                hdOut: undefined,
                carcassWt: undefined,
                liveWt: undefined,
                yield: undefined,
                avg: undefined,
                avgWt: undefined,
                totalVal: undefined,
              },
            ];
            /* Variable to store sheet data stored in state locally for further operations */
            const grid = [
              ...sheetData,
              ...newRowData.map((item) => [
                ...renderPigsAndBarnPigsSoldSheetRows(
                  item,
                  selectedPigWtType === 'avgPigWt' ? [4, 5, 7] : [],
                ),
              ]),
            ];
            /* Updating sheet data */
            updateSheetData(grid);
          } else {
            /* Variable to store new row data to be added when new row btn clicked */
            const newRowData: PigsAndBarnPigsSoldSheetIndividualGridElement[] = [
              {
                date: '',
                isCull: null,
                hdOut: undefined,
                liveWt: undefined,
                avg: undefined,
                avgWt: undefined,
                totalVal: undefined,
              },
            ];
            /* Variable to store sheet data stored in state locally for further operations */
            const grid = [
              ...sheetData,
              ...newRowData.map((item) => [
                ...renderPigsAndBarnPigsSoldSheetRowsForNurserySession(
                  item,
                  selectedPigWtType === 'avgPigWt' ? [4, 7] : [],
                ),
              ]),
            ];
            /* Updating sheet data */
            updateSheetData(grid);
          }
        }}
      />

      <Row className={styles.finalOpRow} style={{ width: 1360, paddingLeft: 0 }}>
        <Col className={styles.finalOpCol} style={{ width: 105 }}>
          {calculateMaxOutputDate(sheetData) || '-'}
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 100 }}>
          -
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 250 }}>
          {calculateHdOut(sheetData, 2) || '-'}
        </Col>
        {sessionType !== Enum_Session_Type_Enum.Nursery ? (
          <Col className={styles.finalOpCol} style={{ width: 150 }}>
            {calculateCarCassWeight() || '-'}
          </Col>
        ) : null}
        <Col
          className={styles.finalOpCol}
          style={{ width: sessionType !== Enum_Session_Type_Enum.Nursery ? 100 : 105 }}
        >
          {calculateLiveWt(sessionType === Enum_Session_Type_Enum.Nursery ? 3 : 4)
            ? calculateLiveWt(sessionType === Enum_Session_Type_Enum.Nursery ? 3 : 4).toFixed(2)
            : '-'}
        </Col>
        {sessionType !== Enum_Session_Type_Enum.Nursery ? (
          <Col className={styles.finalOpCol} style={{ width: 100 }}>
            {calculateYield() ? `${calculateYield().toFixed(2)} %` : '-'}
          </Col>
        ) : null}
        <Col className={styles.finalOpCol} style={{ width: 100 }}>
          {calculateAvgWeight() ? calculateAvgWeight().toFixed(2) : '-'}
        </Col>
        <Col className={styles.finalOpCol} style={{ width: 125 }}>
          {calculateTotalValue() ? `$ ${calculateTotalValue().toFixed(2)}` : '-'}
        </Col>
        <Col
          className={styles.finalOpCol}
          style={{ width: sessionType !== Enum_Session_Type_Enum.Nursery ? 165 : 170 }}
        >
          {calculateAvg() ? `$ ${calculateAvg().toFixed(2)}` : '-'}
        </Col>
      </Row>

      {/* Output Information */}
      <div style={{ marginTop: 20 }}>
        <Descriptions
          title={
            <Tooltip
              color="white"
              title={
                <div style={{ color: 'black' }}>
                  Calculation summaries for economic measures calculated based on pig inventory,
                  revenue and expense entries.
                </div>
              }
            >
              <span style={{ fontSize: 18, color: '#000000D9' }}>Output Information</span>
            </Tooltip>
          }
        >
          <Descriptions.Item label="Running Inventory">
            <>
              {renderInfoTooltip(
                <div style={{ color: 'black' }}>
                  Running inventory is calculating actual inventory based on sum total of pigs
                  received at last delivery minus pigs sold and dead pigs.
                </div>,
              )}
              <div className={styles.runningInventoryTxt}>{calculateRunningInventory() || 0}</div>
              {renderInfoTooltip(
                <div style={{ color: 'black' }}>
                  <div>Pigs In: {calculateHead() || '-'}</div>
                  <div>Pigs Out: {calculateHdOut(sheetData, 2) || '-'}</div>
                  <div>Pigs Dead: {calculateSumOfDeadPigs() || '-'}</div>
                </div>,
              )}
            </>
          </Descriptions.Item>
          <Descriptions.Item label="Last Day in Barn" contentStyle={{ marginLeft: 20 }}>
            {calculateLatestDateOfPigsSold() || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Pigs Culled" className="descriptionItemStyle">
            {calculatePigsCulledHeadOut() || '-'}
          </Descriptions.Item>
        </Descriptions>

        <Table<ActualAndProjectedTableType>
          dataSource={actualAndProjectedTableData}
          bordered
          pagination={false}
          size="small"
          style={{ marginBottom: 40 }}
        >
          <Table.Column
            key="pigCostByHd"
            title="Pig Cost / Hd"
            dataIndex="pigCostByHd"
            align="center"
            render={(value: number) => {
              return value && isFinite(value) ? `$ ${value.toFixed(2)}` : '-';
            }}
          />
          <Table.Column
            key="feedCostByHd"
            title="Feed Cost / Hd"
            dataIndex="feedCostByHd"
            align="center"
            render={(value: number) => {
              return value && isFinite(value) ? `$ ${value.toFixed(2)}` : '-';
            }}
          />
          <Table.Column
            key="productionCostByHd"
            title="Production Cost / Hd "
            dataIndex="productionCostByHd"
            align="center"
            render={(value: number) => {
              return value && isFinite(value) ? `$ ${value.toFixed(2)}` : '-';
            }}
          />
          <Table.Column
            key="totalCostByHd"
            title="Total Cost / Hd "
            dataIndex="totalCostByHd"
            align="center"
            render={(value: number) => {
              return value && isFinite(value) ? `$ ${value.toFixed(2)}` : '-';
            }}
          />
          <Table.Column
            key="totalReceiptsByHd"
            title="Total Receipts / Hd "
            dataIndex="totalReceiptsByHd"
            align="center"
            render={(value: number) => {
              return value && isFinite(value) ? `$ ${value.toFixed(2)}` : '-';
            }}
          />
          <Table.Column
            key="profitLossPerHd"
            title="Profit/Loss (Per Hd)"
            dataIndex="profitLossPerHd"
            align="center"
            render={(value: number) => {
              return value && isFinite(value) ? `$ ${value.toFixed(2)}` : '-';
            }}
          />
          <Table.Column
            key="profitLossPerGroup"
            title="Profit/Loss (Per Group)"
            dataIndex="profitLossPerGroup"
            align="center"
            render={(value: number) => {
              return value && isFinite(value)
                ? `$ ${parseFloat(value.toString()).toFixed(2)}`
                : '-';
            }}
          />
        </Table>
      </div>
    </>
  );
};

export default PigsAndBarnPigsSoldSheet;
