import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { LeftOutlined, RightOutlined, CalendarOutlined } from '@ant-design/icons';
import { monthNames } from '../utils/sheetGlobals';
import { getMonth, getYear, years } from '../utils/sheetHelpers';

/* DatePickerDataEditorComponent props type */
type DatePickerDataEditorComponentPropsType = {
  /* Prop to store cell value of data sheet */
  cellValue: string | number | null;
  /* onChange function which will be called after selected date from date picker */
  onChange: (
    date: Date | [Date | null, Date | null] | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ) => void;
  /* onBlur function which will be called after date picker blur event invoked */
  onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
};

/* React functional component */
const DatePickerDataEditorComponent = ({
  cellValue,
  onChange,
  onBlur,
}: DatePickerDataEditorComponentPropsType): JSX.Element => {
  /* Variable to store date picker ref */
  const datePickerRef = useRef<DatePicker>(null);

  return (
    <div
      className="datePickerInput"
      onClick={() => {
        if (datePickerRef && datePickerRef.current) {
          datePickerRef.current.setOpen(true);
        }
      }}
    >
      <DatePicker
        ref={datePickerRef}
        selected={cellValue ? new Date(cellValue as string) : undefined}
        onChange={onChange}
        todayButton="Today"
        dateFormat="MM-dd-yy"
        onBlur={onBlur}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              style={{ marginRight: 15 }}
            >
              <LeftOutlined style={{ fontSize: 12 }} />
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(Number(value))}
              style={{ marginRight: 10, border: '1px solid #00000050', borderRadius: 2 }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={monthNames[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(monthNames.indexOf(value))}
              style={{ border: '1px solid #00000050', borderRadius: 2 }}
            >
              {monthNames.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              style={{ marginLeft: 15 }}
            >
              <RightOutlined style={{ fontSize: 12 }} />
            </button>
          </div>
        )}
      />
      <CalendarOutlined
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (datePickerRef && datePickerRef.current) {
            datePickerRef.current.setOpen(true);
          }
        }}
      />
    </div>
  );
};

export default DatePickerDataEditorComponent;
