import React, { useState } from 'react';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { LockFilled } from '@ant-design/icons';
import { updatePassword } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { firebaseAuth } from '../utils/firebase';
import { useApp } from '../contexts/AppContext';
import styles from './SetNewPassword.module.scss';
import FormItem from '../components/FormItem';
import Input from '../components/Input';
import {
  SetUserFirstLoginMutation,
  SetUserFirstLoginMutationVariables,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';

const setUserFirstLoginMutation = loader('../graphql/mutations/setUserFirstLoginMutation.graphql');

/* SetNewPasswordScreen props type */
type SetNewPasswordScreenProps = {
  /* setState function to update showSetNewPasswordScreen which decides whether to show/hide set new password screen */
  setShowSetNewPasswordScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

/* set new password form type */
type SetNewPasswordFormType = {
  /* New password to modify current one */
  newPassword: string;
  /* Confirm New password to modify current one */
  confirmNewPassword: string;
};

/* React functional component */
const SetNewPasswordScreen = ({
  setShowSetNewPasswordScreen,
}: SetNewPasswordScreenProps): JSX.Element => {
  /* Destructuring useAppData hook */
  const { setUser, user } = useApp();
  /* State used whether to show/hide update btn loading indicator */
  const [showBtnLoader, setShowBtnLoader] = useState<boolean>(false);

  /* Mutation to update user's first login details */
  const [setUserFirstLogin] = useMutation<
    SetUserFirstLoginMutation,
    SetUserFirstLoginMutationVariables
  >(setUserFirstLoginMutation);

  /* useForm declaration */
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<SetNewPasswordFormType>({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  /* Function to submit form to set user accounts new password */
  const onSubmit = (data: SetNewPasswordFormType) => {
    setShowBtnLoader(true);

    /* Fetching current user from firebase auth */
    const { currentUser } = firebaseAuth;

    if (currentUser) {
      /* Updating user's password */
      updatePassword(currentUser, data.newPassword)
        .then(() => {
          if (user) {
            setUserFirstLogin({
              variables: {
                id: user.id,
              },
            })
              .then((res) => {
                /* Variable to store user's first login time */
                const firstLoginAt = res.data?.update_user_by_pk?.first_login_at;

                if (firstLoginAt) {
                  setUser({ ...user, first_login_at: firstLoginAt });
                  setShowBtnLoader(false);
                  setShowSetNewPasswordScreen(false);
                }
              })
              .catch((err) => {
                logger(err);
                setShowBtnLoader(false);
              });
          }
        })
        .catch((err: FirebaseError) => {
          logger(err);
          if (err.code === 'auth/requires-recent-login') {
            firebaseAuth
              .signOut()
              .then((): void => {
                /* resetting user context */
                setUser(null);
                setShowSetNewPasswordScreen(false);
              })
              .catch((firebaseAuthError) => {
                logger(firebaseAuthError);
              });
          }
          setShowBtnLoader(false);
        });
    }
  };

  return (
    <div className="fullScreen">
      <div className={styles.formContainer}>
        <h1 className={styles.formHeading}>Set New Password</h1>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={styles.firstLoginTxt}>
            This is your first login. Please set a new password.
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem
            errorText={
              errors.newPassword && errors.newPassword.message
                ? errors.newPassword.message
                : undefined
            }
            customStyle={{ marginTop: 15 }}
          >
            <Input
              customStyles={{ borderRadius: 5, borderColor: '#999999', borderWidth: 2 }}
              placeholder="Please enter new password"
              isPasswordInput
              name="newPassword"
              rhfControllerProps={{
                control,
                rules: {
                  required: {
                    value: true,
                    message: 'Please enter new password and try again',
                  },
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 letters long',
                  },
                },
              }}
              inputProps={{
                prefix: <LockFilled style={{ color: '#999999', marginRight: 10 }} />,
                type: 'password',
              }}
            />
          </FormItem>

          <FormItem
            errorText={
              errors.confirmNewPassword && errors.confirmNewPassword.message
                ? errors.confirmNewPassword.message
                : undefined
            }
            customStyle={{ marginTop: 20 }}
          >
            <Input
              customStyles={{ borderRadius: 5, borderColor: '#999999', borderWidth: 2 }}
              placeholder="Please confirm new password"
              isPasswordInput
              name="confirmNewPassword"
              rhfControllerProps={{
                control,
                rules: {
                  required: {
                    value: true,
                    message: 'Please enter confirm new password and try again',
                  },
                  validate: (val: string) =>
                    val === watch('newPassword') || 'The entered passwords do not match.',
                },
              }}
              inputProps={{
                prefix: <LockFilled style={{ color: '#999999', marginRight: 10 }} />,
                type: 'password',
              }}
            />
          </FormItem>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className={
                watch('newPassword') === '' || watch('confirmNewPassword') === ''
                  ? styles.buttonColorDisabled
                  : 'buttonColorRed'
              }
              htmlType="submit"
              style={{
                width: 150,
                height: 40,
                fontSize: 18,
                marginTop: 40,
              }}
              disabled={watch('newPassword') === '' || watch('confirmNewPassword') === ''}
              loading={showBtnLoader}
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetNewPasswordScreen;
