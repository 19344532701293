import React, { useState } from 'react';
import { Table, Button, Popconfirm, message } from 'antd';
import { WarningFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, Reference, ApolloError, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SessionNameModalComponent from '../components/SessionNameModalComponent';
import ShowWarningComponent from '../components/ShowWarningComponent';
import {
  DeleteSessionByPkMutation,
  DeleteSessionByPkMutationVariables,
  SessionByIdQuery,
  SessionByIdQueryVariables,
  AddDataMutation,
  AddDataMutationVariables,
  UpdateSessionByPkMutation,
  UpdateSessionByPkMutationVariables,
  GetSavedSessionsQuery,
  GetSavedSessionsQueryVariables,
  Enum_Session_Type_Enum,
} from '../graphql/graphql-types';
import { useApp } from '../contexts/AppContext';
import {
  LocalForageDataType,
  PigsAndBarnPigsInSheetIndividualGridElement,
  PigsAndBarnPigsSoldSheetIndividualGridElement,
  DeathLossSheetIndividualGridElement,
  ProductionExpensesSheetIndividualGridElement,
  FeedDeliverySheetIndividualGridElement,
} from '../utils/types';
import { getSheetDataFromLocalForage, handleSaveSessionData, logger } from '../utils/helpers';
import styles from './ViewSessionHistoryScreen.module.scss';

/* session table data type */
type SessionTableDataType = {
  /* session name */
  name: string;
  /* session id */
  id: string;
  /* date on which the session is created */
  created_at: string | Date;
  /* date on which the session is updated */
  updated_at: string | Date;
  /* user details */
  user: {
    /* User first name */
    first_name: string;
    /* User last name */
    last_name: string;
  };
  session_type?: Enum_Session_Type_Enum | null;
};

/* loading delete session by pk mutation with the help of loader */
const deleteSessionByPkMutation = loader('../graphql/mutations/deleteSessionByPkMutation.graphql');
/* Loading add data mutation */
const addDataMutation = loader('../graphql/mutations/addDataMutation.graphql');
/* Loading update session data mutation */
const updateSessionByPkMutation = loader('../graphql/mutations/updateSessionByPkMutation.graphql');
/* Loading session by ID query */
const sessionByIdQuery = loader('../graphql/queries/sessionByIdQuery.graphql');
/* loading saved sessions */
const getSavedSessionsQuery = loader('../graphql/queries/getSavedSessionsQuery.graphql');

/* React functional component */
const ViewSessionHistoryScreen = (): JSX.Element => {
  /* extracting navigate from use Navigation */
  const navigate = useNavigate();

  /* extracting apolloClient from useApolloClient hook */
  const apolloClient = useApolloClient();

  /* extracting user data from context */
  const { user } = useApp();

  /* useState to store selected user id when user hits delete button which will then use for loading indication*/
  const [deleteSessionId, setDeleteSessionId] = useState<string | null>(null);
  /* useState to store selected user id when user hits delete button which will then use for loading indication*/
  const [recallSessionId, setRecallSessionId] = useState<string | null>(null);

  const [showSessionNameModal, setShowSessionNameModal] = useState<boolean>(false);
  /* State to decide whether to show/hide loading indicator on save to db btn */
  const [saveToDbBtnLoading, setSaveToDbBtnLoading] = useState<boolean>(false);

  /* state to store the duplicate session name error and then passing it down to show name modal component */
  const [duplicateSessionNameError, setDuplicateSessionNameError] = useState<string | undefined>(
    undefined,
  );

  /* delete session mutation */
  const [deleteSessionByPk] = useMutation<
    DeleteSessionByPkMutation,
    DeleteSessionByPkMutationVariables
  >(deleteSessionByPkMutation);

  /* Mutation to add data to db */
  const [addData] = useMutation<AddDataMutation, AddDataMutationVariables>(addDataMutation);

  /* Mutation to update session data to db */
  const [updateSessionByPk] = useMutation<
    UpdateSessionByPkMutation,
    UpdateSessionByPkMutationVariables
  >(updateSessionByPkMutation);

  /* saved session by user query */
  const {
    data: getSavedSessionData,
    loading: getSavedSessionLoading,
    error: getSavedSessionError,
  } = useQuery<GetSavedSessionsQuery, GetSavedSessionsQueryVariables>(getSavedSessionsQuery, {
    fetchPolicy: 'network-only',
  });

  /* function to delete the session selected by running mutation query and updating cache */
  const deleteSelectedSession = (deletedId: string) => {
    deleteSessionByPk({
      variables: { id: deletedId },
      update(cache, { data: deleteData }) {
        /* to get the id of the data to be deleted */
        const idToRemove = deleteData?.delete_data_by_pk?.id;

        cache.modify({
          fields: {
            data(existingSessions: Array<Reference>, { readField }) {
              if (idToRemove) {
                return existingSessions.filter(
                  (sessionData) => idToRemove !== readField('id', sessionData),
                );
              }
              return existingSessions;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteSessionId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Session has been successfully deleted');
      })
      .catch((deleteSessionError: ApolloError) => {
        setDeleteSessionId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(deleteSessionError.message);
      });
  };

  /* Function to recall selected session data by user */
  const recallSelectedSessionData = (id?: string) => {
    /* Variable to store session id */
    const sessionId = id ? id : (recallSessionId as string);

    apolloClient
      .query<SessionByIdQuery, SessionByIdQueryVariables>({
        query: sessionByIdQuery,
        variables: {
          id: sessionId,
        },
      })
      .then((res) => {
        /* Variable to store fetched session data */
        const fetchedData = res.data.data_by_pk;

        if (fetchedData) {
          /* Variable to store session data to store it in local storage */
          const sessionData: LocalForageDataType = {
            pigs_and_barn_pigs_in:
              fetchedData.pigs_and_barn_pigs_in as PigsAndBarnPigsInSheetIndividualGridElement[],
            pigs_and_barn_pigs_sold:
              fetchedData.pigs_and_barn_pigs_sold as PigsAndBarnPigsSoldSheetIndividualGridElement[],
            death_loss: fetchedData.death_loss as DeathLossSheetIndividualGridElement[],
            production_expenses:
              fetchedData.production_expenses as ProductionExpensesSheetIndividualGridElement[],
            feed_delivery: fetchedData.feed_delivery as FeedDeliverySheetIndividualGridElement[],
            producerName: fetchedData.producer_name,
            groupId: fetchedData.group_id as string,
            location: fetchedData.location as string,
            id: fetchedData.id,
            name: fetchedData.name,
            sessionType: fetchedData.session_type as Enum_Session_Type_Enum,
            feedLeft: fetchedData.feed_left ? (fetchedData.feed_left as number) : undefined,
          };

          handleSaveSessionData(sessionData)
            .then(() => {
              navigate('/data');
              setRecallSessionId(null);
            })
            .catch((err: ApolloError) => {
              if (err.name === 'Error' && err.message === 'Failed to fetch') {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error('Cannot save data when device is offline.');
              }
              logger(err);
              setRecallSessionId(null);
            });
        }
      })
      .catch((err: ApolloError) => {
        if (err.name === 'Error' && err.message === 'Failed to fetch') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('Cannot save data when device is offline.');
        }
        logger(err);
        setRecallSessionId(null);
      });
  };

  /* Function to get filtered data from existing data based on conditions */
  const getFilteredDataBasedOnCondition = (savedData: LocalForageDataType) => {
    /* Variable to store pigs and barn pigs in data  */
    const pigsAndBarnPigsInData =
      Array.isArray(savedData.pigs_and_barn_pigs_in) && savedData.pigs_and_barn_pigs_in.length > 0
        ? savedData.pigs_and_barn_pigs_in.filter(
            (item) =>
              item.date || item.head || item.weight || item.avgWt || item.totalVal || item.avg,
          )
        : [];

    /* Variable to store pigs and barn pigs sold data  */
    const pigsAndBarnPigsSoldData =
      Array.isArray(savedData.pigs_and_barn_pigs_sold) &&
      savedData.pigs_and_barn_pigs_sold.length > 0
        ? savedData.pigs_and_barn_pigs_sold.filter(
            (item) =>
              item.date ||
              item.isCull ||
              item.hdOut ||
              item.carcassWt ||
              item.liveWt ||
              item.yield ||
              item.avgWt ||
              item.totalVal ||
              item.avg,
          )
        : [];

    /* Variable to store death loss data  */
    const deathLossData =
      Array.isArray(savedData.death_loss) && savedData.death_loss.length > 0
        ? (savedData.death_loss as DeathLossSheetIndividualGridElement[]).filter(
            (item) => item.date || item.dead || item.weight || item.reason || item.deathWeek,
          )
        : [];

    /* Variable to store feed delivery data  */
    const feedDeliveryData =
      Array.isArray(savedData.feed_delivery) && savedData.feed_delivery.length > 0
        ? savedData.feed_delivery.filter(
            (item) =>
              // item.delivery ||
              item.date ||
              item.feedType ||
              item.poundsDelivered ||
              item.feedDetails ||
              item.actualFeedCost,
          )
        : [];

    /* Variable to store production expenses data  */
    const productionExpensesData =
      Array.isArray(savedData.production_expenses) && savedData.production_expenses.length > 0
        ? savedData.production_expenses.filter(
            (item) => item.date || item.description || item.account || item.totalCost,
          )
        : [];

    return {
      pigsAndBarnPigsInData,
      pigsAndBarnPigsSoldData,
      deathLossData,
      productionExpensesData,
      feedDeliveryData,
    };
  };

  /* Function to handle add new session before recalling existing session */
  const handleAddNewSessionBeforeRecall = (sessionName: string) => {
    getSheetDataFromLocalForage()
      .then((savedData) => {
        if (savedData) {
          /* Getting filtered data from existing saved data */
          const {
            pigsAndBarnPigsInData,
            pigsAndBarnPigsSoldData,
            deathLossData,
            feedDeliveryData,
            productionExpensesData,
          } = getFilteredDataBasedOnCondition(savedData);

          /* Mutation values that will be sent to mutation */
          const mutationValues = {
            name: sessionName,
            producer_name: savedData.producerName,
            location: savedData.location,
            group_id: savedData.groupId,
            pigs_and_barn_pigs_in: pigsAndBarnPigsInData.length > 0 ? pigsAndBarnPigsInData : null,
            pigs_and_barn_pigs_sold:
              pigsAndBarnPigsSoldData.length > 0 ? pigsAndBarnPigsSoldData : null,
            death_loss: deathLossData.length > 0 ? deathLossData : null,
            production_expenses: productionExpensesData.length > 0 ? productionExpensesData : null,
            feed_delivery: feedDeliveryData.length > 0 ? feedDeliveryData : null,
            session_type: savedData.sessionType as Enum_Session_Type_Enum,
            feed_left: savedData.feedLeft ? Number(savedData.feedLeft) : null,
          };

          addData({ variables: mutationValues })
            .then(() => {
              setSaveToDbBtnLoading(false);
              setShowSessionNameModal(false);
              setDuplicateSessionNameError(undefined);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success('Data has been successfully saved to database.');
              recallSelectedSessionData();
            })
            .catch((err: ApolloError) => {
              logger(err);
              if (
                err.message ===
                'Uniqueness violation. duplicate key value violates unique constraint "data_name_key"'
              ) {
                setDuplicateSessionNameError(
                  'Session name already exists in DB. Please enter different name and try again”',
                );
              }
              if (err.name === 'Error' && err.message === 'Failed to fetch') {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error('Cannot save data when device is offline.');
              }
              setSaveToDbBtnLoading(false);
              setRecallSessionId(null);
            });
        }
      })
      .catch((err) => {
        logger(err);
        setRecallSessionId(null);
      });
  };

  /* Function to handle update existing session */
  const handleUpdateSessionData = (id: string, savedData: LocalForageDataType) => {
    /* Getting filtered data from existing saved data */
    const {
      pigsAndBarnPigsInData,
      pigsAndBarnPigsSoldData,
      deathLossData,
      feedDeliveryData,
      productionExpensesData,
    } = getFilteredDataBasedOnCondition(savedData);

    /* Mutation values that will be sent to mutation */
    const mutationValues = {
      name: savedData.name,
      producer_name: savedData.producerName,
      location: savedData.location,
      group_id: savedData.groupId,
      pigs_and_barn_pigs_in: pigsAndBarnPigsInData.length > 0 ? pigsAndBarnPigsInData : null,
      pigs_and_barn_pigs_sold: pigsAndBarnPigsSoldData.length > 0 ? pigsAndBarnPigsSoldData : null,
      death_loss: deathLossData.length > 0 ? deathLossData : null,
      production_expenses: productionExpensesData.length > 0 ? productionExpensesData : null,
      feed_delivery: feedDeliveryData.length > 0 ? feedDeliveryData : null,
      feed_left: savedData.feedLeft ? Number(savedData.feedLeft) : null,
    };

    updateSessionByPk({
      variables: {
        id: savedData.id as string,
        sessionData: mutationValues,
      },
    })
      .then((res) => {
        /* Variable to store session name */
        const sessionName = res.data?.update_data_by_pk?.name;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(`${sessionName || ''} has been updated with previous locally saved data.`);
        recallSelectedSessionData(id);
      })
      .catch((err: ApolloError) => {
        logger(err);
        setRecallSessionId(null);
        /* when the device is offline,query will throw 'failed to fetch' error and we have to show the below error message on click of recall button */
        if (err.name === 'Error' && err.message === 'Failed to fetch') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('Cannot recall saved session as device is offline.');
        }
      });
  };

  /* Function to handle recall session */
  /***
   * In below function 3 conditions are checked
   * 1. Locally saved data with id - here locally saved data will be updated to db first then session will be recalled
   * 2. Locally saved data without id - here locally saved data will be added to db first and then session will be recalled
   * 3. No locally saved data - here session will be recalled directly
   */
  const handleRecallSession = (id: string) => {
    getSheetDataFromLocalForage()
      .then((savedData) => {
        if (savedData) {
          if (savedData.id) {
            handleUpdateSessionData(id, savedData);
          } else {
            setShowSessionNameModal(true);
          }
        } else {
          recallSelectedSessionData(id);
        }
      })
      .catch((err: ApolloError) => {
        /* when the device is offline,query will throw 'failed to fetch' error and we have to show the below error message on click of recall button */
        if (err.name === 'Error' && err.message === 'Failed to fetch') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('Cannot recall saved session as device is offline.');
        }
        logger(err);
        setRecallSessionId(null);
      });
  };

  if (
    getSavedSessionData &&
    Array.isArray(getSavedSessionData.data) &&
    getSavedSessionData.data.length === 0
  ) {
    return (
      <div style={{ marginTop: 40, marginLeft: 20 }}>
        <ShowWarningComponent message="No saved sessions found" />
      </div>
    );
  }

  if (getSavedSessionError) {
    if (
      getSavedSessionError.name === 'Error' &&
      getSavedSessionError.message === 'Failed to fetch'
    ) {
      /* this is to deny access to session table when user is offline */
      return (
        <div style={{ marginTop: 40, marginLeft: 20 }}>
          <ShowWarningComponent
            message="Cannot access History to recall saved session as device is offline."
            containerStyle={{ width: 470 }}
          />
        </div>
      );
    }
    return <div style={{ color: 'red' }}>{getSavedSessionError.message}</div>;
  }

  return (
    <div style={{ paddingBottom: 25 }}>
      {showSessionNameModal ? (
        <SessionNameModalComponent
          isModalVisible={showSessionNameModal}
          handleSave={(sessionName) => {
            handleAddNewSessionBeforeRecall(sessionName);
          }}
          handleCancel={() => {
            setShowSessionNameModal(false);
            setRecallSessionId(null);
            setDuplicateSessionNameError(undefined);
          }}
          saveToDbBtnLoading={saveToDbBtnLoading}
          showWarning
          duplicateSessionNameError={duplicateSessionNameError}
          setDuplicateSessionError={setDuplicateSessionNameError}
        />
      ) : null}

      <Table<SessionTableDataType>
        dataSource={
          getSavedSessionData &&
          Array.isArray(getSavedSessionData.data) &&
          getSavedSessionData.data.length > 0
            ? getSavedSessionData.data
            : []
        }
        style={{ width: '70%', marginLeft: 30, marginTop: 50 }}
        size="small"
        bordered
        loading={getSavedSessionLoading}
        rowKey={(record) => record.id}
      >
        <Table.Column title="Session Name" key="name" dataIndex="name" align="center" />
        <Table.Column
          title="Session Type"
          key="session_type"
          dataIndex="session_type"
          align="center"
          render={(text) => {
            if (text === Enum_Session_Type_Enum.Nursery) {
              return 'Nursery';
            }
            if (text === Enum_Session_Type_Enum.GrowFinish) {
              return 'Grow-Finish';
            }
            return 'Wean-Finish';
          }}
        />
        {user && user.role === 'app_admin' ? (
          <Table.Column<SessionTableDataType>
            title="Created by"
            key="user"
            dataIndex="user"
            align="center"
            render={(text, record) => `${record.user.first_name} ${record.user.last_name}`}
          />
        ) : null}

        <Table.Column
          title="Created at"
          key="created_at"
          dataIndex="created_at"
          align="center"
          render={(text) => {
            return dayjs(text).format('YYYY-MM-DD hh:mm A');
          }}
        />
        <Table.Column
          title="Updated at"
          key="updated_at"
          dataIndex="updated_at"
          align="center"
          render={(text) => {
            return dayjs(text).format('YYYY-MM-DD hh:mm A');
          }}
        />
        <Table.Column<SessionTableDataType>
          key="actions"
          title="Options"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <>
                <Button
                  className={styles.recallBtn}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setRecallSessionId(record.id);
                    handleRecallSession(record.id);
                  }}
                  loading={record.id === recallSessionId}
                >
                  Recall
                </Button>
                <Popconfirm
                  title="Delete session. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    deleteSelectedSession(record.id);
                    setDeleteSessionId(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: '#CE0E2D' }} />}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{
                      borderRadius: 5,
                    }}
                    loading={record.id === deleteSessionId}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default ViewSessionHistoryScreen;
