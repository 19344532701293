import React from 'react';
import { Button, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormItem from './FormItem';
import Input from './Input';
import styles from './SessionNameModalComponent.module.scss';
import ShowWarningComponent from './ShowWarningComponent';

/* SessionNameModalComponent props type */
type SessionNameModalComponentType = {
  /* Prop to decide whether to show/hide modal */
  isModalVisible: boolean;
  /* Function that will be called after user clicks on save btn */
  handleSave: (sessionName: string) => void;
  /* Function that will be called after user clicks on cancel btn */
  handleCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /* Prop to decide whether to show/hide loading indicator on save btn */
  saveToDbBtnLoading: boolean;
  /* Prop to decide whether to show/hide warning msg */
  showWarning?: boolean;
  /* duplicate session name error which will be ocurred when user enters a session name already present in the database */
  duplicateSessionNameError?: string;
  /* state to set the duplicate session name error */
  setDuplicateSessionError?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

/* Session name form type */
type SessionNameFormType = {
  /* session name */
  sessionName: string;
};

/* Validation schema */
const schema = yup.object().shape({
  sessionName: yup.string().required('Please enter session name and try again'),
});

/* React functional component */
const SessionNameModalComponent = ({
  isModalVisible,
  handleSave,
  handleCancel,
  saveToDbBtnLoading,
  showWarning = false,
  duplicateSessionNameError = undefined,
  setDuplicateSessionError = () => {},
}: SessionNameModalComponentType): JSX.Element => {
  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SessionNameFormType>({
    defaultValues: {
      sessionName: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <Modal footer={false} visible={isModalVisible} onCancel={handleCancel} destroyOnClose centered>
      <FormItem
        errorText={errors.sessionName ? errors.sessionName.message : duplicateSessionNameError}
        label="Session Name"
        isRequired
        displayMode="column"
        labelStyle={{ fontWeight: 'bold' }}
        inputColSpan={24}
        containerStyle={{ paddingLeft: 0 }}
        customStyle={{ paddingTop: 0 }}
        errorTextStyle={{ textAlign: 'left' }}
        showRequiredMark="after"
      >
        <Input
          customStyles={{ borderRadius: 5, borderColor: '#999999', borderWidth: 2 }}
          placeholder="Please enter a name for this session"
          name="sessionName"
          rhfControllerProps={{ control }}
          onChange={(rhfOnChange, value) => {
            rhfOnChange(value);
            if (duplicateSessionNameError) {
              setDuplicateSessionError(undefined);
            }
          }}
          inputProps={{ style: { width: '100%', borderColor: 'black', borderRadius: 5 } }}
        />
      </FormItem>

      {showWarning ? (
        <ShowWarningComponent
          message="Recalling a session will delete locally saved data. Save it to DB before proceeding"
          containerStyle={{ width: '100%', marginTop: 20 }}
        />
      ) : null}

      <div className={styles.btnContainer}>
        <Button onClick={handleCancel} className={styles.cancelBtn}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            handleSubmit((data) => {
              handleSave(data.sessionName);
            })();
          }}
          loading={saveToDbBtnLoading}
          disabled={saveToDbBtnLoading}
          className="buttonColorRed"
          style={{ marginLeft: 20 }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default SessionNameModalComponent;
