import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Button } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';

/* type for props of ColumnSearchComponent */
type ColumnSearchProps = {
  /* this prop type used to store property name from which user want to search into the column */
  filterBy: string;
  /* prop type used to store table filter dropdown prop */
  filterDropDownProp: FilterDropdownProps;
};

/* React functional component which will be used for column search functionality of the table */
const ColumnSearchComponent = ({
  filterBy,
  filterDropDownProp,
}: ColumnSearchProps): JSX.Element => {
  /* destructing filter dropdown prop */
  const { selectedKeys, setSelectedKeys, confirm, clearFilters } = filterDropDownProp;

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search by ${filterBy.split('.')[0]}`}
        value={selectedKeys[0] ? selectedKeys[0].toString() : selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => {
          confirm();
        }}
        style={{ marginBottom: 8, display: 'block', borderRadius: 5 }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm();
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, borderRadius: 5 }}
          className="buttonColorRed"
        >
          Search
        </Button>
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
              confirm();
            }
          }}
          size="small"
          style={{ width: 90, borderRadius: 5 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default ColumnSearchComponent;
