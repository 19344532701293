import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  PigsAndBarnPigsInSheetGridElement,
  PigsAndBarnPigsSoldSheetGridElement,
  DeathLossSheetGridElement,
  ProductionExpensesSheetGridElement,
  FeedDeliverySheetGridElement,
} from './types';

/* Variable to store series start date as per excel sheet */
export const seriesStartDate = '01-01-1990';

/* Date column format */
export const dateColumnFormat = 'MM-DD-YY';

/* common props passed to tool tips */
const toolTipCommonProps = {
  color: 'white',
  className: 'cellHeaderTitleTxt',
  overlayInnerStyle: { borderRadius: 5 },
};

/* Function to render header tooltip */
const renderHeaderTooltip = (title: string, message: string): JSX.Element => {
  return (
    <Tooltip title={<div style={{ color: 'black' }}>{message}</div>} {...toolTipCommonProps}>
      {title}
    </Tooltip>
  );
};

/* Pigs and barn pigs in sheet columns to be rendered */
export const pigsAndBarnPigsInSheetColumns: PigsAndBarnPigsInSheetGridElement[] = [
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Date</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Head', 'Total number of pigs received'),
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Body Weight,Total', 'Grand Total Weight of all pigs received.'),
    forceComponent: true,
    className: 'pigsAndBarnPigsInTotalColHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleStyle">Avg. Body Wt.</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Total Value, US$', 'Grand Total Value of all pigs received.'),
    forceComponent: true,
    className: 'pigsAndBarnPigsInTotalColHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleStyle">Avg. Value, US$/head</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
];

/* Pigs and barn pigs sold sheet columns to be rendered */
export const pigsAndBarnPigsSoldSheetColumns: PigsAndBarnPigsSoldSheetGridElement[] = [
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Date</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip(
      'Is Cull?',
      'If Pigs are removed and not sold then (1) if euthanized, then these removals should be counted in Death Loss and reason given as “Other” or (2) if sold as CULLS, check the box to indicate “CULL”.',
    ),
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Total Head Out/ Total Head Sold', 'Total number of head sold.'),
    forceComponent: true,
    className: 'pigsAndBarPigsSoldTotalHeadCol',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip(
      'Total Carcass Wt.',
      'Total Hot Carcass Wt value reported on slaughter data sheet',
    ),
    forceComponent: true,
    className: 'pigsAndBarPigsSoldTotalCarcassWeightCol',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip(
      'Total Live Wt.',
      'Total Live Wt. Value reported on slaughter data sheet',
    ),
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Yield, %', 'Values is automatically calculated.'),
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Avg. Body Wt.', 'Values is automatically calculated.'),
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip(
      'Total Value, US$',
      'Total payment received for group of pigs sold.',
    ),
    forceComponent: true,
    className: 'pigsAndBarPigsSoldTotalValueCol',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Avg. Value, US$/Head', 'Values is automatically calculated.'),
    forceComponent: true,
    className: 'pigsAndBarPigsSoldAvgCol',
  },
];

/* pigs sold sheet columns to be rendered for nursery session type */
export const pigsSoldSheetColumnsForNurserySessionType = pigsAndBarnPigsSoldSheetColumns.filter(
  (item, index) => !(index === 3 || index === 5),
);

/* Production Expenses sheet columns to be rendered */
export const productionExpensesSheetColumns: ProductionExpensesSheetGridElement[] = [
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Date</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Description</span>,
    forceComponent: true,
    className: 'productionExpensesDescriptionCol',
  },
  {
    value: null,
    disableEvents: true,
    component: (
      <Tooltip
        title={
          <div style={{ color: 'black' }}>
            Name of product or service that will align with “Account” from list , i.e. Description,
            Electrician Account, Custom Hire or Professional Services or Repair
          </div>
        }
        {...toolTipCommonProps}
      >
        Account <InfoCircleTwoTone style={{ marginLeft: 2 }} />
      </Tooltip>
    ),
    forceComponent: true,
    className: 'productionExpensesAccountCol',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Total Cost', 'Total cost value US$ for item and/or service.'),
    forceComponent: true,
    className: 'productionExpensesTotalCostCol',
  },
];

/* Death loss sheet columns to be rendered */
export const deathLossSheetColumns: DeathLossSheetGridElement[] = [
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Date</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt"># Dead</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Total Dead Weight</span>,
    forceComponent: true,
    className: 'deathLossDeadWeightCol',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Reason</span>,
    forceComponent: true,
    className: 'deathLossReasonCol',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Death Week</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
];

// death reason stored
export const deathReasons = [
  'Basic Enteric',
  'Basic Respiratory',
  'Circo Virus',
  'E-Coli',
  'HBS',
  'Ileitis',
  'Influenza',
  'Mulberry Heart',
  'Mycoplasma',
  'Other',
  'Other Pneumonia',
  'PRRS',
  'Salmonella',
  'Savaged',
  'Strep Suis',
  'Trauma',
];

/* Feed Delivery sheet columns to be rendered */
export const feedDeliverySheetColumns: FeedDeliverySheetGridElement[] = [
  /* Code commented for future use */
  // {
  //   value: null,
  //   disableEvents: true,
  //   component: renderHeaderTooltip('Delivery #', 'Numerical order of feed deliveries.'),
  //   forceComponent: true,
  //   className: 'sheetHeader',
  // },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Date</span>,
    forceComponent: true,
    className: 'sheetHeader',
  },
  {
    value: null,
    disableEvents: true,
    component: (
      <Tooltip
        title={
          <div style={{ color: 'black' }}>
            Select Ration Number in series of feeding program, Ration 1 – 14
          </div>
        }
        {...toolTipCommonProps}
      >
        Feed Type <InfoCircleTwoTone style={{ marginLeft: 2 }} />
      </Tooltip>
    ),
    forceComponent: true,
    className: 'feedDeliveryFeedTypeCol',
  },
  {
    value: null,
    disableEvents: true,
    component: (
      <Tooltip
        title={
          <div style={{ color: 'black' }}>
            Provide specific description for Ration Identification, I.e. Accu6, UltraCare 300,
            N3-UC350 Base, GF1 10% DDGS, GF5 Tylan 40.
          </div>
        }
        {...toolTipCommonProps}
      >
        Feed details <InfoCircleTwoTone style={{ marginLeft: 2 }} />
      </Tooltip>
    ),
    forceComponent: true,
    className: 'feedDeliveryFeedDetailsCol',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip('Pounds Delivered', 'Total pounds of feed for this delivery.'),
    forceComponent: true,
    className: 'feedDeliveryPoundsDeliveredCol',
  },
  {
    value: null,
    disableEvents: true,
    component: renderHeaderTooltip(
      'Actual Feed Cost',
      'Total Cost US$, includes Grind, Mix, Delivery Fee if applicable.',
    ),
    forceComponent: true,
    className: 'feedDeliveryActualFeedCostCol',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Pounds of Corn</span>,
    forceComponent: true,
    className: 'feedDeliveryPoundsOfCorn',
  },
  {
    value: null,
    disableEvents: true,
    component: <span className="cellHeaderTitleTxt">Cost of Corn Total Value, $</span>,
    forceComponent: true,
    className: 'feedDeliveryCostOfCornCol',
  },
];

/* Variable to store accepted values for accounts column in production expenses sheet */
export const chartOfAccounts = [
  'Animal Health - Feed Additives',
  'Animal Health - Other',
  'Chemicals',
  'Custom Hire',
  'Fuel, Oil & Gasoline',
  'Insurance',
  'Labor & Management',
  'Marketing',
  'Miscellaneous',
  'Professional Services',
  'Rent or Lease',
  'Repairs',
  'Supplies',
  'Taxes',
  'Utilities',
  'Trucking',
  'Yardage-Expense',
  'Yardage-Revenue',
  'Total',
];

/* Variable to store accepted values for accounts column in the form of label and value object in production expenses sheet */
export const chartOfAccountsLabelValueData = chartOfAccounts
  .filter((item) => item !== 'Total')
  .map((item) => ({
    label: item,
    value: item,
  }));

/* Variable to store feed type options in feed delivery sheet */
export const feedTypeOptions = [
  'Ration 1',
  'Ration 2',
  'Ration 3',
  'Ration 4',
  'Ration 5',
  'Ration 6',
  'Ration 7',
  'Ration 8',
  'Ration 9',
  'Ration 10',
  'Ration 11',
  'Ration 12',
  'Ration 13',
  'Ration 14',
];

/* Variable to store feed type options label value data */
export const feedTypeOptionsValueLabelData = feedTypeOptions.map((item) => ({
  label: item,
  value: item,
  costPerPound: 1, // $1,
}));

/* Variable to store feed type options label value data in feed delivery sheet */
export const feedTypeLabelValueData = feedTypeOptions.map((item) => ({
  label: item,
  value: item,
}));

/* Variable to store death reasons  options label value data in Death Loss sheet */
export const deathReasonsLabelValueData = deathReasons.map((item) => ({
  label: item,
  value: item,
}));

/* Variable to store month names */
export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/* bulk upload modal body style */
export const bulkUploadModalStyle = {
  borderTop: '1px solid black',
  marginLeft: 20,
  marginRight: 20,
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 15,
};

/* general information common input style */
export const generalInformationInputStyle = {
  width: 250,
  borderRadius: 5,
  borderColor: '#333333',
};
