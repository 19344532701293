import React from 'react';
import { Spin } from 'antd';

const LoadingScreen = (): JSX.Element => {
  return (
    <div className="fullScreen">
      <Spin size="large" />
    </div>
  );
};

export default LoadingScreen;
