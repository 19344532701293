import React from 'react';

/* ViewReleaseFormComponent props type */
type ViewReleaseFormComponentPropsType = {
  /* called from prop to check from where component is called */
  calledFrom: 'printPage' | 'panel';
};

/* React functional component */
const ViewReleaseFormComponent = ({
  calledFrom,
}: ViewReleaseFormComponentPropsType): JSX.Element => {
  return (
    <div className="printReleaseForm" style={{ marginLeft: calledFrom === 'printPage' ? 0 : 50 }}>
      <h4 style={{ textAlign: 'center' }}>Release</h4>
      <br />
      <p>Date:</p>
      <p>
        I hereby grant authority to Purina Animal Nutrition, LLC or its parent entity Land O’Lakes
        Inc. to publish my name, publish photographs and video images of myself and my property and
        to utilize any and all material I furnish to them as they see fit. This authority shall be
        valid until revoked by me in writing. Revocation shall not affect any material previously
        prepared and/or utilized by Purina Animal Nutrition, LLC or Land O'Lakes, Inc.
      </p>
      <br />
      <p>Signature:</p>
      <p>Name:</p>
      <p>Address:</p>
      <p>Town, State, Zip:</p>
      <p>Witness:</p>
      <br />
    </div>
  );
};

export default ViewReleaseFormComponent;
