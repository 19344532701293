import React, { useState } from 'react';
import { Modal, Upload, Button, message } from 'antd';
import XLSX from 'xlsx';
import dayjs from 'dayjs';
import { InboxOutlined, CloseOutlined } from '@ant-design/icons';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import {
  DeathLossSheetIndividualGridElement,
  FeedDeliverySheetIndividualGridElement,
  PigsAndBarnPigsInSheetGridElement,
  PigsAndBarnPigsInSheetIndividualGridElement,
  PigsAndBarnPigsSoldSheetGridElement,
  PigsAndBarnPigsSoldSheetIndividualGridElement,
  ProductionExpensesSheetGridElement,
  ProductionExpensesSheetIndividualGridElement,
  FeedDeliverySheetGridElement,
  DeathLossSheetGridElement,
  CalledFromWhichSheetType,
  RowColumnIndexType,
} from '../utils/types';
import {
  renderFeedDeliverySheetRows,
  renderPigsAndBarnPigsInSheetRows,
  renderPigsAndBarnPigsSoldSheetRows,
  renderDeathLossSheetRows,
  renderProductionExpensesSheetRows,
  getSerialNumberOfDate,
  removeCommaFromNumericValues,
  removeEmptyEntriesFromSheetData,
  getHeaderData,
  validationForDateColumn,
} from '../utils/sheetHelpers';
import {
  dateColumnFormat,
  feedTypeOptionsValueLabelData,
  deathReasons,
  chartOfAccounts,
  bulkUploadModalStyle,
} from '../utils/sheetGlobals';
import { Enum_Session_Type_Enum } from '../graphql/graphql-types';

const { Dragger } = Upload;

/* pigs in sheet template file download link */
const pigsInTemplateFileDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/addPigs%26BarnPigsInInput.xlsx';

/* Production expenses  sheet template file download link */
const productionExpensesTemplateFileDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/addProductionExpensesInput.xlsx';

/* pigs sold sheet template file download link */
const pigsSoldTemplateFileDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/addPigs%26BarnPigsSoldInput.xlsx';
// death loss sheet template file download link
const deathLossSheetTemplateFileDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/addDeathLossInput.xlsx';
// feed delivery template file download
const feedDeliveryTemplateFileDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/addFeedDeliveryInput.xlsx';

/* Type for selected file details to upload */
type UploadFileDetailsType = { file: any; fileList: Array<UploadFile> };

/* BulkUploadModalComponent props type */
type BulkUploadModalComponentPropsType = {
  /* Prop to show/hide modal */
  isModalVisible: boolean;
  /* Function to update modal visibility */
  updateIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  /* Variable to store sheet data to be rendered */
  sheetData:
    | PigsAndBarnPigsInSheetGridElement[][]
    | PigsAndBarnPigsSoldSheetGridElement[][]
    | ProductionExpensesSheetGridElement[][]
    | FeedDeliverySheetGridElement[][]
    | DeathLossSheetGridElement[][];
  /* Function to update sheet data */
  updateSheetData:
    | React.Dispatch<React.SetStateAction<PigsAndBarnPigsInSheetGridElement[][]>>
    | React.Dispatch<React.SetStateAction<PigsAndBarnPigsSoldSheetGridElement[][]>>
    | React.Dispatch<React.SetStateAction<ProductionExpensesSheetGridElement[][]>>
    | React.Dispatch<React.SetStateAction<FeedDeliverySheetGridElement[][]>>
    | React.Dispatch<React.SetStateAction<DeathLossSheetGridElement[][]>>;
  /* Prop to check from where bulk upload component is called */
  calledFrom: CalledFromWhichSheetType;
  // pigs and barn pigs in sheet data
  pigsAndBarnPigsInSheetData?: PigsAndBarnPigsInSheetGridElement[][];
  /* session type */
  sessionType?: Enum_Session_Type_Enum | null;
  /* function to update error in pigs and barn pigs in sheet */
  setPigsAndBarnPigsInValidationErrors?: React.Dispatch<React.SetStateAction<RowColumnIndexType[]>>;
  /* function to update error in death loss sheet */
  setDeathLossSheetValidationErrors?: React.Dispatch<React.SetStateAction<RowColumnIndexType[]>>;
  /* function to update error in pigs and barn pigs sold sheet */
  setPigsAndBarnPigsSoldValidationErrors?: React.Dispatch<
    React.SetStateAction<RowColumnIndexType[]>
  >;
  /* function to update error in production expense sheet */
  setProductionExpenseSheetValidationErrors?: React.Dispatch<
    React.SetStateAction<RowColumnIndexType[]>
  >;
  /* function to update error in feed delivery sheet */
  setFeedDeliverySheetValidationErrors?: React.Dispatch<React.SetStateAction<RowColumnIndexType[]>>;
};

/* React functional component */
const BulkUploadModalComponent = ({
  isModalVisible,
  updateIsModalVisible,
  sheetData,
  updateSheetData,
  calledFrom,
  pigsAndBarnPigsInSheetData = undefined,
  sessionType = undefined,
  setPigsAndBarnPigsInValidationErrors = undefined,
  setPigsAndBarnPigsSoldValidationErrors = undefined,
  setDeathLossSheetValidationErrors = undefined,
  setProductionExpenseSheetValidationErrors = undefined,
  setFeedDeliverySheetValidationErrors = undefined,
}: BulkUploadModalComponentPropsType): JSX.Element => {
  /* State to store selected file details to upload */
  const [uploadFileDetails, setUploadFileDetails] = useState<UploadFileDetailsType>({
    file: null,
    fileList: [],
  });

  /* Function to handle file upload using dragger */
  const handleFileUpload = (file: RcFile) => {
    /* File reader instance (read the contents of files stored on device) */
    const fileReader = new FileReader();

    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      /* Variable to store raw data parsed using 'xlsx' file */
      const rawData = event.target?.result;
      /* Variable to store read xlsx workbook data */
      const excelWorkBookData = XLSX.read(rawData, { type: 'array', cellDates: true });

      /* Variable to store converted sheet data to json format */
      const jsonFormattedSheetData = XLSX.utils.sheet_to_json(
        excelWorkBookData.Sheets[excelWorkBookData.SheetNames[0]],
        {
          /* Default value of the cell if data is not present */
          defval: undefined,
          header: getHeaderData(calledFrom),
          raw: false,
        },
      );

      if (calledFrom === 'pigsIn') {
        /* const to calculate number of rows(non empty) in pigs in sheet data */
        const numberOfRowsInPigsInSheet = sheetData
          ? removeEmptyEntriesFromSheetData(sheetData).length
          : 0;

        /* to store pigs in sheet data without header */
        const sheetDataWithoutHeader = (
          jsonFormattedSheetData as PigsAndBarnPigsInSheetIndividualGridElement[]
        )
          .slice(1)
          .map((item, index) => {
            /* Variable to store 'head' column input from sheet */
            const head = item.head ? removeCommaFromNumericValues(item.head) : undefined;
            if (Number(head) <= 0 && setPigsAndBarnPigsInValidationErrors) {
              setPigsAndBarnPigsInValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsInSheet + index, columnIndex: 1 },
              ]);
            }
            if (!Number.isInteger(Number(head)) && setPigsAndBarnPigsInValidationErrors) {
              if (Number(head) > 0) {
                setPigsAndBarnPigsInValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: numberOfRowsInPigsInSheet + index, columnIndex: 1 },
                ]);
              }
            }

            /* Variable to store 'weight' column input from sheet */
            const weight = item.weight ? removeCommaFromNumericValues(item.weight) : undefined;
            if (Number(weight) <= 0 && setPigsAndBarnPigsInValidationErrors) {
              setPigsAndBarnPigsInValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsInSheet + index, columnIndex: 2 },
              ]);
            }

            /* Variable to store 'totalVal' column input from sheet */
            const totalVal = item.totalVal
              ? removeCommaFromNumericValues(item.totalVal)
              : undefined;

            if (Number(totalVal) < 0 && setPigsAndBarnPigsInValidationErrors) {
              setPigsAndBarnPigsInValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsInSheet + index, columnIndex: 4 },
              ]);
            }

            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              head: Number(head) || undefined,
              weight: Number(weight) || undefined,
              // avgWt: Number(item.avgWt),
              avgWt: Number(head) && Number(weight) ? Number(weight) / Number(head) : undefined,
              totalVal: Number(totalVal) || undefined,
              // avg: Number(item.avg),
              avg: Number(head) && Number(totalVal) ? Number(totalVal) / Number(head) : undefined,
            };
          });

        /**
         * Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
         * the sheet
         */
        /* Variable to store sheet data in local variable */
        const grid = sheetData.map((row) => [...row]);

        /* Variable to store sheet data without empty row values */
        const dataWithoutEmptyValues = removeEmptyEntriesFromSheetData(grid);

        const gridData = [
          ...dataWithoutEmptyValues,
          ...sheetDataWithoutHeader.map((item) => [...renderPigsAndBarnPigsInSheetRows(item)]),
        ];

        updateSheetData(gridData);
      } else if (calledFrom === 'pigsSold') {
        /* const to calculate number of rows(non empty) in pigs sold sheet data */
        const numberOfRowsInPigsSoldSheet = sheetData
          ? removeEmptyEntriesFromSheetData(sheetData).length
          : 0;

        /* to store pigs sold sheet data without header */
        const sheetDataWithoutHeader = (
          jsonFormattedSheetData as PigsAndBarnPigsSoldSheetIndividualGridElement[]
        )
          .slice(1)
          .map((item, index) => {
            /* Variable to store 'hdOut' column input from sheet */
            const hdOut = item.hdOut ? removeCommaFromNumericValues(item.hdOut) : undefined;
            if (Number(hdOut) <= 0 && setPigsAndBarnPigsSoldValidationErrors) {
              setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsSoldSheet + index, columnIndex: 2 },
              ]);
            }
            if (!Number.isInteger(Number(hdOut)) && setPigsAndBarnPigsSoldValidationErrors) {
              if (Number(hdOut) > 0) {
                setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: numberOfRowsInPigsSoldSheet + index, columnIndex: 2 },
                ]);
              }
            }

            /* Variable to store 'carcassWt' column input from sheet */
            const carcassWt = item.carcassWt
              ? removeCommaFromNumericValues(item.carcassWt)
              : undefined;

            if (Number(carcassWt) <= 0 && setPigsAndBarnPigsSoldValidationErrors) {
              setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsSoldSheet + index, columnIndex: 3 },
              ]);
            }

            /* Variable to store 'liveWt' column input from sheet */
            const liveWt = item.liveWt ? removeCommaFromNumericValues(item.liveWt) : undefined;

            if (Number(liveWt) <= 0 && setPigsAndBarnPigsSoldValidationErrors) {
              setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsSoldSheet + index, columnIndex: 4 },
              ]);
            }

            /* Variable to store 'totalVal' column input from sheet */
            const totalVal = item.totalVal
              ? removeCommaFromNumericValues(item.totalVal)
              : undefined;

            if (Number(totalVal) < 0 && setPigsAndBarnPigsSoldValidationErrors) {
              setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsSoldSheet + index, columnIndex: 7 },
              ]);
            }

            /* variable to store is cull column from excel sheet */
            const isCull = item.isCull === 'Y' ? 'true' : null;

            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              date: item.date ? dayjs(date).format(dateColumnFormat) : '',
              isCull: isCull,
              hdOut: Number(hdOut) || undefined,
              carcassWt: Number(carcassWt) || undefined,
              liveWt: Number(liveWt) || undefined,
              yield:
                carcassWt && Number(liveWt)
                  ? (Number(carcassWt) * 100) / Number(liveWt)
                  : undefined,
              avgWt: Number(liveWt) && Number(hdOut) ? Number(liveWt) / Number(hdOut) : undefined,
              totalVal: Number(totalVal) || undefined,
              avg: Number(totalVal) && Number(hdOut) ? Number(totalVal) / Number(hdOut) : undefined,
            };
          });

        /**
         * Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
         * the sheet
         */
        /* Variable to store sheet data in local variable */
        const grid = sheetData.map((row) => [...row]);

        /* Variable to store sheet data without empty row values */
        const dataWithoutEmptyValues = removeEmptyEntriesFromSheetData(grid);

        const gridData = [
          ...dataWithoutEmptyValues,
          ...sheetDataWithoutHeader.map((item) => [...renderPigsAndBarnPigsSoldSheetRows(item)]),
        ];

        updateSheetData(gridData);
      } else if (calledFrom === 'deathLoss') {
        /* const to calculate number of rows(non empty) in death loss sheet data */
        const numberOfRowsInDeathLossSheet = sheetData
          ? removeEmptyEntriesFromSheetData(sheetData).length
          : 0;

        // stores the value  from pigsAndBarnPigsInSheetData required for calculation
        let valueCalculatedFromInSheet = 0;
        if (Array.isArray(pigsAndBarnPigsInSheetData) && pigsAndBarnPigsInSheetData.length > 0) {
          // stores the value of sum Product of date and head
          let sumProductOfDateAndHead = 0;
          // stores the value of sum of head
          let sumOfHead = 0;
          pigsAndBarnPigsInSheetData.forEach((item) => {
            if (item[0].value !== null && item[1].value !== null) {
              const dateSeries = getSerialNumberOfDate(item[0].value as string);
              sumProductOfDateAndHead += dateSeries * Number(item[1].value);
              sumOfHead += Number(item[1].value);
            }
            valueCalculatedFromInSheet = sumProductOfDateAndHead / sumOfHead;
          });
        }
        /* to store death loss sheet data */
        const sheetDataWithoutHeader = (
          jsonFormattedSheetData as DeathLossSheetIndividualGridElement[]
        )
          .slice(1)
          .map((item, index) => {
            // finds if reason entered is correct
            const reason = deathReasons.find(
              (val) => val.toLowerCase() === item.reason?.toString().toLowerCase(),
            );

            /* Variable to store 'dead' column input from sheet */
            const dead = item.dead ? removeCommaFromNumericValues(item.dead) : undefined;

            if (Number(dead) <= 0 && setDeathLossSheetValidationErrors) {
              setDeathLossSheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInDeathLossSheet + index, columnIndex: 1 },
              ]);
            }
            if (!Number.isInteger(Number(dead)) && setPigsAndBarnPigsInValidationErrors) {
              if (Number(dead) > 0) {
                setPigsAndBarnPigsInValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: numberOfRowsInDeathLossSheet + index, columnIndex: 1 },
                ]);
              }
            }

            /* Variable to store 'weight' column input from sheet */
            const weight = item.weight ? removeCommaFromNumericValues(item.weight) : undefined;

            if (Number(weight) <= 0 && setDeathLossSheetValidationErrors) {
              setDeathLossSheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInDeathLossSheet + index, columnIndex: 2 },
              ]);
            }

            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              dead: Number(dead) || undefined,
              weight: Number(weight) || undefined,
              reason: reason ? item.reason : undefined,
              deathWeek: (getSerialNumberOfDate(item.date) - valueCalculatedFromInSheet) / 7,
            };
          });

        /**
         * Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
         * the sheet
         */
        /* Variable to store sheet data in local variable */
        const grid = sheetData.map((row) => [...row]);

        /* Variable to store sheet data without empty row values */
        const dataWithoutEmptyValues = removeEmptyEntriesFromSheetData(grid);

        const gridData = [
          ...dataWithoutEmptyValues,
          ...sheetDataWithoutHeader.map((item) => [...renderDeathLossSheetRows(item)]),
        ];

        updateSheetData(gridData);
      } else if (calledFrom === 'productionExpenses') {
        /* const to calculate number of rows(non empty) in production sheet data */
        const numberOfRowsInProductionSheet = sheetData
          ? removeEmptyEntriesFromSheetData(sheetData).length
          : 0;

        /* to store production expense sheet data */
        const sheetDataWithoutHeader = (
          jsonFormattedSheetData as ProductionExpensesSheetIndividualGridElement[]
        )
          .slice(1)
          .map((item, index) => {
            /* Variable to store 'totalCost' column input from sheet */
            const totalCost = item.totalCost
              ? removeCommaFromNumericValues(item.totalCost)
              : undefined;

            if (Number(totalCost) < 0 && setProductionExpenseSheetValidationErrors) {
              setProductionExpenseSheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInProductionSheet + index, columnIndex: 3 },
              ]);
            }

            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              description: item.description || '',
              account: item.account && chartOfAccounts.includes(item.account) ? item.account : '',
              totalCost: Number(totalCost) || undefined,
            };
          });

        /**
         * Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
         * the sheet
         */
        /* Variable to store sheet data in local variable */
        const grid = sheetData.map((row) => [...row]);

        /* Variable to store sheet data without empty row values */
        const dataWithoutEmptyValues = removeEmptyEntriesFromSheetData(grid);

        const gridData = [
          ...dataWithoutEmptyValues,
          ...sheetDataWithoutHeader.map((item) => [...renderProductionExpensesSheetRows(item)]),
        ];

        updateSheetData(gridData);
      } else if (calledFrom === 'feedDelivery') {
        /* const to calculate number of rows(non empty) in feed delivery data */
        const numberOfRowsInFeedDeliverySheet = sheetData
          ? removeEmptyEntriesFromSheetData(sheetData).length
          : 0;

        /* to store feed delivery sheet data without header */
        const sheetDataWithoutHeader = (
          jsonFormattedSheetData as FeedDeliverySheetIndividualGridElement[]
        )
          .slice(1)
          .sort((itemOne, itemTwo) =>
            getSerialNumberOfDate(itemOne.date) > getSerialNumberOfDate(itemTwo.date) ? 1 : -1,
          )
          .map((item, index) => {
            const selectedFeedType = feedTypeOptionsValueLabelData.find(
              (val) => val.value.toLowerCase() === item.feedType?.toString().toLowerCase(),
            );
            /* Variable to store 'poundsDelivered' column input from sheet */
            const poundsDelivered = item.poundsDelivered
              ? removeCommaFromNumericValues(item.poundsDelivered)
              : undefined;

            if (Number(poundsDelivered) <= 0 && setFeedDeliverySheetValidationErrors) {
              setFeedDeliverySheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInFeedDeliverySheet + index, columnIndex: 4 },
              ]);
            }
            /* Variable to store 'actualFeedCost' column input from sheet */
            const actualFeedCost = item.actualFeedCost
              ? removeCommaFromNumericValues(item.actualFeedCost)
              : undefined;

            if (Number(actualFeedCost) < 0 && setFeedDeliverySheetValidationErrors) {
              setFeedDeliverySheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInFeedDeliverySheet + index, columnIndex: 5 },
              ]);
            }

            /* variable to store 'pounds of corn' column input from sheet */
            const poundsOfCorn = item.poundsOfCorn
              ? item.poundsOfCorn.toString().replace(',', '')
              : undefined;

            if (Number(poundsOfCorn) <= 0 && setFeedDeliverySheetValidationErrors) {
              setFeedDeliverySheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInFeedDeliverySheet + index, columnIndex: 6 },
              ]);
            }

            /* variable to store 'cost of corn' column input from sheet */
            const costOfCorn = item.costOfCorn
              ? item.costOfCorn.toString().replace(',', '')
              : undefined;

            if (Number(costOfCorn) < 0 && setFeedDeliverySheetValidationErrors) {
              setFeedDeliverySheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInFeedDeliverySheet + index, columnIndex: 7 },
              ]);
            }
            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              // delivery: item.delivery || undefined,
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              feedType: selectedFeedType ? item.feedType : '',
              poundsDelivered: Number(poundsDelivered) || undefined,
              actualFeedCost: Number(actualFeedCost) || undefined,
              feedDetails: item.feedDetails || '',
              poundsOfCorn: Number(poundsOfCorn) || undefined,
              costOfCorn: Number(costOfCorn) || undefined,
            };
          });

        /**
         * Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
         * the sheet
         */
        /* Variable to store sheet data in local variable */
        const grid = sheetData.map((row) => [...row]);

        /* Variable to store sheet data without empty row values */
        const dataWithoutEmptyValues = removeEmptyEntriesFromSheetData(grid);

        const gridData = [
          ...dataWithoutEmptyValues,
          ...sheetDataWithoutHeader.map((item) => [...renderFeedDeliverySheetRows(item)]),
        ];

        gridData.forEach((item, index) => {
          if (index !== 0 && setFeedDeliverySheetValidationErrors) {
            /* Below logic is added for delivery input validation as entered value in it should be a integer grater than 0 */
            if (item[0].value) {
              if (isNaN(Number(item[0].value))) {
                setFeedDeliverySheetValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: index, columnIndex: 0 },
                ]);
              } else if (Number(item[0].value) <= 0 || !Number.isInteger(Number(item[0].value))) {
                setFeedDeliverySheetValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: index, columnIndex: 0 },
                ]);
              } else {
                setFeedDeliverySheetValidationErrors((prevState) =>
                  prevState.filter((val) => val.rowIndex !== index || val.rowIndex !== 0),
                );
              }

              /* Following logic insures that if user enters 1.0 or 2.0000 then it should be converted to 1 while storing it in sheet data */
              item[0].value =
                !isNaN(Number(item[0].value) % 1) && Number(item[0].value) % 1 === 0
                  ? Math.trunc(Number(item[0].value))
                  : item[0].value;
            } else {
              setFeedDeliverySheetValidationErrors((prevState) =>
                prevState.filter((val) => val.rowIndex !== index || val.rowIndex !== 0),
              );
            }
          }
        });

        updateSheetData(gridData);
      }
      updateIsModalVisible(false);
    };
    fileReader.readAsArrayBuffer(file);
  };

  /* Function to get file download url based on sheet */
  const getFileDownloadUrl = (): string | undefined => {
    if (calledFrom === 'pigsIn') {
      return pigsInTemplateFileDownloadUrl;
    }
    if (calledFrom === 'productionExpenses') {
      return productionExpensesTemplateFileDownloadUrl;
    }
    if (calledFrom === 'pigsSold') {
      return pigsSoldTemplateFileDownloadUrl;
    }
    if (calledFrom === 'deathLoss') {
      return deathLossSheetTemplateFileDownloadUrl;
    }
    if (calledFrom === 'feedDelivery') {
      return feedDeliveryTemplateFileDownloadUrl;
    }
    return undefined;
  };

  return (
    <Modal
      title="Bulk Add Data"
      visible={isModalVisible}
      footer={null}
      onCancel={() => {
        updateIsModalVisible(false);
        setUploadFileDetails({ file: null, fileList: [] });
      }}
      closeIcon={<CloseOutlined style={{ fontSize: 18 }} />}
      centered
      destroyOnClose
      width="50%"
      bodyStyle={bulkUploadModalStyle}
    >
      <div>
        <Button href={getFileDownloadUrl()} className="buttonColorRed" type="primary">
          Download template file
        </Button>
        <div style={{ fontStyle: 'italic', marginTop: 15 }}>
          Supported file types are: <span style={{ fontWeight: 'bold' }}>.xls</span> &{' '}
          <span style={{ fontWeight: 'bold' }}>.xlsx</span>
        </div>
        <Dragger
          maxCount={1}
          className="draggerStyle"
          beforeUpload={(file) => {
            if (
              file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || // for .xlsx file
              file.type === 'application/vnd.ms-excel' // for .xsl file
            ) {
              handleFileUpload(file);
            } else {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error('Only .xls & .xlsx files are allowed');
            }
            return false;
          }}
          onRemove={() => {
            setUploadFileDetails({ file: null, fileList: [] });
            return false;
          }}
          fileList={uploadFileDetails.fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
      </div>
    </Modal>
  );
};

export default BulkUploadModalComponent;
