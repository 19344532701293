import React from 'react';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  chartOfAccountsLabelValueData,
  dateColumnFormat,
  deathLossSheetColumns,
  deathReasonsLabelValueData,
  feedDeliverySheetColumns,
  feedTypeLabelValueData,
  pigsAndBarnPigsInSheetColumns,
  pigsAndBarnPigsSoldSheetColumns,
  productionExpensesSheetColumns,
  seriesStartDate,
} from './sheetGlobals';
import {
  DeathLossSheetGridElement,
  DeathLossSheetIndividualGridElement,
  FeedDeliverySheetGridElement,
  FeedDeliverySheetIndividualGridElement,
  PigsAndBarnPigsInSheetGridElement,
  PigsAndBarnPigsInSheetIndividualGridElement,
  PigsAndBarnPigsSoldSheetGridElement,
  PigsAndBarnPigsSoldSheetIndividualGridElement,
  ProductionExpensesSheetGridElement,
  ProductionExpensesSheetIndividualGridElement,
  CalledFromWhichSheetType,
} from './types';

/* Function to get range from start to end with provided step */
export const range = (start: number, stop: number, step: number): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

/* Function to get year for provided date */
export const getYear = (date: Date): number => date.getFullYear();

/* Function to get month for provided date */
export const getMonth = (date: Date): number => date.getMonth();

/* Variable to store years from 1990 to 2050 */
export const years = range(1990, 2050, 1);

/* Render function for select editor for columns if required */
export const renderSelectDataEditor = (
  cellValue: string | number | null,
  sheetName: 'pigsIn' | 'pigsSold' | 'deathLoss' | 'productionExpenses' | 'feedDelivery',
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined,
): JSX.Element => {
  /* Variable to store select options data */
  let data: Array<{ label: string; value: string }> = [];
  /* Variable to store select options label */
  let selectOptionLabel = '';

  if (sheetName === 'productionExpenses') {
    data = chartOfAccountsLabelValueData;
    selectOptionLabel = 'account';
  } else if (sheetName === 'feedDelivery') {
    data = feedTypeLabelValueData;
    selectOptionLabel = 'feed type';
  } else if (sheetName === 'deathLoss') {
    data = deathReasonsLabelValueData;
    selectOptionLabel = 'reason';
  }

  return (
    <select
      style={{
        border: 'none',
        width: '100%',
        height: '100%',
        paddingLeft: 10,
        cursor: 'cell',
      }}
      onChange={onChange}
      value={cellValue ? cellValue : undefined}
    >
      <option style={{ color: '#cccccc' }} value={0}>
        Select {selectOptionLabel}
      </option>
      {data.map((item, index) => (
        <option key={index.toString()} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
};

/* Function to render pigs and barn pigs in sheet rows */
export const renderPigsAndBarnPigsInSheetRows = (
  data: PigsAndBarnPigsInSheetIndividualGridElement,
  disabledIndexes?: number[],
): PigsAndBarnPigsInSheetGridElement[] => {
  const rowData: PigsAndBarnPigsInSheetGridElement[] = [];
  /* Variable to store sheet disable indexes */
  const sheetDisableIndexes =
    Array.isArray(disabledIndexes) && disabledIndexes.length > 0 ? disabledIndexes : [3, 5];

  for (let i = 0; i < pigsAndBarnPigsInSheetColumns.length; i++) {
    let val = null;
    if (i === 0) {
      val = data.date;
    } else if (i === 1) {
      val = data.head;
    } else if (i === 2 && data.weight) {
      val = data.weight.toFixed(2);
    } else if (i === 3 && data.avgWt) {
      val = data.avgWt.toFixed(2);
    } else if (i === 4 && data.totalVal) {
      val = data.totalVal.toFixed(2);
    } else if (i === 5 && data.avg) {
      val = data.avg.toFixed(2);
    }
    rowData.push({
      readOnly: sheetDisableIndexes.includes(i),
      disableEvents: sheetDisableIndexes.includes(i),
      value: val || null,
    });
  }
  return rowData;
};

/* function to render pigs sold sheet when session type is nursery */
export const renderPigsAndBarnPigsSoldSheetRowsForNurserySession = (
  data: PigsAndBarnPigsSoldSheetIndividualGridElement,
  disabledIndexes?: number[],
): PigsAndBarnPigsSoldSheetGridElement[] => {
  const rowData: PigsAndBarnPigsSoldSheetGridElement[] = [];
  /* Variable to store sheet disable indexes */
  const sheetDisableIndexes =
    Array.isArray(disabledIndexes) && disabledIndexes.length > 0 ? disabledIndexes : [6, 8];

  for (let i = 0; i < pigsAndBarnPigsSoldSheetColumns.length; i++) {
    let val = null;
    if (i === 0) {
      val = data.date;
    } else if (i === 1) {
      val = data.isCull;
    } else if (i === 2) {
      val = data.hdOut;
    } else if (i === 3) {
      val = data.carcassWt;
    } else if (i === 4) {
      val = data.liveWt;
    } else if (i === 5 && data.yield) {
      val = data.yield.toFixed(2);
    } else if (i === 6 && data.avgWt) {
      val = data.avgWt.toFixed(2);
    } else if (i === 7 && data.totalVal) {
      val = data.totalVal.toFixed(2);
    } else if (i === 8 && data.avg) {
      val = data.avg.toFixed(2);
    }
    if (!(i === 3 || i === 5)) {
      rowData.push({
        readOnly: sheetDisableIndexes.includes(i),
        disableEvents: sheetDisableIndexes.includes(i),
        value: val || null,
      });
    }
  }
  return rowData;
};

/* Function to render pigs and barn pigs in sheet rows */
export const renderPigsAndBarnPigsSoldSheetRows = (
  data: PigsAndBarnPigsSoldSheetIndividualGridElement,
  disabledIndexes?: number[],
): PigsAndBarnPigsSoldSheetGridElement[] => {
  const rowData: PigsAndBarnPigsSoldSheetGridElement[] = [];
  /* Variable to store sheet disable indexes */
  const sheetDisableIndexes =
    Array.isArray(disabledIndexes) && disabledIndexes.length > 0 ? disabledIndexes : [5, 6, 8];

  for (let i = 0; i < pigsAndBarnPigsSoldSheetColumns.length; i++) {
    let val = null;
    if (i === 0) {
      val = data.date;
    } else if (i === 1) {
      val = data.isCull;
    } else if (i === 2) {
      val = data.hdOut;
    } else if (i === 3) {
      val = data.carcassWt;
    } else if (i === 4 && data.liveWt) {
      val = data.liveWt.toFixed(2);
    } else if (i === 5 && data.yield) {
      val = data.yield.toFixed(2);
    } else if (i === 6 && data.avgWt) {
      val = data.avgWt.toFixed(2);
    } else if (i === 7 && data.totalVal) {
      val = data.totalVal.toFixed(2);
    } else if (i === 8 && data.avg) {
      val = data.avg.toFixed(2);
    }
    rowData.push({
      readOnly: sheetDisableIndexes.includes(i),
      disableEvents: sheetDisableIndexes.includes(i),
      value: val || null,
    });
  }
  return rowData;
};

/* Function to render production expenses in sheet rows */
export const renderProductionExpensesSheetRows = (
  data: ProductionExpensesSheetIndividualGridElement,
): ProductionExpensesSheetGridElement[] => {
  const rowData: ProductionExpensesSheetGridElement[] = [];

  for (let i = 0; i < productionExpensesSheetColumns.length; i++) {
    let val = null;
    if (i === 0) {
      val = data.date;
    } else if (i === 1) {
      val = data.description;
    } else if (i === 2) {
      val = data.account;
    } else if (i === 3 && data.totalCost) {
      val = data.totalCost.toFixed(2);
    }
    rowData.push({
      readOnly: false,
      disableEvents: false,
      value: val || null,
    });
  }
  return rowData;
};

// renders death loss sheet rows
export const renderDeathLossSheetRows = (
  data: DeathLossSheetIndividualGridElement,
): DeathLossSheetGridElement[] => {
  const rowData: DeathLossSheetGridElement[] = [];
  for (let i = 0; i < deathLossSheetColumns.length; i++) {
    let val = null;
    if (i === 0) {
      val = data.date;
    } else if (i === 1) {
      val = data.dead;
    } else if (i === 2) {
      val = data.weight;
    } else if (i === 3) {
      val = data.reason;
    } else if (i === 4 && data.deathWeek) {
      val = data.deathWeek;
    }
    rowData.push({
      readOnly: i === 4,
      disableEvents: i === 4,
      value: val || null,
    });
  }
  return rowData;
};

/* Function to get serial number of date so that it can be used further for mathematical operations */
export const getSerialNumberOfDate = (date: string): number => {
  return dayjs(dayjs(date).format('MM-DD-YYYY')).diff(seriesStartDate, 'd') + 1;
};

/* Function to validate date field value entered by user in excel sheet */
export const validationForDateColumn = (value: string): boolean => {
  return (
    dayjs(value, dateColumnFormat).format(dateColumnFormat) === value ||
    dayjs(value, 'MM-DD-YYYY').format('MM-DD-YYYY') === value ||
    dayjs(value, 'M-DD-YYYY').format('M-DD-YYYY') === value ||
    dayjs(value, 'MM-D-YYYY').format('MM-D-YYYY') === value ||
    dayjs(value, 'M-D-YYYY').format('M-D-YYYY') === value ||
    dayjs(value, 'M-DD-YY').format('M-DD-YY') === value ||
    dayjs(value, 'MM-D-YY').format('MM-D-YY') === value ||
    dayjs(value, 'M-D-YY').format('M-D-YY') === value ||
    dayjs(value, 'MM/DD/YYYY').format('MM/DD/YYYY') === value ||
    dayjs(value, 'M/DD/YYYY').format('M/DD/YYYY') === value ||
    dayjs(value, 'MM/D/YYYY').format('MM/D/YYYY') === value ||
    dayjs(value, 'M/D/YYYY').format('M/D/YYYY') === value ||
    dayjs(value, 'M/DD/YY').format('M/DD/YY') === value ||
    dayjs(value, 'MM/D/YY').format('MM/D/YY') === value ||
    dayjs(value, 'M/D/YY').format('M/D/YY') === value
  );
};

/* Renders feed delivery loss sheet rows */
export const renderFeedDeliverySheetRows = (
  data: FeedDeliverySheetIndividualGridElement,
): FeedDeliverySheetGridElement[] => {
  const rowData: FeedDeliverySheetGridElement[] = [];
  for (let i = 0; i < feedDeliverySheetColumns.length; i++) {
    let val = null;
    // if (i === 0) {
    //   val = data.delivery;
    // } else
    if (i === 0) {
      val = data.date;
    } else if (i === 1) {
      val = data.feedType;
    } else if (i === 2) {
      val = data.feedDetails;
    } else if (i === 3 && data.poundsDelivered) {
      val = data.poundsDelivered;
    } else if (i === 4 && data.actualFeedCost) {
      val = data.actualFeedCost;
    } else if (i === 5 && data.poundsOfCorn) {
      val = data.poundsOfCorn;
    } else if (i === 6 && data.costOfCorn) {
      val = data.costOfCorn;
    }
    rowData.push({
      value: val || null,
    });
  }
  return rowData;
};

/* Function to render info tooltip */
export const renderInfoTooltip = (content: JSX.Element): JSX.Element => {
  return (
    <Tooltip color="white" title={content} overlayInnerStyle={{ borderRadius: 5 }}>
      <InfoCircleTwoTone />
    </Tooltip>
  );
};

/* function to remove comma from numeric value */
export const removeCommaFromNumericValues = (removeCommaFromNumericValue: number): string => {
  return removeCommaFromNumericValue.toString().replace(',', '');
};

/* Function to remove empty entries from sheet data */
export const removeEmptyEntriesFromSheetData = (
  data: (
    | PigsAndBarnPigsInSheetGridElement
    | PigsAndBarnPigsSoldSheetGridElement
    | ProductionExpensesSheetGridElement
    | FeedDeliverySheetGridElement
    | DeathLossSheetGridElement
  )[][],
  initialIndex = 0,
): (
  | PigsAndBarnPigsInSheetGridElement
  | PigsAndBarnPigsSoldSheetGridElement
  | ProductionExpensesSheetGridElement
  | FeedDeliverySheetGridElement
  | DeathLossSheetGridElement
)[][] => {
  return data.filter((item, index) => {
    let count = 0;
    if (index !== 0) {
      for (let arrIndex = initialIndex; arrIndex < item.length; arrIndex++) {
        if (item[arrIndex].value) {
          count = 1;
          break;
        }
      }
    }
    return count === 1 || index === 0;
  });
};

/* to calculate Hd out */
export const calculateHdOut = (data: PigsAndBarnPigsInSheetGridElement[][]): number => {
  let result = 0;
  data.forEach((item) => {
    if (item[1].value) {
      result += Number(item[1].value);
    }
  });
  return result;
};

/* to calculate Days */
export const calculateDays = (data: PigsAndBarnPigsInSheetGridElement[][] | undefined): string => {
  let result = 0;
  if (data) {
    data.forEach((item) => {
      if (item[0].value && item[1].value) {
        const serialNumberForDate = getSerialNumberOfDate(item[0].value as string);
        result += serialNumberForDate * Number(item[1].value);
      }
    });

    if (calculateHdOut(data) && result) {
      result = result / calculateHdOut(data);
    }
  }
  return result
    ? dayjs(seriesStartDate)
        .add(Math.floor(result) - 1, 'day')
        .format(dateColumnFormat)
    : '';
};

/* function to get header data */
export const getHeaderData = (calledFrom: CalledFromWhichSheetType): string[] => {
  if (calledFrom === 'pigsIn') {
    return ['date', 'head', 'weight', 'avgWt', 'totalVal', 'avg'];
  }
  if (calledFrom === 'pigsSold') {
    return ['date', 'isCull', 'hdOut', 'carcassWt', 'liveWt', 'avgWt', 'totalVal', 'avg'];
  }
  if (calledFrom === 'deathLoss') {
    return ['date', 'dead', 'weight', 'reason', 'deathWeek'];
  }
  if (calledFrom === 'productionExpenses') {
    return ['date', 'description', 'account', 'totalCost'];
  }
  return [
    // 'delivery',
    'date',
    'feedType',
    'feedDetails',
    'poundsDelivered',
    'actualFeedCost',
    'poundsOfCorn',
    'costOfCorn',
  ];
};

/* days in pigs sold sheet is calculated as ( Date-01/01/2000 )* Total Hd out according to the new update */
export const serialNumberOfDateForCalculationOfDays = getSerialNumberOfDate('01/01/2000');

/* function to calculate first filled date from pigs in sheet data i.e the first time(date) pigs entered */
export const calculateFirstFillDate = (
  data: (PigsAndBarnPigsInSheetGridElement | DeathLossSheetGridElement)[][],
): string => {
  /* variable to store result(first filled date) */
  let result = 0;
  /* array used to store dates from the sheet.First we filter the rows where date and head in values are present and after that we sort the array depending upon first filled to last filled dates */
  const arrayOfDates = data
    .filter((obj) => obj[0].value && obj[1].value)
    .map((item) => item[0].value)
    .sort((itemOne, itemTwo) =>
      getSerialNumberOfDate(itemOne as string) > getSerialNumberOfDate(itemTwo as string) ? 1 : -1,
    );

  if (Array.isArray(arrayOfDates) && arrayOfDates.length > 0) {
    result = Number(getSerialNumberOfDate(arrayOfDates[0] as string));
  }

  return result
    ? dayjs(seriesStartDate)
        .add(Math.floor(result) - 1, 'day')
        .format(dateColumnFormat)
    : '';
};

/* function to calculate the recent(latest) date entered by user */
export const calculateMaxOutputDate = (data: PigsAndBarnPigsSoldSheetGridElement[][]): string => {
  /* variable to store the latest date */
  let result = 0;
  data.forEach((item) => {
    if (item[0].value && item[2].value) {
      /* Variable to store serial number for date selected by user in data sheet */
      const serialNumberForDate = getSerialNumberOfDate(item[0].value as string);
      if (serialNumberForDate > result) {
        result = serialNumberForDate;
      }
    }
  });
  return result
    ? dayjs(seriesStartDate)
        .add(Math.floor(result) - 1, 'day')
        .format(dateColumnFormat)
    : '';
};
