import React from 'react';
import { Button, Modal, ModalProps } from 'antd';

/* AlertModalComponent props type */
type AlertModalComponentPropsType = {
  /* Prop to decide whether to show/hide modal */
  isModalVisible: boolean;
  /* Function to handle cancel btn of modal */
  handleCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /* Prop to decide whether to show/hide loader on ok btn of the modal */
  okBtnLoading?: boolean;
  /* Function to handle ok btn of modal */
  handleOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /* Message to show inside modal */
  message: string;
  /* Title of the modal */
  title?: string;
  /* Prop to show cancel btn label of modal */
  cancelBtnLabel?: string;
  /* Prop to ok cancel btn label of modal */
  okBtnLabel?: string;
  /* Function to be called on close btn clicked */
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /* Other modal props */
  modalProps?: Omit<ModalProps, 'visible' | 'title'>;
};

/* React functional component */
const AlertModalComponent = ({
  isModalVisible,
  handleCancel,
  okBtnLoading = false,
  handleOk,
  message,
  title = 'Alert',
  cancelBtnLabel = 'Cancel',
  okBtnLabel = 'Yes',
  onClose = undefined,
  modalProps = {},
}: AlertModalComponentPropsType): JSX.Element => {
  const { footer, ...restProps } = modalProps;
  return (
    <Modal
      centered
      title={title}
      footer={
        footer
          ? footer
          : [
              <Button key="1" onClick={handleCancel}>
                {cancelBtnLabel}
              </Button>,
              <Button
                className="buttonColorRed"
                loading={okBtnLoading}
                disabled={okBtnLoading}
                key="2"
                type="primary"
                onClick={handleOk}
              >
                {okBtnLabel}
              </Button>,
            ]
      }
      visible={isModalVisible}
      bodyStyle={{ borderRadius: 5 }}
      onCancel={onClose || handleCancel}
      {...restProps}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default AlertModalComponent;
