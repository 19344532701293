import React, { useState } from 'react';
import { Button } from 'antd';
import { MailOutlined, LockFilled } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import FormItem from '../components/FormItem';
import Input from '../components/Input';
import logo from '../assets/images/abacus_logo.svg';
import { firebaseAuth } from '../utils/firebase';
import { logger } from '../utils/helpers';
import styles from './LoginScreen.module.scss';

/* Login form fields type */
type LoginFormFieldsType = {
  /* Email */
  email: string;
  /* Password */
  password: string;
};

/* React functional component */
const LoginScreen = (): JSX.Element => {
  /* State to decide whether to show loader on sign in button or not */
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  /* State to store reset password message after successfully sending a mail to user */
  const [resetPasswordMsg, setResetPasswordMsg] = useState<string>('');
  /* State to decide whether to show loader on reset password button or not */
  const [resetPasswordBtnLoading, setResetPasswordBtnLoading] = useState<boolean>(false);

  /* useForm destructuring */
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    reset,
  } = useForm<LoginFormFieldsType>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  /* form submit function */
  const onSubmit = (data: LoginFormFieldsType) => {
    setSignInLoading(true);

    /* Sign in user with email and password */
    signInWithEmailAndPassword(firebaseAuth, data.email, data.password)
      .then(() => {
        setSignInLoading(false);
      })
      .catch((error: FirebaseError) => {
        logger(error);
        if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
          setError('email', {
            type: 'manual',
            message: 'The entered Email is incorrect',
          });
        }
        if (error.code === 'auth/wrong-password') {
          setError('password', {
            type: 'manual',
            message: 'The entered Password is incorrect',
          });
        }
        setSignInLoading(false);
      });
  };

  /* Function to handle reset password */
  const handleReset = () => {
    setResetPasswordBtnLoading(true);
    setResetPasswordMsg('');

    /* Entered email by user */
    const email = watch('email');

    /* Send password reset email to user */
    sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {
        setResetPasswordMsg(
          'An email has been sent to your Email ID. Please follow the instructions in it to reset your password.',
        );
        reset();
        setResetPasswordBtnLoading(false);
      })
      .catch((error: FirebaseError) => {
        logger(error);
        if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
          setError('email', {
            type: 'manual',
            message: 'The entered Email is incorrect',
          });
        }
        setResetPasswordBtnLoading(false);
      });
  };

  return (
    <div className="fullScreen">
      <div className={styles.formContainer}>
        <h1 className={styles.formHeading}>Purina Swine Close-Out Tool</h1>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
          <img src={logo} alt="purinaEquine" height="40" width="40" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem
            customStyle={{ marginBottom: 20 }}
            errorText={errors.email && errors.email.message ? errors.email.message : undefined}
          >
            <Input
              customStyles={{ borderRadius: 5, borderColor: '#999999', borderWidth: 2 }}
              placeholder="Please enter email ID"
              name="email"
              rhfControllerProps={{ control }}
              inputProps={{
                prefix: <MailOutlined style={{ color: '#999999', marginRight: 10 }} />,
              }}
            />
          </FormItem>
          <FormItem
            errorText={
              errors.password && errors.password.message ? errors.password.message : undefined
            }
          >
            <Input
              customStyles={{ borderRadius: 5, borderColor: '#999999', borderWidth: 2 }}
              placeholder="Please enter password"
              isPasswordInput
              name="password"
              rhfControllerProps={{ control }}
              inputProps={{
                prefix: <LockFilled style={{ color: '#999999', marginRight: 10 }} />,
                type: 'password',
              }}
            />
          </FormItem>

          {resetPasswordMsg !== '' ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
              <div style={{ width: 350, textAlign: 'center', color: '#5784e5' }}>
                {resetPasswordMsg}
              </div>
            </div>
          ) : null}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className={
                watch('email') === '' || watch('password') === ''
                  ? styles.buttonColorDisabled
                  : 'buttonColorRed'
              }
              htmlType="submit"
              style={{
                width: 150,
                height: 40,
                fontSize: 18,
                marginTop: 40,
              }}
              disabled={watch('email') === '' || watch('password') === ''}
              loading={signInLoading}
            >
              Sign In
            </Button>
          </div>
        </form>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button
            type="link"
            style={{ fontSize: 17, color: watch('email') === '' ? '#dddddd' : '#5784e5' }}
            disabled={watch('email') === ''}
            onClick={handleReset}
            loading={resetPasswordBtnLoading}
          >
            Reset Password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
