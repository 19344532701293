import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';

/* pie chart data type */
type CharDataType = {
  charData: {
    name: string;
    value: number | string | undefined;
  }[];
};

/* to calculate the label position depending upon radius and x,y coordinates */
const PieChartComponent = ({ charData }: CharDataType): JSX.Element => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props: any) => {
    /* Props destructuring */
    const { cx, cy, outerRadius, midAngle, value } = props as {
      cx: number;
      cy: number;
      name: string;
      value: number;
      width: number;
      innerRadius: number;
      outerRadius: number;
      midAngle: number;
      percent: number;
    };

    /* radius- which will be used in the calculation of x-coordinate and y-coordinate for labels */
    const radius = outerRadius * 1.4;

    /* to calculate the x-coordinate */
    const x = cx + radius * Math.cos(-midAngle * RADIAN);

    /* to calculate the y-coordinate */
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor="middle"
        style={{ fontSize: 13, color: '#333333', fontWeight: 'bold' }}
      >
        {value ? `${value.toFixed(2)}%` : null}
      </text>
    );
  };

  /* colors to display */
  const COLORS = ['#fae7ea', '#ce0e2d', '#eb9fab'];

  return (
    <ResponsiveContainer width={400} height={450}>
      <PieChart width={300} height={300} style={{ marginTop: 10 }}>
        <Pie
          data={charData}
          cx="50%"
          cy="27%"
          outerRadius={80}
          fill="#00C49F"
          dataKey="value"
          label={renderCustomizedLabel}
        >
          {charData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
