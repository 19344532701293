import React, { MouseEventHandler } from 'react';
import { Button } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './sheets/Sheets.module.scss';

/* SheetActionButtonsComponent props type */
type SheetActionButtonsComponentProps = {
  /* bulk upload props */
  bulkUploadProps: {
    /* onClick of common upload button set common bulk modal to true i.e visible */
    commonBulkUpload: React.Dispatch<React.SetStateAction<boolean>>;
    /* onClick of bulk upload button set bulk upload modal to true i.e visible */
    bulkUpload: React.Dispatch<React.SetStateAction<boolean>>;
  };
  /* to add new row on click of new row button */
  addNewRowFunc: MouseEventHandler<HTMLElement> | undefined;
};

/* React functional component */
const SheetActionButtonsComponent = ({
  bulkUploadProps,
  addNewRowFunc,
}: SheetActionButtonsComponentProps): JSX.Element => {
  return (
    <>
      {/* new row button */}
      <Button
        className={styles.newRowBtn}
        icon={<PlusOutlined style={{ fontWeight: 'bold' }} color="#2B78E4" />}
        onClick={addNewRowFunc}
      >
        New row
      </Button>

      {/* common bulk upload button */}
      <Button
        className={styles.commonBulkUploadBtn}
        icon={<UploadOutlined style={{ fontWeight: 'bold' }} color="#cf2a27" />}
        onClick={() => {
          bulkUploadProps.commonBulkUpload(true);
        }}
      >
        ALL OR CURRENT PAGE DATA UPLOAD
      </Button>

      {/* in order to hide the bulk upload button this code is being commented out. Depending upon the client requirement further action will be taken */}
      {/* <Button
        className={styles.bulkUploadBtn}
        icon={<UploadOutlined style={{ fontWeight: 'bold' }} color="#2B78E4" />}
        onClick={() => {
          bulkUploadProps.bulkUpload(true);
        }}
      >
        Bulk Upload
      </Button> */}
    </>
  );
};

export default SheetActionButtonsComponent;
