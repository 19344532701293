import React from 'react';
import { Modal, Button, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormItem from './FormItem';
import Select from './Select';
import styles from './SessionNameModalComponent.module.scss';

/* Nursery session name modal component props type */
type NurserySessionNameModalComponentType = {
  /* Prop to decide whether to show/hide modal */
  isModalVisible: boolean;
  /* Function to handle yes btn of modal */
  handleOk: (sessionId: string) => void;
  /* Function that will be called after user clicks on cancel btn */
  handleCancel: (sessionId: string) => void;
  /* dropdown options for select */
  dropDownOptions: Array<{ label: string; value: string }>;
  /* message to display */
  message: string;
  /* loading condition of button */
  okBtnLoading: boolean;
  /* to show loading state when the data is not yet fetched */
  nurseryDataLoading: boolean;
  /* Function to be called on close btn clicked */
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /* Decide to show/hide no btn */
  showNoBtn?: boolean;
};

/* nursery session name form type */
type NurserySessionNameFormType = {
  /* nursery session id */
  nurserySessionId: string;
};

/* Validation schema */
const schema = yup.object().shape({
  nurserySessionId: yup.string().required('Please select nursery session name and try again'),
});

/* React functional component */
const NurseryNameModalComponent = ({
  isModalVisible,
  handleOk,
  handleCancel,
  dropDownOptions,
  okBtnLoading = false,
  message,
  nurseryDataLoading,
  onClose = undefined,
  showNoBtn = false,
}: NurserySessionNameModalComponentType): JSX.Element => {
  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<NurserySessionNameFormType>({
    defaultValues: {
      nurserySessionId: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <Modal
      visible={isModalVisible}
      width={580}
      destroyOnClose
      centered
      title="Alert"
      footer={false}
      onCancel={onClose}
    >
      <p>{message}</p>
      {!nurseryDataLoading ? (
        <>
          <FormItem
            errorText={errors.nurserySessionId ? errors.nurserySessionId.message : undefined}
            isRequired
            label="Select Nursery session for Grow-Finish inputs"
            labelColSpan={9}
            inputColSpan={14}
            errorTextStyle={{ textAlign: 'left' }}
            showRequiredMark="after"
            labelStyle={{ textAlign: 'left' }}
          >
            <Select
              name="nurserySessionId"
              rhfControllerProps={{
                control,
              }}
              customStyles={{ width: 250 }}
              options={dropDownOptions}
            />
          </FormItem>

          <div className={styles.btnContainer}>
            {showNoBtn && onClose ? (
              <Button onClick={onClose} className={styles.cancelBtn}>
                No
              </Button>
            ) : null}
            <Button
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleSubmit((data) => {
                  handleCancel(data.nurserySessionId);
                })();
              }}
              className={styles.cancelBtn}
              style={{ marginLeft: 20 }}
            >
              Continue without saving
            </Button>
            <Button
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleSubmit((data) => {
                  handleOk(data.nurserySessionId);
                })();
              }}
              loading={okBtnLoading}
              className="buttonColorRed"
              style={{ marginLeft: 20 }}
            >
              Save & Continue
            </Button>
          </div>
        </>
      ) : (
        <div className={styles.loader}>
          <Spin size="small" />
        </div>
      )}
    </Modal>
  );
};

export default NurseryNameModalComponent;
