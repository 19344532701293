import React, { BaseSyntheticEvent, useState } from 'react';
import { Button, message, Divider } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { firebaseAuth } from '../utils/firebase';
import FormItem from '../components/FormItem';
import Input from '../components/Input';
import { logger } from '../utils/helpers';
import styles from './SettingsScreen.module.scss';

/* Modify current password form type */
type ModifyPasswordFormType = {
  /* Current user password */
  currentPassword: string;
  /* New password */
  newPassword: string;
  /* Confirm new password */
  confirmNewPassword: string;
};

/* Validation schema */
const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Please enter current password and try again'),
  newPassword: yup
    .string()
    .required('Please enter new password and try again')
    .min(6, 'Password must be atleast 6 letters long'),
  confirmNewPassword: yup
    .string()
    .required('Please confirm new password and try again')
    .min(6, 'Password must be atleast 6 letters long')
    .oneOf(
      [yup.ref('newPassword'), null],
      'The entered passwords do not match. Please check and try again.',
    ),
});

/* React functional component */
const SettingsScreen = (): JSX.Element => {
  /* useNavigate() for navigation */
  const navigate = useNavigate();
  /* State to decide whether to show/hide confirm new password field content */
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(true);
  /* State to decide whether to show/hide loading indicator on save btn */
  const [showBtnLoader, setShowBtnLoader] = useState<boolean>(false);

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
  } = useForm<ModifyPasswordFormType>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(validationSchema),
  });

  /* Function to submit form data */
  const onSubmit = (data: ModifyPasswordFormType, event: BaseSyntheticEvent | undefined) => {
    if (event) {
      event.preventDefault();
    }
    setShowBtnLoader(true);

    const { currentUser } = firebaseAuth;
    if (currentUser && currentUser.email) {
      /* firebase credentials */
      const credentials = EmailAuthProvider.credential(currentUser.email, data.currentPassword);

      reauthenticateWithCredential(currentUser, credentials)
        .then((): void => {
          updatePassword(currentUser, data.newPassword)
            .then((): void => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success('Password has been successfully updated');
              setShowBtnLoader(false);
              reset();
              navigate('/data');
            })
            .catch((updatePasswordErr): void => {
              logger(updatePasswordErr);
              reset();
              setShowBtnLoader(false);
            });
        })
        .catch((error) => {
          setShowBtnLoader(false);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (error.code === 'auth/wrong-password') {
            setError('currentPassword', {
              type: 'manual',
              message: 'The Current Password you have entered is incorrect',
            });
          }
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <h3 style={{ margin: 0 }}>Modify Password</h3>
        <Divider
          style={{ borderTop: '1px solid #000000', margin: 0, marginTop: 10, marginBottom: 10 }}
        />
      </div>

      <FormItem
        label="Current Password:"
        isRequired
        labelColSpan={3}
        inputColSpan={21}
        errorText={
          errors.currentPassword && errors.currentPassword.message
            ? errors.currentPassword.message
            : undefined
        }
        errorTextStyle={{ textAlign: 'start' }}
      >
        <Input
          placeholder="Please enter current password"
          isPasswordInput
          name="currentPassword"
          rhfControllerProps={{ control }}
        />
      </FormItem>

      <FormItem
        label="New Password:"
        isRequired
        labelColSpan={3}
        inputColSpan={21}
        errorText={
          errors.newPassword && errors.newPassword.message ? errors.newPassword.message : undefined
        }
        errorTextStyle={{ textAlign: 'start' }}
      >
        <Input
          placeholder="Please enter new password"
          isPasswordInput
          name="newPassword"
          rhfControllerProps={{ control }}
        />
      </FormItem>

      <FormItem
        label="Confirm New Password:"
        isRequired
        labelColSpan={3}
        inputColSpan={21}
        errorText={
          errors.confirmNewPassword && errors.confirmNewPassword.message
            ? errors.confirmNewPassword.message
            : undefined
        }
        errorTextStyle={{ textAlign: 'start' }}
      >
        <Input
          placeholder="Please confirm new password"
          name="confirmNewPassword"
          rhfControllerProps={{ control }}
          inputProps={{
            type: showConfirmNewPassword ? 'text' : 'password',
            suffix: (
              <Button
                type="link"
                style={{ padding: 0, margin: 0, height: 'inherit' }}
                onClick={(): void => {
                  setShowConfirmNewPassword(!showConfirmNewPassword);
                }}
              >
                {showConfirmNewPassword ? (
                  <EyeOutlined className={styles.confirmPassSuffix} />
                ) : (
                  <EyeInvisibleOutlined className={styles.confirmPassSuffix} />
                )}
              </Button>
            ),
          }}
        />
      </FormItem>

      <Button
        className={`buttonColorRed ${styles.saveBtn}`}
        loading={showBtnLoader}
        htmlType="submit"
      >
        Save
      </Button>
      <Button
        type="default"
        htmlType="reset"
        onClick={(): void => {
          reset();
        }}
      >
        Cancel
      </Button>
    </form>
  );
};

export default SettingsScreen;
