import React from 'react';
import { Descriptions, Table, Col } from 'antd';
import dayjs from 'dayjs';
import PieChartComponent from './charts/PieChartComponent';
import { dateColumnFormat, seriesStartDate } from '../utils/sheetGlobals';
import {
  PigsAndBarnPigsInSheetGridElement,
  PigsAndBarnPigsSoldSheetGridElement,
} from '../utils/types';
import {
  getSerialNumberOfDate,
  serialNumberOfDateForCalculationOfDays,
  calculateFirstFillDate,
  calculateMaxOutputDate,
} from '../utils/sheetHelpers';
import { CloseOutSummaryType } from '../utils/types';
import styles from './CloseOutSummaryComponent.module.scss';
import { Enum_Session_Type_Enum } from '../graphql/graphql-types';

/* close out summary props type */
type CloseOutSummaryComponentType = Omit<CloseOutSummaryType, 'makeCloseOutSummaryPageHidden'> & {
  /* to check from were the close out summary component is called and accordingly give styles to the page */
  calledFrom: 'closeOutSummaryPrintPreview' | 'closeOutSummaryPage';
};

/* close out summary tables type */
type CloseOutSummaryTablesType = {
  /* key for name */
  name: string;
  /* key for calculated value */
  calculatedValue: string | number | undefined;
};

const CloseOutSummaryComponent = ({
  pigsAndBarnPigsInSheetData,
  pigsAndBarnPigsSoldSheetData,
  productionSheetData,
  inputData,
  feedDeliverySheetData,
  calledFrom,
  deathLossSheetData,
}: CloseOutSummaryComponentType): JSX.Element => {
  /* Function to do calculation based on head in or head out data (Pigs In and Pigs sold sheet) */
  const calculateHeadInOrHeadOut = (
    data: PigsAndBarnPigsInSheetGridElement[][] | PigsAndBarnPigsSoldSheetGridElement[][],
    columnIndex: number,
  ): number => {
    let result = 0;
    data.forEach((item) => {
      if (item[columnIndex].value) {
        result += Number(item[columnIndex].value);
      }
    });
    return result;
  };

  /* function to do calculation based on data : 'Date' column (Pigs In and Pigs sold sheet) */
  const calculateDate = (
    data: PigsAndBarnPigsInSheetGridElement[][] | PigsAndBarnPigsSoldSheetGridElement[][],
    columnIndex: number,
  ): string => {
    let result = 0;
    data.forEach((item) => {
      if (item[0].value && item[columnIndex].value) {
        const serialNumberForDate = getSerialNumberOfDate(item[0].value as string);
        result += serialNumberForDate * Number(item[columnIndex].value);
      }
    });
    if (calculateHeadInOrHeadOut(data, columnIndex) && result) {
      result = result / calculateHeadInOrHeadOut(data, columnIndex);
    }
    return result
      ? dayjs(seriesStartDate)
          .add(Math.floor(result) - 1, 'day')
          .format(dateColumnFormat)
      : '';
  };

  /* function to calculate the sum of weights from pigs in sheet */
  const calculateWeightFromPigsInData = () => {
    let result = 0;
    pigsAndBarnPigsInSheetData.forEach((item) => {
      if (item[2].value) {
        result += Number(item[2].value);
      }
    });
    return result;
  };

  /* function to calculate sum of  avg weight from pigs in sheet */
  const calculateAvgWeightPigsInData = () => {
    let result;
    if (calculateHeadInOrHeadOut(pigsAndBarnPigsInSheetData, 1)) {
      result =
        calculateWeightFromPigsInData() / calculateHeadInOrHeadOut(pigsAndBarnPigsInSheetData, 1);
    }
    return result;
  };

  /* function to calculate sum of live weight from pigs sold sheet */
  const calculateLiveWeightOfPigsSold = () => {
    let result = 0;
    /* live weight column index */
    const liveWeightIndex = inputData.sessionType !== Enum_Session_Type_Enum.Nursery ? 4 : 3;

    pigsAndBarnPigsSoldSheetData.forEach((item) => {
      if (item[liveWeightIndex].value) {
        result += Number(item[liveWeightIndex].value);
      }
    });
    return result;
  };

  /* function to calculate avg weight out */
  const calculateAvgWeightOut = () => {
    let result;
    if (calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2)) {
      result =
        calculateLiveWeightOfPigsSold() / calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2);
    }
    return result;
  };

  /* function to calculate sum of total value from pigs sold sheet */
  const calculateSumOfTotalValuePigsSold = () => {
    let result = 0;
    /* totalValue column index */
    const totalValueColumnIndex = inputData.sessionType === Enum_Session_Type_Enum.Nursery ? 5 : 7;

    pigsAndBarnPigsSoldSheetData.forEach((item) => {
      if (item[totalValueColumnIndex].value) {
        result += Number(item[totalValueColumnIndex].value);
      }
    });
    return result;
  };

  /* function to calculate sum of total value from pigs in sheet */
  const calculateSumOfTotalValuePigsIn = () => {
    let result = 0;
    pigsAndBarnPigsInSheetData.forEach((item) => {
      if (item[4].value) {
        result += Number(item[4].value);
      }
    });
    return result;
  };

  /* function to calculate sum of total cost from production expense sheet */
  const calculateTotalCostFromProductionExpense = () => {
    let result = 0;
    productionSheetData.forEach((item) => {
      if (item[3].value) {
        result += Number(item[3].value);
      }
    });
    return result;
  };

  /* function to calculate sum of pounds delivered in feed Delivery sheet */
  const calculateSumOfPoundsDelivered = () => {
    let result = 0;
    feedDeliverySheetData.forEach((item) => {
      if (item[3].value) {
        result += Number(item[3].value);
      }
    });
    return result;
  };

  /* function to calculate sum of actual feed cost in feed Delivery sheet */
  const calculateSumOfActualFeedCost = () => {
    let result = 0;
    feedDeliverySheetData.forEach((item) => {
      if (item[4].value) {
        result += Number(item[4].value);
      }
    });
    return result;
  };

  /* function to do calculation based on 'pounds of corn' column in feed Delivery sheet */
  const calculateTotalPoundsOfCorn = () => {
    let result = 0;
    feedDeliverySheetData.forEach((item) => {
      if (item[5].value) {
        result += Number(item[5].value);
      }
    });
    return result;
  };

  /* to calculate pigs culled head out */
  const calculatePigsCulledHeadOut = () => {
    let result = 0;
    pigsAndBarnPigsSoldSheetData.forEach((item) => {
      if (item[1].value && item[2].value) {
        result += Number(item[2].value);
      }
    });
    return result;
  };

  /* function to do calculation based on 'cost of corn' column in feedDelivery sheet */
  const calculateTotalCostOfCorn = () => {
    let result = 0;
    feedDeliverySheetData.forEach((item) => {
      if (item[6].value) {
        result += Number(item[6].value);
      }
    });
    return result;
  };

  /* calculation of pigs in days */
  const toCalculatePigsInDays = () => {
    let result = 0;
    pigsAndBarnPigsInSheetData.forEach((item) => {
      if (item[0].value && item[1].value) {
        result +=
          (getSerialNumberOfDate(item[0].value as string) -
            serialNumberOfDateForCalculationOfDays) *
          Number(item[1].value);
      }
    });
    return result;
  };

  /* calculation of death loss days */
  const toCalculateDeathLossDays = () => {
    let result = 0;
    deathLossSheetData.forEach((item) => {
      if (item[0].value && item[1].value) {
        result +=
          (getSerialNumberOfDate(item[0].value as string) -
            serialNumberOfDateForCalculationOfDays) *
          Number(item[1].value);
      }
    });
    return result;
  };

  /* calculation of pigs sold culled pigs days */
  const toCalculatePigsSoldCulledPigsDays = () => {
    let result = 0;
    pigsAndBarnPigsSoldSheetData.forEach((item) => {
      if (item[0].value && item[1].value && item[2].value) {
        result +=
          (getSerialNumberOfDate(item[0].value as string) -
            serialNumberOfDateForCalculationOfDays) *
          Number(item[2].value);
      }
    });
    return result;
  };

  /* calculation of pigs sold days */
  const toCalculatePigsSoldPigDays = () => {
    let result = 0;
    pigsAndBarnPigsSoldSheetData.forEach((item) => {
      if (item[0].value && item[2].value && !item[1].value) {
        result +=
          (getSerialNumberOfDate(item[0].value as string) -
            serialNumberOfDateForCalculationOfDays) *
          Number(item[2].value);
      }
    });
    return result;
  };

  /* to calculate dead pigs in death loss sheet */
  const toCalculateDeadPigsInDeathLossSheet = () => {
    let result = 0;
    deathLossSheetData.forEach((item) => {
      if (item[1].value) {
        result += Number(item[1].value);
      }
    });
    return result;
  };

  /* total pig days = pigs sold days + pigs culled days + death loss days - pigs in days */
  const toCalculateTotalPigDays = () => {
    return (
      toCalculatePigsSoldPigDays() +
      toCalculatePigsSoldCulledPigsDays() +
      toCalculateDeathLossDays() -
      toCalculatePigsInDays()
    );
  };

  /* Variable to store feed consumed calculated value */
  const feedConsumedVal =
    calculateSumOfPoundsDelivered() - (inputData.feedLeft ? Number(inputData.feedLeft) : 0);

  /* Function to calculate gain bank corn total value */
  const calculateGrainBankCornTotalValue = () => {
    /* Variable to store net farm income */
    const netFarmIncome =
      calculateSumOfTotalValuePigsSold() -
      (calculateSumOfTotalValuePigsIn() + calculateSumOfActualFeedCost()) -
      calculateTotalCostFromProductionExpense();

    /* Variable to store total no. bushels based on total pounds of corn */
    const totalBushelsOfCorn = calculateTotalPoundsOfCorn() / 56;

    /* Variable to store average price billed per bushel */
    const avgPriceBilledPerBushel = calculateTotalCostOfCorn() / totalBushelsOfCorn;

    /* Variable to store net return per bushel */
    const netReturnPerBushel = (netFarmIncome + calculateTotalCostOfCorn()) / totalBushelsOfCorn;

    return netReturnPerBushel - avgPriceBilledPerBushel;
  };

  /* facility table data */
  const facilityDateTableData: CloseOutSummaryTablesType[] = [
    {
      name: 'First Fill Date',
      calculatedValue: calculateFirstFillDate(pigsAndBarnPigsInSheetData),
    },
    {
      name: 'Room Empty Date',
      calculatedValue: calculateMaxOutputDate(pigsAndBarnPigsSoldSheetData),
    },
    {
      name: 'Total Pig days',
      calculatedValue: toCalculateTotalPigDays(),
    },
  ];

  /* performance factors table data */
  const performanceTableData: CloseOutSummaryTablesType[] = [
    {
      name: 'Death Loss',
      calculatedValue:
        inputData.sessionType !== Enum_Session_Type_Enum.Nursery
          ? (1 -
              calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) /
                calculateHeadInOrHeadOut(pigsAndBarnPigsInSheetData, 1)) *
            100
          : ((toCalculateDeadPigsInDeathLossSheet() +
              (calculateHeadInOrHeadOut(pigsAndBarnPigsInSheetData, 1) -
                (calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) +
                  toCalculateDeadPigsInDeathLossSheet()))) /
              calculateHeadInOrHeadOut(pigsAndBarnPigsInSheetData, 1)) *
            100,
    },
    {
      name: 'Feed In',
      calculatedValue: calculateSumOfPoundsDelivered(),
    },
    {
      name: 'Feed Left in Bin',
      calculatedValue: inputData.feedLeft ? Number(inputData.feedLeft) : '-',
    },
    {
      name: 'Feed Consumed',
      calculatedValue: feedConsumedVal,
    },
    {
      name: 'Total Feed Cost',
      calculatedValue: calculateSumOfActualFeedCost(),
    },
  ];

  /* pig flow table data */
  const pigFlowTableData: CloseOutSummaryTablesType[] = [
    {
      name: 'Head In',
      calculatedValue: calculateHeadInOrHeadOut(pigsAndBarnPigsInSheetData, 1),
    },
    {
      name: 'Avg. Weight In',
      calculatedValue: calculateAvgWeightPigsInData(),
    },
    {
      name: 'Head Out',
      calculatedValue: calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2),
    },
    {
      name: 'Avg. Weight Out',
      calculatedValue: calculateAvgWeightOut(),
    },
    {
      name: 'Died',
      calculatedValue:
        calculateHeadInOrHeadOut(pigsAndBarnPigsInSheetData, 1) -
        calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2),
    },
    {
      name: 'Unreported Deads',
      calculatedValue:
        calculateHeadInOrHeadOut(pigsAndBarnPigsInSheetData, 1) -
        (calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) +
          toCalculateDeadPigsInDeathLossSheet()),
    },
    {
      name: 'Pigs Culled',
      calculatedValue: calculatePigsCulledHeadOut(),
    },
  ];

  /* gains table data */
  const gainsTableData: CloseOutSummaryTablesType[] = [
    {
      name: 'Avg. Daily Gain',
      calculatedValue:
        (calculateLiveWeightOfPigsSold() - calculateWeightFromPigsInData()) /
        toCalculateTotalPigDays(),
    },
    {
      name: 'Feed / Gain ',
      calculatedValue:
        feedConsumedVal / (calculateLiveWeightOfPigsSold() - calculateWeightFromPigsInData()),
    },
    {
      name: 'ADFI',
      calculatedValue:
        feedConsumedVal /
        ((getSerialNumberOfDate(calculateDate(pigsAndBarnPigsSoldSheetData, 2)) -
          getSerialNumberOfDate(calculateDate(pigsAndBarnPigsInSheetData, 1))) *
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2)),
    },
    {
      name: 'Feed Cost/ Ib. Gain',
      calculatedValue:
        calculateSumOfActualFeedCost() /
        (calculateLiveWeightOfPigsSold() - calculateWeightFromPigsInData()),
    },
  ];

  /* summary of revenue and expense table data */
  const summaryOfRevenueAndExpenses: CloseOutSummaryTablesType[] = [
    {
      name: 'Total Revenue',
      calculatedValue:
        calculateSumOfTotalValuePigsSold() /
        calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2),
    },
    {
      name: 'Grain Bank Corn -Value Added $/bu',
      calculatedValue: calculateTotalPoundsOfCorn() > 0 ? calculateGrainBankCornTotalValue() : 0,
    },
    {
      name: 'Cost of Goods',
      calculatedValue: 0,
    },
    {
      name: 'Pigs Purchased',
      calculatedValue:
        calculateSumOfTotalValuePigsIn() /
        calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2),
    },
    {
      name: 'Total Feed',
      calculatedValue:
        calculateSumOfActualFeedCost() / calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2),
    },
    {
      name: 'Total Cost Of Goods',
      calculatedValue:
        calculateSumOfActualFeedCost() / calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) +
        calculateSumOfTotalValuePigsIn() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2),
    },
    {
      name: 'Production Expenses',
      calculatedValue:
        calculateTotalCostFromProductionExpense() /
        calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2),
    },
    {
      name: 'Total Operating Expenses',
      calculatedValue:
        calculateTotalCostFromProductionExpense() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) +
        (calculateSumOfActualFeedCost() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) +
          calculateSumOfTotalValuePigsIn() /
            calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2)),
    },
    {
      name: 'Net Profit/Loss',
      calculatedValue:
        calculateSumOfTotalValuePigsSold() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) -
        (calculateSumOfActualFeedCost() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) +
          calculateSumOfTotalValuePigsIn() /
            calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2)) -
        calculateTotalCostFromProductionExpense() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2),
    },
  ];

  /* common denominator for the values of chart Data */
  const calculateChartDataDenominatorValue =
    calculateTotalCostFromProductionExpense() /
      calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) +
    calculateSumOfTotalValuePigsIn() / calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) +
    calculateSumOfActualFeedCost() / calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2);

  /* pie chart data */
  const charData = [
    {
      name: 'Production',
      value:
        (calculateTotalCostFromProductionExpense() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) /
          calculateChartDataDenominatorValue) *
        100,
    },
    {
      name: 'Feed',
      value:
        (calculateSumOfActualFeedCost() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) /
          calculateChartDataDenominatorValue) *
        100,
    },
    {
      name: 'Animal',
      value:
        (calculateSumOfTotalValuePigsIn() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) /
          calculateChartDataDenominatorValue) *
        100,
    },
  ];

  /* label data - which is used to show the colors used in pie chart */
  const labelsData = [
    {
      name: 'Production',
      color: '#fae7ea',
      value:
        (calculateTotalCostFromProductionExpense() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) /
          calculateChartDataDenominatorValue) *
        100,
    },
    {
      name: 'Feed',
      color: '#ce0e2d',
      value:
        (calculateSumOfActualFeedCost() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) /
          calculateChartDataDenominatorValue) *
        100,
    },
    {
      name: 'Animal',
      color: '#eb9fab',
      value:
        (calculateSumOfTotalValuePigsIn() /
          calculateHeadInOrHeadOut(pigsAndBarnPigsSoldSheetData, 2) /
          calculateChartDataDenominatorValue) *
        100,
    },
  ];

  /* common style for calculated values in table */
  const fontWeightStyleForTableNames: React.CSSProperties = {
    /* font weight */
    fontWeight: calledFrom === 'closeOutSummaryPage' ? 'bold' : 'normal',
  };

  return (
    <>
      <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Closeout Summary</h1>
      <div
        className={styles.closeOutSummary}
        style={{
          marginLeft: calledFrom === 'closeOutSummaryPage' ? 40 : 80,
          marginRight: calledFrom === 'closeOutSummaryPage' ? 60 : 0,
        }}
      >
        <div>
          <Descriptions
            title="General Information"
            style={{
              marginLeft: calledFrom === 'closeOutSummaryPage' ? 90 : 10,
              marginTop: calledFrom === 'closeOutSummaryPrintPreview' ? 50 : 0,
            }}
            className={calledFrom === 'closeOutSummaryPrintPreview' ? 'description' : ''}
          >
            <Descriptions.Item label="Producer Name">
              {inputData.producerName || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Location">{inputData.location || '-'}</Descriptions.Item>
            <Descriptions.Item label="Group ID">{inputData.groupId || '-'}</Descriptions.Item>
          </Descriptions>
        </div>

        <div style={{ display: 'flex' }}>
          <Col
            className={styles.columnStyle}
            style={{
              marginRight: calledFrom === 'closeOutSummaryPage' ? 50 : 0,
              marginLeft: calledFrom === 'closeOutSummaryPage' ? 90 : 10,
              marginTop: calledFrom === 'closeOutSummaryPage' ? 25 : 10,
              width: calledFrom === 'closeOutSummaryPage' ? 600 : 700,
            }}
          >
            {calledFrom === 'closeOutSummaryPrintPreview' ? (
              <h3>Facility Dates</h3>
            ) : (
              <h2>Facility Dates </h2>
            )}
            <Table<CloseOutSummaryTablesType>
              dataSource={facilityDateTableData}
              showHeader={false}
              pagination={false}
              bordered
              style={{
                marginBottom: calledFrom === 'closeOutSummaryPrintPreview' ? 10 : 20,
                width: 250,
              }}
              size="small"
            >
              <Table.Column
                key="name"
                title="Name"
                dataIndex="name"
                render={(name) => <span style={fontWeightStyleForTableNames}>{name}</span>}
              />
              <Table.Column
                key="calculatedValue"
                title="calculatedValue"
                dataIndex="calculatedValue"
                align="center"
                render={(text: number | string) => text || '-'}
              />
            </Table>

            {calledFrom === 'closeOutSummaryPrintPreview' ? <h3>Pig Flow</h3> : <h2>Pig Flow</h2>}
            <Table<CloseOutSummaryTablesType>
              dataSource={pigFlowTableData}
              showHeader={false}
              pagination={false}
              bordered
              style={{
                marginBottom: calledFrom === 'closeOutSummaryPrintPreview' ? 10 : 30,
                width: 250,
              }}
              size="small"
            >
              <Table.Column
                key="name"
                title="Name"
                dataIndex="name"
                render={(name) => <span style={fontWeightStyleForTableNames}>{name}</span>}
              />
              <Table.Column<CloseOutSummaryTablesType>
                key="calculatedValue"
                title="calculatedValue"
                dataIndex="calculatedValue"
                align="center"
                render={(text: number, render) => {
                  if (text) {
                    if (render.name === 'Avg. Weight In' || render.name === 'Avg. Weight Out') {
                      return text.toFixed(2);
                    }
                    return text;
                  }
                  return '-';
                }}
              />
            </Table>

            {calledFrom === 'closeOutSummaryPrintPreview' ? (
              <h3>Summary of Revenue and Expenses (Per Head Marketed) </h3>
            ) : (
              <h2>Summary of Revenue and Expenses (Per Head Marketed) </h2>
            )}

            <Table<CloseOutSummaryTablesType>
              dataSource={summaryOfRevenueAndExpenses}
              showHeader={false}
              pagination={false}
              bordered
              style={{ width: 400 }}
              size="small"
            >
              <Table.Column<CloseOutSummaryTablesType>
                key="name"
                title="Name"
                dataIndex="name"
                render={(name: string) => {
                  if (name === 'Cost of Goods') {
                    return <span style={{ fontStyle: 'italic' }}>Cost of Goods</span>;
                  }
                  if (
                    name === 'Pigs Purchased' ||
                    name === 'Total Feed' ||
                    name === 'Grain Bank Corn -Value Added $/bu'
                  ) {
                    return (
                      <span
                        style={{
                          marginLeft: 22,
                          fontSize: 13,
                          ...fontWeightStyleForTableNames,
                        }}
                      >
                        {name}
                      </span>
                    );
                  }
                  return <span style={fontWeightStyleForTableNames}>{name}</span>;
                }}
              />
              <Table.Column<CloseOutSummaryTablesType>
                key="calculatedValue"
                title="calculatedValue"
                dataIndex="calculatedValue"
                align="center"
                render={(text: number, record) => {
                  if (record.name === 'Cost of Goods') {
                    return null;
                  }
                  if (record.name === 'Grain Bank Corn -Value Added $/bu') {
                    return text && isFinite(text) ? `$ ${text.toFixed(2)}/bu` : '-';
                  }
                  return text && isFinite(text) ? `$ ${text.toFixed(2)}` : '-';
                }}
              />
            </Table>
          </Col>

          <Col
            className={styles.columnStyle}
            style={{
              marginLeft: calledFrom === 'closeOutSummaryPage' ? 90 : 0,
              marginTop: calledFrom === 'closeOutSummaryPage' ? 25 : 10,
              width: calledFrom === 'closeOutSummaryPage' ? 540 : 725,
            }}
          >
            {calledFrom === 'closeOutSummaryPrintPreview' ? (
              <h3>Performance Factors</h3>
            ) : (
              <h2>Performance Factors</h2>
            )}
            <Table<CloseOutSummaryTablesType>
              dataSource={performanceTableData}
              showHeader={false}
              pagination={false}
              bordered
              style={{
                marginBottom: calledFrom === 'closeOutSummaryPrintPreview' ? 10 : 20,
                width: 300,
              }}
              size="small"
            >
              <Table.Column
                key="name"
                title="Name"
                dataIndex="name"
                render={(name) => <span style={fontWeightStyleForTableNames}>{name}</span>}
              />
              <Table.Column<CloseOutSummaryTablesType>
                key="calculatedValue"
                title="calculatedValue"
                dataIndex="calculatedValue"
                align="center"
                render={(text: number, record) => {
                  if (record.name === 'Feed Left') {
                    return text;
                  }
                  if (text) {
                    if (record.name === 'Total Feed Cost') {
                      return `$ ${text}`;
                    }
                    return record.name === 'Death Loss' ? `${text.toFixed(2)} %` : text;
                  }
                  return '-';
                }}
              />
            </Table>

            <Table<CloseOutSummaryTablesType>
              dataSource={gainsTableData}
              showHeader={false}
              pagination={false}
              bordered
              style={{ marginBottom: 5, width: 300 }}
              size="small"
            >
              <Table.Column
                key="name"
                title="Name"
                dataIndex="name"
                render={(name) => <span style={fontWeightStyleForTableNames}>{name}</span>}
              />
              <Table.Column<CloseOutSummaryTablesType>
                key="calculatedValue"
                title="calculatedValue"
                dataIndex="calculatedValue"
                align="center"
                render={(text: number, record) => {
                  if (record.name === 'Feed Cost/ Ib. Gain' && text) {
                    return isFinite(text) ? `$ ${text.toFixed(2)}` : '-';
                  }
                  return text && isFinite(text) ? text.toFixed(2) : '-';
                }}
              />
            </Table>

            <div className="legendTable">
              {calledFrom === 'closeOutSummaryPage' ? (
                <h2 className="expenseDistributionHeaderStyle">Expense Distribution</h2>
              ) : (
                <h3 className="expenseDistributionHeaderStyle">Expense Distribution</h3>
              )}
              <Table
                size="small"
                showHeader={false}
                dataSource={labelsData}
                pagination={false}
                style={{ width: 160 }}
                bordered
              >
                <Table.Column
                  key="color"
                  title="color"
                  dataIndex="color"
                  width={10}
                  render={(color: string) => {
                    return (
                      <div
                        style={{ backgroundColor: color, width: 18, height: 10, marginRight: 2 }}
                      >
                        <span style={{ color: 'transparent' }}>{color}</span>
                      </div>
                    );
                  }}
                />
                <Table.Column
                  key="name"
                  title="name"
                  dataIndex="name"
                  render={(name) => (
                    <span className="colorLegendTableStyle" style={{ marginLeft: 15 }}>
                      {name}
                    </span>
                  )}
                />
                <Table.Column
                  key="value"
                  title="value"
                  dataIndex="value"
                  render={(value: number) => (
                    <span className="colorLegendTableStyle" style={{ marginLeft: 2 }}>
                      {value ? `${value.toFixed(2)} %` : '-'}
                    </span>
                  )}
                />
              </Table>
              <PieChartComponent charData={charData} />
            </div>
          </Col>
        </div>
        <p
          style={{
            marginRight: 50,
            marginTop: calledFrom === 'closeOutSummaryPage' ? -90 : -120,
            marginLeft: calledFrom === 'closeOutSummaryPage' ? 90 : 5,
          }}
        >
          All numbers, calculations, and the like contained herein regarding costs, expenses,
          production results, etc., have been calculated from INPUTS PROVIDED BY THE PRODUCER.
          Calculations have been made consistent with industry standards. Purina Animal Nutrition
          makes no warranties, express or implied and participants agree that Purina Animal
          Nutrition shall have no liability in connection therewith.
        </p>
      </div>
    </>
  );
};

export default CloseOutSummaryComponent;
