import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import UserTabLayout from './layouts/UserTabLayout';
import AddAndEditUserScreen from './screens/AddAndEditUserScreen';
import DataScreen from './screens/DataScreen';
import SettingsScreen from './screens/SettingsScreen';
import ViewAllUserScreen from './screens/ViewAllUserScreen';
import ViewSessionHistoryScreen from './screens/ViewSessionHistoryScreen';
import HelpScreen from './screens/HelpScreen';
import ReleaseFormPrintPreviewScreen from './screens/ReleaseFormPrintPreviewScreen';

/* React functional component */
const AppRoutes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <AppLayout />
      <Routes>
        <Route path="/data" element={<DataScreen />} />
        <Route path="/users" element={<UserTabLayout />}>
          <Route path="/" element={<ViewAllUserScreen />} />
          <Route path="create" element={<AddAndEditUserScreen mode="Create" />} />
        </Route>
        <Route path="/settings" element={<SettingsScreen />} />
        <Route path="/history" element={<ViewSessionHistoryScreen />} />
        <Route path="/" element={<Navigate to="/data" />} />
        <Route path="/help">
          <Route path="/" element={<HelpScreen />} />
          <Route path="/releaseFormPrint" element={<ReleaseFormPrintPreviewScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
