import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse, Button } from 'antd';
import ViewReleaseFormComponent from '../components/ViewReleaseFormComponent';

/* extracting panel from antd collapse */
const { Panel } = Collapse;

/* link to download the release form */
const releaseFormDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/ecp38_ReleaseForm.docx';

/* font size 20 for all text in help section */
const commonStyle = {
  /* font size */
  fontSize: 20,
};

/* React functional component */
export const HelpScreen = (): JSX.Element => {
  /* useNavigate destructuring */
  const navigate = useNavigate();

  return (
    <>
      <Collapse style={{ marginTop: 30, marginLeft: 30, width: '80vw' }} className="panel">
        <Panel header="Help" key="1">
          <>
            <h2>Swine Tool “HOW TO” Page instructions</h2>
            <p style={commonStyle}>
              Welcome to the Purina Abacus Progress2Profit Swine Close-Out Tool. This app allows for
              easy input of pork production data to summarize close-out data for Nursery, Finisher
              and Wean to Finish production facilities.
              <br />
              <br />
              Each page within the Swine Close-Out Tool can have data manually entered or uploaded
              from an Excel&#174; template file. A Producer File Record can be saved and recalled if
              a user cannot complete all the entries in a single session.
              <br />
              <br />
              Each page can be navigated by clicking on the “TITLE BUBBLE” for each Input category.
              Buttons at the bottom of the page allow for saving data/saving session.
              <br />
              <br />
              RESET – Clears all data and starts a blank session, Save to DB – option to Save all
              data inputted to a file in the Cloud – can only be saved to the Cloud when “online”
              while connected to the internet.
              <br />
              <br />
              If working “offline,” a copy of data is saved on local C Drive of your computer, but
              only for the current producer record being edited. If you start a new record and save,
              the previous file will be overwritten and lost. You CANNOT save multiple producer
              close-out files locally.
              <br />
              <br />
              Closeout Summary – Generates a report with the information entered. If incomplete
              information and/or inaccurate information is entered, then erroneous values will be
              calculated.
              <br />
              <br />
              "All numbers, calculations, and the like contained herein regarding costs, expenses,
              production results, etc., have been calculated from INPUTS PROVIDED BY THE PRODUCER.
              Calculations have been made consistent with industry standards. Purina Animal
              Nutrition makes no warranties, express or implied and participants agree that Purina
              Animal Nutrition shall have no liability in connection therewith.”
              <br />
              <br />
              Let's get started.
            </p>
          </>
          <Collapse>
            <Panel header="Pigs & Barn : PIGS IN" key="pigsIn">
              <p style={commonStyle}>
                <b>General Information</b> – Required fields are{''}
                <b> "Producer Name"</b> and <b>Group ID</b>. A Producer can have multiple Group IDs,
                but each Group ID must be unique. <b>“Location”</b> is optional but helps identify a
                record file if a producer has multiple sites in separate locations.
                <br />
                <br />
                Manual entry of <b>DATE</b>, number of <b>HEAD</b>, <b>BODY WEIGHT</b>, <b>TOTAL</b>{' '}
                and <b>TOTAL VALUE,US$</b>, can be entered by clicking on the cell and entering the
                value. Avg. Body Wt. and Avg, Value, US$/head are automatically calculated for you
                (if ‘Total Pig weight’ selected in ‘Pig weight type’ selection). If pigs are
                received on multiple days, each delivery date is a new row. Add a new row by
                clicking on <b>"+New row"</b> button.
              </p>
              <p style={commonStyle}>
                If you received all the required data from the producer in an Excel® workbook, you
                can click on the ‘ALL OR CURRENT PAGE DATA UPLOAD’ button and download the Template
                File. Each page has a unique template, represented by its own tab within the
                workbook. You can copy and paste the appropriate values representing Date, Head,
                Total Body Weight, Avg Body Wt, Total Value, US$ from the original producer provided
                spreadsheet into the template worksheet. The complete workbook can be uploaded to
                the tool and will populate all the input pages.
                <br />
                <br />
                If manually entering inputs, you have the option to select “Total Pig Weight” or
                “Average Pig Weight”. Please select the option based on the information you have
                available. The shaded cell will automatically calculate based on your selection.
              </p>
            </Panel>
            <Panel header="Pigs & Barn : PIGS SOLD" key="pigsSold">
              <p style={commonStyle}>
                Similar to the previous page, data can be manually entered when pigs are sold.
                <b> Date</b>,<b> Is Cull</b>,<b> Total Head Out / Total Head Sold</b>,
                <b> Total Carcass Wt</b> ,<b> Total Live Wt </b>,<b> Avg Body Wt</b>,
                <b> Total Value, US$</b> values can be entered from packer harvest reports. If
                manually entering inputs, you have the option to select “Total Pig Weight” or
                “Average Pig Weight”. Please select the option based on the information you have
                available. The shaded cell will automatically calculate based on your selection. If
                pigs are sold as CULLS, you can put a checkmark in the box for CULLS. This will
                allow CULLS data to be segregated in the final summary report as a line item to show
                their value contribution.
                <br />
                <br />
                ‘ALL OR CURRENT PAGE DATA UPLOAD' procedures are the same as PIGS IN. Data captured
                in a producer provided Excel® document can be copied and pasted into the template
                for Pigs SOLD (in 'ALL OR CURRENT PAGE DATA UPLOAD’ template file) and upload the
                template file.
                <br />
                <br />
                <h4>OUTPUT INFORMATION</h4>
                <b>Running Inventory :</b> Summation of pigs still on inventory in barn. Calculated
                from Summed Pigs In inventory as of last delivery date minus dead pigs and pigs
                sold.
                <br />
                <br />
                <b>Last Day in Barn:</b> Last date pigs were shipped out and barn officially closed.
                <br />
                <br />
                Based on input values for Pigs IN, Pigs Out, Death Loss, Production Expenses, and
                Feed Delivery to date, calculations for basic economic measures are reported as part
                of running inventory outputs. Final values will be represented on a single page
                Close-Out Summary Report.
              </p>
            </Panel>
            <Panel header="Death Loss" key="deathLoss">
              <p style={commonStyle}>
                Record of pig deaths and reason.
                <br />
                <br />
                Required information includes <b>DATE</b>, <b>Number (#) DEAD</b>,{' '}
                <b>WEIGHT (TOTAL)</b> and <b>REASON </b> – Reason is optional, but can be selected
                from a list to better understand health challenge incidence and severity.
                <br />
                <br />
                <b>Unreported Deads</b> is a results entry to reconcile the total head count at time
                of closeout. Unreported deads are calculated from Total Pigs In minus the sum of
                Full Value pigs sold, Cull pigs sold, and Dead Pigs recorded.
                <br />
                <br />
                Unreported Deads is also included in the final % mortality as % Death Loss.
                <br />
                <br />
                <b>‘ALL OR CURRENT PAGE DATA UPLOAD’</b> of <b>Death Loss</b> can also be completed
                in similar fashion as PIGS IN and PIGS OUT templates
              </p>
            </Panel>
            <Panel header="Production Expenses" key="productionExpense">
              <p style={commonStyle}>
                Manually enter <b>DATE</b>, <b>DESCRIPTION</b>, <b>ACCOUNT</b> (Select from Drop
                Down), <b>TOTAL COST</b>
                <br />
                <br />
                'ALL OR CURRENT PAGE DATA UPLOAD' of Production Expenses can be completed similarly
                as other inputs. A “Chart of Accounts” is available in the template file on second
                tab to identify appropriate Account type.
                <br />
                <br />
                These inputs are important to be as accurate as possible to calculate total expenses
                separate from Feed Costs (Feed Cost plus Grind, Mix, Delivery Fees).
              </p>
            </Panel>
            <Panel header="Feed Delivery" key="feedDelivery">
              <p style={commonStyle}>
                Manual Entry of Feed Deliveries. This information can be inputted over time as feed
                deliveries occur, or all at one time. It is important that feed deliveries are
                entered in chronological order when entered manually or within a ‘ALL OR CURRENT
                PAGE DATA UPLOAD’ template
                <br />
                <br />
                Date, Feed Type (Ration #1, 2,3...12,13, or 14). Feed Details may include Actual
                Diet Name/Product Reference, i.e., Accu6, LucraFit 320, UltraCare240, GF1 20% DDGS,
                etc.
                <br />
                <br />
                Pounds Delivered – Total pounds for that delivery.
                <br />
                <br />
                Actual Feed Costs – Sum Total of Feed Cost + Grind, Mix, Delivery Fee (if
                applicable.) 'ALL OR CURRENT PAGE DATA UPLOAD' of Feed Delivery template file is
                like other 'ALL OR CURRENT PAGE DATA UPLOAD' template file procedures.
                <br />
                <br />
                <b>Corn Grain Bank:</b> If producer uses grain banked corn, this helps equate the
                Opportunity Cost of feeding it to pigs, or if it should have been sold and corn
                purchased instead.
              </p>
            </Panel>
          </Collapse>
        </Panel>
        <Panel header="Release form" key="form">
          <ViewReleaseFormComponent calledFrom="panel" />
          <div style={{ display: 'flex', justifyContent: 'center' }} className="releaseFormButton">
            <Button
              className="buttonColorRed"
              style={{ marginRight: 20 }}
              href={releaseFormDownloadUrl}
            >
              Download
            </Button>
            <Button
              onClick={() => {
                navigate('releaseFormPrint');
              }}
              style={{ borderRadius: 5 }}
            >
              Print Preview
            </Button>
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default HelpScreen;
