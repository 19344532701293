import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Button, message, Spin, Tooltip, Space, Dropdown, Menu } from 'antd';
import { InfoCircleTwoTone, CaretDownOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { ApolloError, useMutation, Reference, useQuery, useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import localforage from 'localforage';
import styles from './DataScreen.module.scss';
import FormItem from '../components/FormItem';
import Input from '../components/Input';
import Select from '../components/Select';
import PigsAndBarnPigsInSheet from '../components/sheets/PigsAndBarnPigsInSheet';
import PigsAndBarnPigsSoldSheet from '../components/sheets/PigsAndBarnPigsSoldSheet';
import DeathLossSheet from '../components/sheets/DeathLossSheet';
import ProductionExpensesSheet from '../components/sheets/ProductionExpensesSheet';
import FeedDeliverySheet from '../components/sheets/FeedDeliverySheet';
import CloseOutSummaryScreen from './CloseOutSummaryScreen';
import SessionNameModalComponent from '../components/SessionNameModalComponent';
import AlertModalComponent from '../components/AlertModalComponent';
import ShowWarningComponent from '../components/ShowWarningComponent';
import NurseryNameModalComponent from '../components/NurseryNameModalComponent';
import {
  DeathLossSheetGridElement,
  DeathLossSheetIndividualGridElement,
  FeedDeliverySheetGridElement,
  FeedDeliverySheetIndividualGridElement,
  PigsAndBarnPigsInSheetGridElement,
  PigsAndBarnPigsInSheetIndividualGridElement,
  PigsAndBarnPigsSoldSheetGridElement,
  PigsAndBarnPigsSoldSheetIndividualGridElement,
  ProductionExpensesSheetGridElement,
  ProductionExpensesSheetIndividualGridElement,
  GeneralInformationFormFieldsType,
  LocalForageDataType,
  RowColumnIndexType,
} from '../utils/types';
import {
  deathLossSheetColumns,
  feedDeliverySheetColumns,
  pigsAndBarnPigsInSheetColumns,
  pigsAndBarnPigsSoldSheetColumns,
  productionExpensesSheetColumns,
  generalInformationInputStyle,
  pigsSoldSheetColumnsForNurserySessionType,
} from '../utils/sheetGlobals';
import {
  renderPigsAndBarnPigsInSheetRows,
  renderPigsAndBarnPigsSoldSheetRows,
  renderDeathLossSheetRows,
  renderProductionExpensesSheetRows,
  renderFeedDeliverySheetRows,
  renderPigsAndBarnPigsSoldSheetRowsForNurserySession,
} from '../utils/sheetHelpers';
import { getSheetDataFromLocalForage, handleSaveSessionData, logger } from '../utils/helpers';
import {
  AddDataMutation,
  AddDataMutationVariables,
  SessionByIdQuery,
  SessionByIdQueryVariables,
  UpdateSessionByPkMutation,
  UpdateSessionByPkMutationVariables,
  Data_Set_Input,
  GetSavedSessionsQuery,
  GetSavedSessionsQueryVariables,
  Enum_Session_Type_Enum,
  GetNurserySessionsQuery,
  GetNurserySessionsQueryVariables,
} from '../graphql/graphql-types';
import RadioGroup from '../components/RadioGroup';

/* common styling for buttons */
const buttonStyle = {
  border: '1px solid #2B78E4',
  borderRadius: 5,
  paddingLeft: 25,
  paddingRight: 25,
  marginRight: 20,
};

const { TabPane } = Tabs;

/* pigs sold submit values type */
type PigsSoldSubmitValuesType = Omit<PigsAndBarnPigsSoldSheetIndividualGridElement, 'date'> & {
  /* date */
  date: string | undefined;
};

/* Loading add data mutation */
const addDataMutation = loader('../graphql/mutations/addDataMutation.graphql');
/* Loading update session data mutation */
const updateSessionByPkMutation = loader('../graphql/mutations/updateSessionByPkMutation.graphql');
/* Loading session by ID query */
const sessionByIdQuery = loader('../graphql/queries/sessionByIdQuery.graphql');
/* loading saved sessions of user based on user role */
const getSavedSessionsQuery = loader('../graphql/queries/getSavedSessionsQuery.graphql');
/* loading nursery sessions query with the help of loader */
const getNurserySessionsQuery = loader('../graphql/queries/getNurserySessionsQuery.graphql');

/* Pigs and barn pigs in sheet initial data */
const pigsAndBarnPigsInSheetInitialData: PigsAndBarnPigsInSheetIndividualGridElement[] = [
  {
    date: '',
    head: undefined,
    weight: undefined,
    avgWt: undefined,
    totalVal: undefined,
    avg: undefined,
  },
];

/* Pigs and barn pigs sold sheet initial data */
const pigsAndBarnPigsSoldSheetInitialData: PigsAndBarnPigsSoldSheetIndividualGridElement[] = [
  {
    date: '',
    isCull: null,
    hdOut: undefined,
    carcassWt: undefined,
    liveWt: undefined,
    yield: undefined,
    avgWt: undefined,
    totalVal: undefined,
    avg: undefined,
  },
];

/* Pigs and barn pigs sold sheet initial data */
const pigsAndBarnPigsSoldSheetInitialDataForNurserySession: PigsAndBarnPigsSoldSheetIndividualGridElement[] =
  [
    {
      date: '',
      isCull: null,
      hdOut: undefined,
      liveWt: undefined,
      avgWt: undefined,
      totalVal: undefined,
      avg: undefined,
    },
  ];

/* Production Expenses sold sheet initial data */
const productionExpensesSheetInitialData: ProductionExpensesSheetIndividualGridElement[] = [
  {
    date: '',
    description: '',
    account: '',
    totalCost: undefined,
  },
];

// Initial data of death loss sheet
const deathLossSheetInitialData: DeathLossSheetIndividualGridElement[] = [
  {
    date: '',
    dead: undefined,
    weight: undefined,
    reason: '',
    deathWeek: undefined,
  },
];

/* Feed Delivery sold sheet initial data */
const feedDeliverySheetInitialData: FeedDeliverySheetIndividualGridElement[] = [
  {
    // delivery: undefined,
    date: '',
    feedType: '',
    poundsDelivered: undefined,
    actualFeedCost: undefined,
    feedDetails: '',
    poundsOfCorn: undefined,
    costOfCorn: undefined,
  },
];

/* Schema for validation */
const validationSchema = yup.object().shape({
  producerName: yup.string().required('Please enter producer name and try again'),
  groupId: yup.string().required('Please enter group ID and try again'),
});

/* Function to do calculate total of 'head in' in pigs in sheet */
const calculateTotalHeadIn = (data: PigsAndBarnPigsInSheetGridElement[][]): number => {
  let result = 0;
  data.forEach((item) => {
    if (item[1].value) {
      result += Number(item[1].value);
    }
  });
  return result;
};

/* Function to do calculate total of 'hd out' in pigs sold sheet */
const calculateTotalHeadOut = (data: PigsAndBarnPigsSoldSheetGridElement[][]): number => {
  let result = 0;
  data.forEach((item) => {
    if (item[2].value) {
      result += Number(item[2].value);
    }
  });
  return result;
};

/* React functional component */
const DataScreen = (): JSX.Element => {
  /* State to store active tab key */
  const [activeTabKey, setActiveTabKey] = useState<number>(1);

  /* State to decide whether to show/hide loading indicator on save to db btn */
  const [saveToDbBtnLoading, setSaveToDbBtnLoading] = useState<boolean>(false);

  /* State to decide whether to show/hide loading indicator on save to session btn */
  const [saveToSessionBtnLoading, setSaveToSessionBtnLoading] = useState<boolean>(false);

  /* State to decide whether to show/hide loading indicator on reset btn */
  const [resetBtnLoading, setResetBtnLoading] = useState<boolean>(false);

  /* state to handle the visibility of close out summary */
  const [isCloseOutSummaryVisible, setIsCloseOutSummaryVisible] = useState<boolean>(false);

  /* state to handle the modal visibility - (whether to show the modal or not ) */
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  /* this state is use to store the selected session id from dropdown of recall session */
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);

  /* State to decide whether to show/hide session name */
  const [showSessionNameModal, setShowSessionNameModal] = useState<boolean>(false);

  /* state to handle the visibility of reset modal by storing session type values which will also help to solve the session type ui issue */
  const [resetModalVisibilityAndSessionType, setResetModalVisibilityAndSessionType] =
    useState<Enum_Session_Type_Enum | null>(null);

  /* state to check whether to show common bulk upload modal or not */
  const [showCommonBulkUploadModal, setShowCommonBulkUploadModal] = useState<boolean>(false);

  /* State to check whether to show bulk upload modal or not */
  const [showBulkUploadModal, setShowBulkUploadModal] = useState<boolean>(false);

  /* State to store Pigs and barn pigs in sheet data */
  const [pigsAndBarnPigsInSheetData, setPigsAndBarnPigsInSheetData] = useState<
    PigsAndBarnPigsInSheetGridElement[][]
  >([
    pigsAndBarnPigsInSheetColumns,
    ...pigsAndBarnPigsInSheetInitialData.map((item) => [...renderPigsAndBarnPigsInSheetRows(item)]),
  ]);

  /* state to store pigs and barn pigs sold sheet data when session type is Nursery */
  const [
    pigsAndBarnPigsSoldSheetDataForNurserySession,
    setPigsAndBarPigsSoldSheetDataForNurserySession,
  ] = useState<PigsAndBarnPigsSoldSheetGridElement[][]>([
    pigsSoldSheetColumnsForNurserySessionType,
    ...pigsAndBarnPigsSoldSheetInitialDataForNurserySession.map((item) => [
      ...renderPigsAndBarnPigsSoldSheetRowsForNurserySession(item),
    ]),
  ]);

  /* State to store Pigs and barn pigs sold sheet data */
  const [pigsAndBarnPigsSoldSheetData, setPigsAndBarnPigsSoldSheetData] = useState<
    PigsAndBarnPigsSoldSheetGridElement[][]
  >([
    pigsAndBarnPigsSoldSheetColumns,
    ...pigsAndBarnPigsSoldSheetInitialData.map((item) => [
      ...renderPigsAndBarnPigsSoldSheetRows(item),
    ]),
  ]);

  /* State to store Production expenses sheet data */
  const [productionExpensesSheetData, setProductionExpensesSheetData] = useState<
    ProductionExpensesSheetGridElement[][]
  >([
    productionExpensesSheetColumns,
    ...productionExpensesSheetInitialData.map((item) => [
      ...renderProductionExpensesSheetRows(item),
    ]),
  ]);

  // stores the death loss sheet data
  const [deathLossSheetData, setDeathLossSheetData] = useState<DeathLossSheetGridElement[][]>([
    deathLossSheetColumns,
    ...deathLossSheetInitialData.map((item) => [...renderDeathLossSheetRows(item)]),
  ]);

  /* State to store Feed Delivery sheet data */
  const [feedDeliverySheetData, setFeedDeliverySheetData] = useState<
    FeedDeliverySheetGridElement[][]
  >([
    feedDeliverySheetColumns,
    ...feedDeliverySheetInitialData.map((item) => [...renderFeedDeliverySheetRows(item)]),
  ]);

  /* state to handle validation errors on pigs and barn pigs in sheet */
  const [pigsAndBarnPigsInValidationErrors, setPigsAndBarnPigsInValidationErrors] = useState<
    Array<RowColumnIndexType>
  >([]);

  /* state to handle validation errors on pigs and barn pigs sold sheet */
  const [pigsAndBarnPigsSoldValidationErrors, setPigsAndBarnPigsSoldValidationErrors] = useState<
    Array<RowColumnIndexType>
  >([]);

  /* state to handle validation errors on pigs and barn pigs sold sheet when session type is nursery */
  const [
    pigsAndBarnPigsSoldValidationErrorsForNurserySession,
    setPigsAndBarnPigsSoldValidationErrorsForNurserySession,
  ] = useState<Array<RowColumnIndexType>>([]);

  /* state to handle validation errors on death loss sheet */
  const [deathLossSheetValidationErrors, setDeathLossSheetValidationErrors] = useState<
    Array<RowColumnIndexType>
  >([]);

  /* state to handle validation errors on production expense sheet */
  const [productionExpenseSheetValidationErrors, setProductionExpenseSheetValidationErrors] =
    useState<Array<RowColumnIndexType>>([]);

  /* state to handle validation errors on feed delivery sheet */
  const [feedDeliverySheetValidationErrors, setFeedDeliverySheetValidationErrors] = useState<
    Array<RowColumnIndexType>
  >([]);

  /* state to store the duplicate session name error and then passing it down to show name modal component */
  const [duplicateSessionNameError, setDuplicateSessionNameError] = useState<string | undefined>(
    undefined,
  );

  /* state to store the current session type */
  const [currentSessionType, setCurrentSessionType] = useState<Enum_Session_Type_Enum | null>(null);

  /* state to handle nursery modal visibility by storing session type which will also help us to solve the ui issue  */
  const [nurseryModalVisibilityAndSessionType, setNurseryModalVisibilityAndSessionType] =
    useState<Enum_Session_Type_Enum | null>(null);

  /* state to store the id of nursery selected for grow finish inputs */
  const [nurserySelectedId, setNurserySelectedId] = useState<string | null>(null);

  /* State to decide whether to show/hide close out summary print preview */
  const [showCloseoutSummaryPrintPreview, setShowCloseOutSummaryPrintPreview] =
    useState<boolean>(false);

  /* useForm declaration */
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<GeneralInformationFormFieldsType>({
    defaultValues: {
      producerName: '',
      location: '',
      groupId: '',
      id: null,
      name: '',
      pigWtType: 'totalPigWt',
      feedLeft: null,
    },
    resolver: yupResolver(validationSchema),
  });

  /* Mutation to add data to db */
  const [addData] = useMutation<AddDataMutation, AddDataMutationVariables>(addDataMutation);

  /* Mutation to update session data to db */
  const [updateSessionByPk] = useMutation<
    UpdateSessionByPkMutation,
    UpdateSessionByPkMutationVariables
  >(updateSessionByPkMutation);

  /* use lazy query to fetch nursery session data */
  const [
    getNurserySessions,
    { error: nurseryDataError, data: nurserySessionsData, loading: nurseryDataLoading },
  ] = useLazyQuery<GetNurserySessionsQuery, GetNurserySessionsQueryVariables>(
    getNurserySessionsQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setNurseryModalVisibilityAndSessionType(Enum_Session_Type_Enum.GrowFinish);
      },
      onError: () => {
        if (
          nurseryDataError &&
          nurseryDataError.name === 'Error' &&
          nurseryDataError.message === 'Failed to fetch'
        ) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('Device offline. Cannot use Nursery data for Grow-Finish');
        }
      },
    },
  );

  /* Query to fetch session by ID data */
  const [sessionById, { loading: sessionByIdLoading, error: sessionByIdErr }] = useLazyQuery<
    SessionByIdQuery,
    SessionByIdQueryVariables
  >(sessionByIdQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (queryData) => {
      /* session data */
      const sessionData = queryData.data_by_pk;

      if (nurserySelectedId && sessionData && sessionData.pigs_and_barn_pigs_sold) {
        /* pigs in sheet data which is taken from pigs sold sheet data for nursery -> grow finish farms */
        const pigsData = (
          sessionData.pigs_and_barn_pigs_sold as PigsAndBarnPigsSoldSheetIndividualGridElement[]
        )
          .filter((obj) => !obj.isCull)
          .map((item) => {
            /* avg body weight */
            const avgBodyWeight = item.liveWt && item.hdOut ? item.liveWt / item.hdOut : null;

            /* avg value */
            const avg = item.totalVal && item.hdOut ? item.totalVal / item.hdOut : null;

            return {
              date: item.date,
              head: item.hdOut,
              weight: item.liveWt,
              avgWt: avgBodyWeight || undefined,
              totalVal: item.totalVal,
              avg: avg || undefined,
            };
          });
        setPigsAndBarnPigsInSheetData([
          pigsAndBarnPigsInSheetColumns,
          ...pigsData.map((item) => [...renderPigsAndBarnPigsInSheetRows(item)]),
        ]);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Data successfully transferred');
        setNurserySelectedId(null);
      }

      if (!nurserySelectedId) {
        setValue('id', selectedSessionId);
        if (sessionData) {
          setValue('producerName', sessionData.producer_name || '');
          setValue('name', sessionData.name || '');
          setValue('groupId', sessionData.group_id || '');
          setValue('location', sessionData.location || '');
          setValue('feedLeft', sessionData.feed_left || null);
          setCurrentSessionType(sessionData.session_type || null);

          /* recalled session pigs in data */
          const pigsInDataOfRecalledSession =
            sessionData.pigs_and_barn_pigs_in as PigsAndBarnPigsInSheetIndividualGridElement[];
          if (pigsInDataOfRecalledSession) {
            setPigsAndBarnPigsInSheetData([
              pigsAndBarnPigsInSheetColumns,
              ...pigsInDataOfRecalledSession.map((item) => [
                ...renderPigsAndBarnPigsInSheetRows(item),
              ]),
            ]);
          } else {
            setPigsAndBarnPigsInSheetData([
              pigsAndBarnPigsInSheetColumns,
              ...pigsAndBarnPigsInSheetInitialData.map((item) => [
                ...renderPigsAndBarnPigsInSheetRows(item),
              ]),
            ]);
          }

          /* recalled session pigs sold data */
          const pigsSoldDataOfRecalledSession =
            sessionData.pigs_and_barn_pigs_sold as PigsAndBarnPigsSoldSheetIndividualGridElement[];
          if (pigsSoldDataOfRecalledSession) {
            if (sessionData.session_type !== Enum_Session_Type_Enum.Nursery) {
              setPigsAndBarnPigsSoldSheetData([
                pigsAndBarnPigsSoldSheetColumns,
                ...pigsSoldDataOfRecalledSession.map((item) => [
                  ...renderPigsAndBarnPigsSoldSheetRows(item),
                ]),
              ]);
            } else {
              setPigsAndBarPigsSoldSheetDataForNurserySession([
                pigsSoldSheetColumnsForNurserySessionType,
                ...pigsSoldDataOfRecalledSession.map((item) => [
                  ...renderPigsAndBarnPigsSoldSheetRowsForNurserySession(item),
                ]),
              ]);
            }
          }

          /* recalled session death loss data */
          const deathLossDataOfRecalledSession =
            sessionData.death_loss as DeathLossSheetIndividualGridElement[];
          if (deathLossDataOfRecalledSession) {
            setDeathLossSheetData([
              deathLossSheetColumns,
              ...deathLossDataOfRecalledSession.map((item) => [...renderDeathLossSheetRows(item)]),
            ]);
          } else {
            setDeathLossSheetData([
              deathLossSheetColumns,
              ...deathLossSheetInitialData.map((item) => [...renderDeathLossSheetRows(item)]),
            ]);
          }

          /* recalled session production expense data */
          const productionExpenseDataOfRecalledSession =
            sessionData.production_expenses as ProductionExpensesSheetIndividualGridElement[];
          if (productionExpenseDataOfRecalledSession) {
            setProductionExpensesSheetData([
              productionExpensesSheetColumns,
              ...productionExpenseDataOfRecalledSession.map((item) => [
                ...renderProductionExpensesSheetRows(item),
              ]),
            ]);
          } else {
            setProductionExpensesSheetData([
              productionExpensesSheetColumns,
              ...productionExpensesSheetInitialData.map((item) => [
                ...renderProductionExpensesSheetRows(item),
              ]),
            ]);
          }

          /* recalled session feed delivery data */
          const feedDeliveryDataOfRecalledSession =
            sessionData.feed_delivery as FeedDeliverySheetIndividualGridElement[];
          if (feedDeliveryDataOfRecalledSession) {
            setFeedDeliverySheetData([
              feedDeliverySheetColumns,
              ...feedDeliveryDataOfRecalledSession.map((item) => [
                ...renderFeedDeliverySheetRows(item),
              ]),
            ]);
          } else {
            setFeedDeliverySheetData([
              feedDeliverySheetColumns,
              ...feedDeliverySheetInitialData.map((item) => [...renderFeedDeliverySheetRows(item)]),
            ]);
          }
        }
      }
      setIsModalVisible(false);
      setPigsAndBarnPigsInValidationErrors([]);
      setPigsAndBarnPigsSoldValidationErrors([]);
      setDeathLossSheetValidationErrors([]);
      setProductionExpenseSheetValidationErrors([]);
      setFeedDeliverySheetValidationErrors([]);
      setPigsAndBarnPigsSoldValidationErrorsForNurserySession([]);
    },
    onError: () => {
      if (
        sessionByIdErr &&
        sessionByIdErr.name === 'Error' &&
        sessionByIdErr.message === 'Failed to fetch'
      ) {
        /* when the device is offline, query will throw 'failed to fetch' error and we have to show the below error message on recalling a new session from select box */
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('Cannot recall saved session as device is offline.');
        setIsModalVisible(false);
      }
    },
  });

  /* saved session query */
  const {
    data: userSavedSessionData,
    error: userSavedSessionError,
    loading,
  } = useQuery<GetSavedSessionsQuery, GetSavedSessionsQueryVariables>(getSavedSessionsQuery, {
    fetchPolicy: 'network-only',
  });

  /* In this useEffect  if data is present in local storage then it is loaded and shown to the user */
  useEffect(() => {
    /* to retrieve data from local storage */
    getSheetDataFromLocalForage()
      .then((data) => {
        if (data) {
          setValue('producerName', data.producerName);
          if (data.location) {
            setValue('location', data.location);
          }
          if (data.name) {
            setValue('name', data.name);
            setValue('id', data.id);
          }
          setValue('groupId', data.groupId);
          setCurrentSessionType(data.sessionType);
          setValue('feedLeft', data.feedLeft || null);
          /* pigs in data */
          const pigsInData =
            data.pigs_and_barn_pigs_in as PigsAndBarnPigsInSheetIndividualGridElement[];
          if (pigsInData) {
            setPigsAndBarnPigsInSheetData([
              pigsAndBarnPigsInSheetColumns,
              ...pigsInData.map((item) => [...renderPigsAndBarnPigsInSheetRows(item)]),
            ]);
          }

          /* pigs sold data */
          const pigsSoldData =
            data.pigs_and_barn_pigs_sold as PigsAndBarnPigsSoldSheetIndividualGridElement[];
          if (data.sessionType !== Enum_Session_Type_Enum.Nursery) {
            if (pigsSoldData) {
              setPigsAndBarnPigsSoldSheetData([
                pigsAndBarnPigsSoldSheetColumns,
                ...pigsSoldData.map((item) => [...renderPigsAndBarnPigsSoldSheetRows(item)]),
              ]);
            }
          } else {
            setPigsAndBarPigsSoldSheetDataForNurserySession([
              pigsSoldSheetColumnsForNurserySessionType,
              ...pigsSoldData.map((item) => [
                ...renderPigsAndBarnPigsSoldSheetRowsForNurserySession(item),
              ]),
            ]);
          }

          /* death loss data */
          const deathLossData = data.death_loss as DeathLossSheetIndividualGridElement[];
          if (deathLossData) {
            setDeathLossSheetData([
              deathLossSheetColumns,
              ...deathLossData.map((item) => [...renderDeathLossSheetRows(item)]),
            ]);
          }

          /* production expense data */
          const productionExpenseData =
            data.production_expenses as ProductionExpensesSheetIndividualGridElement[];
          if (productionExpenseData) {
            setProductionExpensesSheetData([
              productionExpensesSheetColumns,
              ...productionExpenseData.map((item) => [...renderProductionExpensesSheetRows(item)]),
            ]);
          }

          /* feed delivery data */
          const feedDeliveryData = data.feed_delivery as FeedDeliverySheetIndividualGridElement[];
          if (feedDeliveryData) {
            setFeedDeliverySheetData([
              feedDeliverySheetColumns,
              ...feedDeliveryData.map((item) => [...renderFeedDeliverySheetRows(item)]),
            ]);
          }
        }
      })
      .catch((error) => {
        logger(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  /**
   * This useEffect triggers whenever pig weight type changes
   * After change it changes the calculations for some columns in pigs in and pigs sold screen and also disabled inputs in sheet
   * changes as per selection
   */
  useEffect(() => {
    if (watch('pigWtType')) {
      /* Variable to store existing pigs in data stored in state */
      const existingPigsInData: PigsAndBarnPigsInSheetIndividualGridElement[] = [];
      /* Variable to store existing pigs sold data stored in state */
      const existingPigsSoldData: PigsAndBarnPigsSoldSheetIndividualGridElement[] = [];

      if (watch('pigWtType') === 'avgPigWt') {
        pigsAndBarnPigsInSheetData.forEach((item, index) => {
          if (index !== 0) {
            existingPigsInData.push({
              date: item[0].value ? (item[0].value as string) : '',
              head: item[1].value ? Number(item[1].value) : undefined,
              weight:
                item[1].value && item[3].value
                  ? Number(item[1].value) * Number(item[3].value)
                  : undefined,
              avgWt: item[3].value ? Number(item[3].value) : undefined,
              totalVal:
                item[1].value && item[5].value
                  ? Number(item[1].value) * Number(item[5].value)
                  : undefined,
              avg: item[5].value ? Number(item[5].value) : undefined,
            });
          }
        });
        setPigsAndBarnPigsInSheetData([
          pigsAndBarnPigsInSheetColumns,
          ...existingPigsInData.map((item) => [...renderPigsAndBarnPigsInSheetRows(item, [2, 4])]),
        ]);

        if (currentSessionType === Enum_Session_Type_Enum.Nursery) {
          pigsAndBarnPigsSoldSheetDataForNurserySession.forEach((item, index) => {
            if (index !== 0) {
              existingPigsSoldData.push({
                date: item[0].value ? (item[0].value as string) : '',
                isCull: item[1].value === 'Y' || item[1].value === 'true' ? 'true' : null,
                hdOut: item[2].value ? Number(item[2].value) : undefined,
                liveWt:
                  item[2].value && item[4].value
                    ? Number(item[2].value) * Number(item[4].value)
                    : undefined,
                avgWt: item[4].value ? Number(item[4].value) : undefined,
                totalVal:
                  item[2].value && item[6].value
                    ? Number(item[2].value) * Number(item[6].value)
                    : undefined,
                avg: item[6].value ? Number(item[6].value) : undefined,
              });
            }
          });

          setPigsAndBarPigsSoldSheetDataForNurserySession([
            pigsSoldSheetColumnsForNurserySessionType,
            ...existingPigsSoldData.map((item) => [
              ...renderPigsAndBarnPigsSoldSheetRowsForNurserySession(item, [4, 7]),
            ]),
          ]);
        } else {
          pigsAndBarnPigsSoldSheetData.forEach((item, index) => {
            if (index !== 0) {
              existingPigsSoldData.push({
                date: item[0].value ? (item[0].value as string) : '',
                isCull: item[1].value === 'Y' || item[1].value === 'true' ? 'true' : null,
                hdOut: item[2].value ? Number(item[2].value) : undefined,
                carcassWt: item[3].value ? Number(item[3].value) : undefined,
                liveWt:
                  item[2].value && item[6].value
                    ? Number(item[2].value) * Number(item[6].value)
                    : undefined,
                yield: item[5].value ? Number(item[5].value) : undefined,
                avgWt: item[6].value ? Number(item[6].value) : undefined,
                totalVal:
                  item[2].value && item[8].value
                    ? Number(item[2].value) * Number(item[8].value)
                    : undefined,
                avg: item[8].value ? Number(item[8].value) : undefined,
              });
            }
          });
          setPigsAndBarnPigsSoldSheetData([
            pigsAndBarnPigsSoldSheetColumns,
            ...existingPigsSoldData.map((item) => [
              ...renderPigsAndBarnPigsSoldSheetRows(item, [4, 5, 7]),
            ]),
          ]);
        }
      } else {
        pigsAndBarnPigsInSheetData.forEach((item, index) => {
          if (index !== 0) {
            existingPigsInData.push({
              date: item[0].value ? (item[0].value as string) : '',
              head: item[1].value ? Number(item[1].value) : undefined,
              weight: item[2].value ? Number(item[2].value) : undefined,
              avgWt:
                item[1].value && item[2].value
                  ? Number(item[2].value) / Number(item[1].value)
                  : undefined,
              totalVal: item[4].value ? Number(item[4].value) : undefined,
              avg:
                item[4].value && item[1].value
                  ? Number(item[4].value) / Number(item[1].value)
                  : undefined,
            });
          }
        });
        setPigsAndBarnPigsInSheetData([
          pigsAndBarnPigsInSheetColumns,
          ...existingPigsInData.map((item) => [...renderPigsAndBarnPigsInSheetRows(item)]),
        ]);

        if (currentSessionType === Enum_Session_Type_Enum.Nursery) {
          pigsAndBarnPigsSoldSheetDataForNurserySession.forEach((item, index) => {
            if (index !== 0) {
              existingPigsSoldData.push({
                date: item[0].value ? (item[0].value as string) : '',
                isCull: item[1].value === 'true' || item[1].value === 'Y' ? 'true' : null,
                hdOut: item[2].value ? Number(item[2].value) : undefined,
                liveWt: item[3].value ? Number(item[3].value) : undefined,
                avgWt:
                  item[2].value && item[3].value
                    ? Number(item[3].value) / Number(item[2].value)
                    : undefined,
                totalVal: item[5].value ? Number(item[5].value) : undefined,
                avg:
                  item[2].value && item[5].value
                    ? Number(item[5].value) / Number(item[2].value)
                    : undefined,
              });
            }
          });

          setPigsAndBarPigsSoldSheetDataForNurserySession([
            pigsSoldSheetColumnsForNurserySessionType,
            ...existingPigsSoldData.map((item) => [
              ...renderPigsAndBarnPigsSoldSheetRowsForNurserySession(item),
            ]),
          ]);
        } else {
          pigsAndBarnPigsSoldSheetData.forEach((item, index) => {
            if (index !== 0) {
              existingPigsSoldData.push({
                date: item[0].value ? (item[0].value as string) : '',
                isCull: item[1].value === 'true' || item[1].value === 'Y' ? 'true' : null,
                hdOut: item[2].value ? Number(item[2].value) : undefined,
                carcassWt: item[3].value ? Number(item[3].value) : undefined,
                liveWt: item[4].value ? Number(item[4].value) : undefined,
                yield: item[5].value ? Number(item[5].value) : undefined,
                avgWt:
                  item[2].value && item[4].value
                    ? Number(item[4].value) / Number(item[2].value)
                    : undefined,
                totalVal: item[7].value ? Number(item[7].value) : undefined,
                avg:
                  item[7].value && item[2].value
                    ? Number(item[7].value) / Number(item[2].value)
                    : undefined,
              });
            }
          });

          setPigsAndBarnPigsSoldSheetData([
            pigsAndBarnPigsSoldSheetColumns,
            ...existingPigsSoldData.map((item) => [...renderPigsAndBarnPigsSoldSheetRows(item)]),
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('pigWtType')]);

  /* function to make the close out summary visible */
  const makeCloseOutSummaryPageVisible = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSubmit(() => {
      setIsCloseOutSummaryVisible(true);
    })();
  };

  /* function to hide the close out summary */
  const makeCloseOutSummaryPageHidden = () => {
    setIsCloseOutSummaryVisible(false);
  };

  /* This function removes empty/unwanted rows from sheet data and returns filtered data that can be save to db */
  const getFilteredDataFromSheetsData = () => {
    /* Variable to store 'pigsAndBarnPigsIn' sheet data submit values */
    const pigsAndBarnPigsInSheetSubmitValues =
      pigsAndBarnPigsInSheetData.length > 1
        ? pigsAndBarnPigsInSheetData
            .slice(1)
            .map((item) => {
              return {
                date: item[0].value ? (item[0].value as string) : undefined,
                head: item[1].value ? Number(item[1].value) : undefined,
                weight: item[2].value ? Number(item[2].value) : undefined,
                avgWt: item[3].value ? Number(item[3].value) : undefined,
                totalVal: item[4].value ? Number(item[4].value) : undefined,
                avg: item[5].value ? Number(item[5].value) : undefined,
              };
            })
            .filter(
              (item) =>
                item.date || item.head || item.weight || item.avgWt || item.totalVal || item.avg,
            )
        : [];

    /* variable to store pigs sold sheet data submit values */
    let pigsAndBarnPigsSoldSheetSubmitValues: PigsSoldSubmitValuesType[] = [];

    if (currentSessionType !== Enum_Session_Type_Enum.Nursery) {
      pigsAndBarnPigsSoldSheetSubmitValues =
        pigsAndBarnPigsSoldSheetData.length > 1
          ? pigsAndBarnPigsSoldSheetData
              .slice(1)
              .map((item) => {
                return {
                  date: item[0].value ? (item[0].value as string) : undefined,
                  isCull: item[1].value ? (item[1].value as string) : null,
                  hdOut: item[2].value ? Number(item[2].value) : undefined,
                  carcassWt: item[3].value ? Number(item[3].value) : undefined,
                  liveWt: item[4].value ? Number(item[4].value) : undefined,
                  yield: item[5].value ? Number(item[5].value) : undefined,
                  avgWt: item[6].value ? Number(item[6].value) : undefined,
                  totalVal: item[7].value ? Number(item[7].value) : undefined,
                  avg: item[8].value ? Number(item[8].value) : undefined,
                };
              })
              .filter(
                (item) =>
                  item.date ||
                  item.isCull ||
                  item.hdOut ||
                  item.carcassWt ||
                  item.liveWt ||
                  item.yield ||
                  item.avgWt ||
                  item.totalVal ||
                  item.avg,
              )
          : [];
    } else {
      pigsAndBarnPigsSoldSheetSubmitValues =
        pigsAndBarnPigsSoldSheetDataForNurserySession.length > 1
          ? pigsAndBarnPigsSoldSheetDataForNurserySession
              .slice(1)
              .map((item) => {
                return {
                  date: item[0].value ? (item[0].value as string) : undefined,
                  isCull: item[1].value ? (item[1].value as string) : null,
                  hdOut: item[2].value ? Number(item[2].value) : undefined,
                  liveWt: item[3].value ? Number(item[3].value) : undefined,
                  avgWt: item[4].value ? Number(item[4].value) : undefined,
                  totalVal: item[5].value ? Number(item[5].value) : undefined,
                  avg: item[6].value ? Number(item[6].value) : undefined,
                };
              })
              .filter(
                (item) =>
                  item.date ||
                  item.isCull ||
                  item.hdOut ||
                  item.liveWt ||
                  item.avgWt ||
                  item.totalVal ||
                  item.avg,
              )
          : [];
    }

    /* Variable to store 'deathLoss' sheet data submit values */
    const deathLossSheetSubmitValues =
      deathLossSheetData.length > 1
        ? deathLossSheetData
            .slice(1)
            .map((item) => {
              return {
                date: item[0].value ? (item[0].value as string) : undefined,
                dead: item[1].value ? Number(item[1].value) : undefined,
                weight: item[2].value ? Number(item[2].value) : undefined,
                reason: item[3].value || null,
                deathWeek: item[4].value ? Number(item[4].value) : undefined,
              };
            })
            .filter(
              (item) => item.date || item.dead || item.weight || item.reason || item.deathWeek,
            )
        : [];

    /* Variable to store 'productionExpenses' sheet data submit values */
    const productionExpensesSheetSubmitValues =
      productionExpensesSheetData.length > 1
        ? productionExpensesSheetData
            .slice(1)
            .map((item) => {
              return {
                date: item[0].value ? (item[0].value as string) : undefined,
                description: item[1].value ? (item[1].value as string) : undefined,
                account: item[2].value ? (item[2].value as string) : undefined,
                totalCost: item[3].value ? Number(item[3].value) : undefined,
              };
            })
            .filter((item) => item.date || item.description || item.account || item.totalCost)
        : [];

    /* Variable to store 'feedDelivery' sheet data submit values */
    const feedDeliverySheetSubmitValues =
      feedDeliverySheetData.length > 1
        ? feedDeliverySheetData
            .slice(1)
            .map((item) => {
              return {
                // delivery: item[0].value || undefined,
                date: item[0].value ? (item[0].value as string) : undefined,
                feedType: item[1].value ? (item[1].value as string) : undefined,
                feedDetails: item[2].value ? (item[2].value as string) : undefined,
                poundsDelivered: item[3].value ? Number(item[3].value) : undefined,
                actualFeedCost: item[4].value ? Number(item[4].value) : undefined,
                poundsOfCorn: item[5].value ? Number(item[5].value) : undefined,
                costOfCorn: item[6].value ? Number(item[6].value) : undefined,
              };
            })
            .filter(
              (item) =>
                // item.delivery ||
                item.date ||
                item.feedType ||
                item.poundsDelivered ||
                item.feedDetails ||
                item.actualFeedCost ||
                item.poundsOfCorn ||
                item.costOfCorn,
            )
        : [];

    return {
      pigsAndBarnPigsInSheetSubmitValues,
      pigsAndBarnPigsSoldSheetSubmitValues,
      deathLossSheetSubmitValues,
      feedDeliverySheetSubmitValues,
      productionExpensesSheetSubmitValues,
    };
  };

  /* to clear the data on reset button */
  const handleReset = (calledFrom?: string, sessionId?: string) => {
    if (calledFrom !== 'newSession') {
      if (calledFrom !== 'nurseryModal') {
        setResetBtnLoading(true);
      }
    }

    setPigsAndBarnPigsInSheetData([
      pigsAndBarnPigsInSheetColumns,
      ...pigsAndBarnPigsInSheetInitialData.map((item) => [
        ...renderPigsAndBarnPigsInSheetRows(item),
      ]),
    ]);

    setPigsAndBarnPigsSoldSheetData([
      pigsAndBarnPigsSoldSheetColumns,
      ...pigsAndBarnPigsSoldSheetInitialData.map((item) => [
        ...renderPigsAndBarnPigsSoldSheetRows(item),
      ]),
    ]);

    setPigsAndBarPigsSoldSheetDataForNurserySession([
      pigsSoldSheetColumnsForNurserySessionType,
      ...pigsAndBarnPigsSoldSheetInitialDataForNurserySession.map((item) => [
        ...renderPigsAndBarnPigsSoldSheetRowsForNurserySession(item),
      ]),
    ]);

    setDeathLossSheetData([
      deathLossSheetColumns,
      ...deathLossSheetInitialData.map((item) => [...renderDeathLossSheetRows(item)]),
    ]);
    setProductionExpensesSheetData([
      productionExpensesSheetColumns,
      ...productionExpensesSheetInitialData.map((item) => [
        ...renderProductionExpensesSheetRows(item),
      ]),
    ]);
    setFeedDeliverySheetData([
      feedDeliverySheetColumns,
      ...feedDeliverySheetInitialData.map((item) => [...renderFeedDeliverySheetRows(item)]),
    ]);
    reset();
    setPigsAndBarnPigsInValidationErrors([]);
    setPigsAndBarnPigsSoldValidationErrors([]);
    setDeathLossSheetValidationErrors([]);
    setProductionExpenseSheetValidationErrors([]);
    setFeedDeliverySheetValidationErrors([]);
    setPigsAndBarnPigsSoldValidationErrorsForNurserySession([]);
    localforage
      .removeItem('sheetData')
      .then(() => {
        if (calledFrom === 'nurseryModal' && sessionId) {
          sessionById({ variables: { id: sessionId } });
        }
        setResetBtnLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('New session has been successfully started');
        setActiveTabKey(1);
        setResetModalVisibilityAndSessionType(null);
        setNurseryModalVisibilityAndSessionType(null);
      })
      .catch((error) => {
        setResetBtnLoading(false);
        logger(error);
      });
  };

  /* Function to handle add new session */
  const handleAddNewSession = (sessionName: string) => {
    setSaveToDbBtnLoading(true);

    const {
      pigsAndBarnPigsInSheetSubmitValues,
      pigsAndBarnPigsSoldSheetSubmitValues,
      productionExpensesSheetSubmitValues,
      feedDeliverySheetSubmitValues,
      deathLossSheetSubmitValues,
    } = getFilteredDataFromSheetsData();

    /* Variable to store final mutation values */
    const mutationValues = {
      name: sessionName,
      producer_name: getValues('producerName'),
      location: getValues('location') || null,
      group_id: getValues('groupId') || null,
      pigs_and_barn_pigs_in:
        pigsAndBarnPigsInSheetSubmitValues.length > 0 ? pigsAndBarnPigsInSheetSubmitValues : null,
      pigs_and_barn_pigs_sold:
        pigsAndBarnPigsSoldSheetSubmitValues.length > 0
          ? pigsAndBarnPigsSoldSheetSubmitValues
          : null,
      death_loss: deathLossSheetSubmitValues.length > 0 ? deathLossSheetSubmitValues : null,
      production_expenses:
        productionExpensesSheetSubmitValues.length > 0 ? productionExpensesSheetSubmitValues : null,
      feed_delivery:
        feedDeliverySheetSubmitValues.length > 0 ? feedDeliverySheetSubmitValues : null,
      session_type: currentSessionType as Enum_Session_Type_Enum,
      feed_left: getValues('feedLeft') ? Number(getValues('feedLeft')) : null,
    };

    addData({
      variables: mutationValues,
      update(cache, { data: addedData }) {
        /* extracting the new data added by user */
        const dataToAdd = addedData?.insert_data_one;
        cache.modify({
          fields: {
            data(existingData: Array<Reference>) {
              return [...existingData, dataToAdd];
            },
          },
        });
      },
    })
      .then((res) => {
        setSaveToDbBtnLoading(false);
        setShowSessionNameModal(false);
        setDuplicateSessionNameError(undefined);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Data has been successfully saved locally and to database.');

        /* Variable to store new session data */
        const newSessionData = res.data?.insert_data_one;

        if (newSessionData) {
          setValue('id', newSessionData.id);
          setValue('name', newSessionData.name);
        }
        if (resetModalVisibilityAndSessionType) {
          setCurrentSessionType(resetModalVisibilityAndSessionType);
          handleReset();
        }
      })
      .catch((err: ApolloError) => {
        logger(err);
        if (err.name === 'Error' && err.message === 'Failed to fetch') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('Device is offline. Cannot save data to DB');
        }
        if (
          err.message ===
          'Uniqueness violation. duplicate key value violates unique constraint "data_name_key"'
        ) {
          setDuplicateSessionNameError(
            'Session name already exists in DB. Please enter different name and try again”',
          );
        }
        setSaveToDbBtnLoading(false);
      });
  };

  /* Function to handle updating existing session data */
  const handleEditExistingSession = (
    values: Data_Set_Input,
    calledFrom?: string,
    sessionId?: string,
  ) => {
    updateSessionByPk({
      variables: {
        id: watch('id') as string,
        sessionData: values,
      },
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Data has been successfully saved locally and to database. ');
        /* Below conditions are added for updated functionality where save to db will be called before new session selection */
        if (calledFrom === 'newSession') {
          /* Below condition is for session type nursery to grow-finish */
          if (sessionId) {
            setCurrentSessionType(nurseryModalVisibilityAndSessionType);
            setNurserySelectedId(sessionId);
            handleReset('nurseryModal', sessionId);
          } else {
            /* Below condition is for session types other than nursery to grow-finish */
            setCurrentSessionType(resetModalVisibilityAndSessionType);
            handleReset();
          }
        } else {
          setSaveToDbBtnLoading(false);
        }
      })
      .catch((err: ApolloError) => {
        logger(err);
        if (err.name === 'Error' && err.message === 'Failed to fetch') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('Device is offline. Cannot save data to DB');
        }
        setSaveToDbBtnLoading(false);
      });
  };

  /* Function to get data for local forage so that it can be save locally */
  const getDataForLocalForage = () => {
    const {
      pigsAndBarnPigsInSheetSubmitValues,
      pigsAndBarnPigsSoldSheetSubmitValues,
      productionExpensesSheetSubmitValues,
      feedDeliverySheetSubmitValues,
      deathLossSheetSubmitValues,
    } = getFilteredDataFromSheetsData();

    /* variable to store data which is being stored in local storage */
    const data: LocalForageDataType = {
      pigs_and_barn_pigs_in:
        pigsAndBarnPigsInSheetSubmitValues.length > 0
          ? pigsAndBarnPigsInSheetSubmitValues
          : pigsAndBarnPigsInSheetInitialData,
      pigs_and_barn_pigs_sold:
        pigsAndBarnPigsSoldSheetSubmitValues.length > 0
          ? pigsAndBarnPigsSoldSheetSubmitValues
          : pigsAndBarnPigsSoldSheetInitialData,
      death_loss:
        deathLossSheetSubmitValues.length > 0
          ? deathLossSheetSubmitValues
          : deathLossSheetInitialData,
      production_expenses:
        productionExpensesSheetSubmitValues.length > 0
          ? productionExpensesSheetSubmitValues
          : productionExpensesSheetInitialData,
      feed_delivery:
        feedDeliverySheetSubmitValues.length > 0
          ? feedDeliverySheetSubmitValues
          : feedDeliverySheetInitialData,
      producerName: getValues('producerName'),
      location: getValues('location'),
      groupId: getValues('groupId'),
      sessionType: currentSessionType,
      feedLeft: getValues('feedLeft'),
    };

    /* Variable to store updated data based on id is present or not */
    const updatedData: LocalForageDataType = watch('id')
      ? { ...data, id: watch('id'), name: watch('name') }
      : data;

    return updatedData;
  };

  /* Function to save the sheet data to db */
  const handleSaveToDb = (
    toShowSuccessMessage: boolean,
    calledFrom?: string,
    sessionId?: string,
  ) => {
    if (calledFrom === 'saveSession' && toShowSuccessMessage) {
      setSaveToSessionBtnLoading(true);
    }
    if (calledFrom === 'newSession' && toShowSuccessMessage) {
      setResetBtnLoading(true);
    }

    const {
      pigsAndBarnPigsInSheetSubmitValues,
      pigsAndBarnPigsSoldSheetSubmitValues,
      productionExpensesSheetSubmitValues,
      feedDeliverySheetSubmitValues,
      deathLossSheetSubmitValues,
    } = getFilteredDataFromSheetsData();

    if (
      pigsAndBarnPigsInSheetSubmitValues.length === 0 &&
      pigsAndBarnPigsSoldSheetSubmitValues.length === 0 &&
      deathLossSheetSubmitValues.length === 0 &&
      productionExpensesSheetSubmitValues.length === 0 &&
      feedDeliverySheetSubmitValues.length === 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('Please enter input data and try again');
      if (calledFrom === 'saveSession') {
        setSaveToSessionBtnLoading(false);
      } else if (calledFrom === 'newSession') {
        setResetBtnLoading(false);
      } else {
        setSaveToDbBtnLoading(false);
      }
      return;
    }

    if (calledFrom === 'saveSession') {
      /* Variable to store data to be updated in local forage */
      const updatedLocalData = getDataForLocalForage();

      handleSaveSessionData(updatedLocalData)
        .then(() => {
          if (toShowSuccessMessage) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Session has been successfully saved locally.');
          }
          setSaveToSessionBtnLoading(false);
        })
        .catch((error) => {
          setSaveToSessionBtnLoading(false);
          logger(error);
        });
    } else {
      /* Variable to store data to be updated in local forage */
      const updatedLocalData = getDataForLocalForage();

      /* Variable to store final mutation values */
      const mutationValues = {
        producer_name: getValues('producerName'),
        location: getValues('location') || null,
        group_id: getValues('groupId') || null,
        pigs_and_barn_pigs_in:
          pigsAndBarnPigsInSheetSubmitValues.length > 0 ? pigsAndBarnPigsInSheetSubmitValues : null,
        pigs_and_barn_pigs_sold:
          pigsAndBarnPigsSoldSheetSubmitValues.length > 0
            ? pigsAndBarnPigsSoldSheetSubmitValues
            : null,
        death_loss: deathLossSheetSubmitValues.length > 0 ? deathLossSheetSubmitValues : null,
        production_expenses:
          productionExpensesSheetSubmitValues.length > 0
            ? productionExpensesSheetSubmitValues
            : null,
        feed_delivery:
          feedDeliverySheetSubmitValues.length > 0 ? feedDeliverySheetSubmitValues : null,
        name: getValues('name') as string,
        feed_left: getValues('feedLeft') || null,
      };

      /* Updating data locally first and then to db */
      handleSaveSessionData(updatedLocalData)
        .then(() => {
          if (watch('id')) {
            handleEditExistingSession(mutationValues, calledFrom, sessionId);
            if (calledFrom !== 'newSession') {
              setSaveToDbBtnLoading(true);
            }
          } else {
            setShowSessionNameModal(true);
          }
        })
        .catch((error) => {
          setSaveToSessionBtnLoading(false);
          logger(error);
        });
    }
  };

  if (loading) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  if (
    userSavedSessionError &&
    userSavedSessionError.name !== 'Error' &&
    userSavedSessionError.message !== 'Failed to fetch'
  ) {
    return <div style={{ color: 'red' }}>{userSavedSessionError.message}</div>;
  }

  /* common props passed down to all sheets */
  const commonPropsPassedToSheets = {
    showCommonBulkUploadModal: showCommonBulkUploadModal,
    setShowCommonBulkUploadModal: setShowCommonBulkUploadModal,
    showBulkUploadModal: showBulkUploadModal,
    setShowBulkUploadModal: setShowBulkUploadModal,
    activeTabKey: activeTabKey,
    sessionType: currentSessionType,
    selectedPigWtType: watch('pigWtType'),
  };

  /* menu which will be displayed in new session button dropdown */
  const buttonDropDownMenu = (
    <Menu
      onClick={(event) => {
        if (event.key === 'nurseryToGrowFinish') {
          getNurserySessions();
        } else {
          setResetModalVisibilityAndSessionType(event.key as Enum_Session_Type_Enum);
        }
      }}
    >
      <Menu.Item key={Enum_Session_Type_Enum.Nursery}>Nursery</Menu.Item>
      <Menu.Item key={Enum_Session_Type_Enum.GrowFinish}>Grow-Finish</Menu.Item>
      <Menu.Item key={Enum_Session_Type_Enum.WeanFinish}>Wean-Finish</Menu.Item>
      <Menu.Item key="nurseryToGrowFinish">Nursery to Grow-Finish</Menu.Item>
    </Menu>
  );

  /* function to get data which has session type as nursery*/
  const getNurserySessionsData = () => {
    let nurseryValues;
    if (
      nurserySessionsData &&
      Array.isArray(nurserySessionsData.data) &&
      nurserySessionsData.data.length > 0
    ) {
      nurseryValues = nurserySessionsData.data.map((obj) => {
        return {
          label: obj.name,
          value: obj.id,
        };
      });
    }
    return nurseryValues ? nurseryValues : [];
  };

  /* names to display depending upon the session type */
  const getCurrentSessionName = () => {
    let name;
    if (currentSessionType === Enum_Session_Type_Enum.Nursery) {
      name = 'Nursery';
    } else if (currentSessionType === Enum_Session_Type_Enum.GrowFinish) {
      name = 'Grow-Finish';
    } else {
      name = 'Wean-Finish';
    }
    return name;
  };

  /* Function to call save to db functionality */
  const callToSaveToDbFunc = (calledFrom?: 'newSession') => {
    if (currentSessionType) {
      if (
        pigsAndBarnPigsInValidationErrors.length > 0 ||
        (currentSessionType !== Enum_Session_Type_Enum.Nursery
          ? pigsAndBarnPigsSoldValidationErrors.length > 0
          : pigsAndBarnPigsSoldValidationErrorsForNurserySession.length > 0) ||
        deathLossSheetValidationErrors.length > 0 ||
        productionExpenseSheetValidationErrors.length > 0 ||
        feedDeliverySheetValidationErrors.length > 0
      ) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('Please resolve all errors');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        handleSubmit(() => {
          if (calledFrom === 'newSession') {
            handleSaveToDb(true, 'newSession');
          } else {
            handleSaveToDb(true);
          }
        })();
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(`Please select session type from 'New session' and try again`);
    }
  };

  /* Function to handle create new session when user wants to start new session by selecting different session type */
  const handleCreateNewSession = (sessionId?: string) => {
    if (currentSessionType) {
      if (
        pigsAndBarnPigsInValidationErrors.length > 0 ||
        (currentSessionType !== Enum_Session_Type_Enum.Nursery
          ? pigsAndBarnPigsSoldValidationErrors.length > 0
          : pigsAndBarnPigsSoldValidationErrorsForNurserySession.length > 0) ||
        deathLossSheetValidationErrors.length > 0 ||
        productionExpenseSheetValidationErrors.length > 0 ||
        feedDeliverySheetValidationErrors.length > 0
      ) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('Please resolve all errors');
      } else {
        if (watch('id') && watch('name')) {
          handleSaveToDb(true, 'newSession', sessionId);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          handleSubmit(() => {
            setShowSessionNameModal(true);
          })();
        }
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(`Please select session type from 'New session' and try again`);
    }
  };

  return (
    <>
      {isModalVisible ? (
        <AlertModalComponent
          isModalVisible={isModalVisible}
          handleCancel={() => {
            setIsModalVisible(false);
          }}
          okBtnLoading={sessionByIdLoading}
          handleOk={() => {
            if (selectedSessionId) {
              sessionById({ variables: { id: selectedSessionId } });
            }
          }}
          message="Are you sure you want to switch session ? Any unsaved data will be lost"
        />
      ) : null}

      {resetModalVisibilityAndSessionType ? (
        <AlertModalComponent
          isModalVisible={!!resetModalVisibilityAndSessionType}
          handleCancel={() => {
            handleReset('newSession');
            setCurrentSessionType(resetModalVisibilityAndSessionType);
          }}
          onClose={() => {
            setResetModalVisibilityAndSessionType(null);
          }}
          okBtnLoading={resetBtnLoading}
          handleOk={() => {
            handleCreateNewSession();
          }}
          cancelBtnLabel="Continue Without Saving"
          okBtnLabel="Save & Continue"
          message="Are you sure you want to start new session?"
          modalProps={{
            footer: [
              <Button
                key="1"
                onClick={() => {
                  setResetModalVisibilityAndSessionType(null);
                }}
              >
                No
              </Button>,
              <Button
                key="2"
                onClick={() => {
                  handleReset('newSession');
                  setCurrentSessionType(resetModalVisibilityAndSessionType);
                }}
              >
                Continue Without Saving
              </Button>,
              <Button
                className="buttonColorRed"
                loading={resetBtnLoading}
                key="3"
                type="primary"
                onClick={() => {
                  handleCreateNewSession();
                }}
              >
                Save & Continue
              </Button>,
            ],
            width: 550,
          }}
        />
      ) : null}

      {/* this modal is created for the 4Th condition ( Nursery to grow-finish). After selecting this option user has to select from which nursery it has come and according to that fields will change in all sheets where ever required */}
      {nurseryModalVisibilityAndSessionType ? (
        <NurseryNameModalComponent
          dropDownOptions={getNurserySessionsData()}
          isModalVisible={!!nurseryModalVisibilityAndSessionType}
          handleCancel={(sessionId) => {
            setCurrentSessionType(nurseryModalVisibilityAndSessionType);
            setNurserySelectedId(sessionId);
            handleReset('nurseryModal', sessionId);
          }}
          handleOk={(sessionId) => {
            handleCreateNewSession(sessionId);
          }}
          message="Are you sure you want to start new session?"
          okBtnLoading={resetBtnLoading}
          nurseryDataLoading={nurseryDataLoading}
          showNoBtn
          onClose={() => {
            setNurseryModalVisibilityAndSessionType(null);
          }}
        />
      ) : null}

      <div style={{ display: 'flex' }} className="hideWhilePrinting">
        <FormItem
          label="Recall session"
          inputColSpan={17}
          labelStyle={{ fontSize: 18 }}
          labelColSpan={7}
          customStyle={{ width: 500 }}
        >
          <Select
            placeholder={
              userSavedSessionData &&
              Array.isArray(userSavedSessionData.data) &&
              userSavedSessionData.data.length > 0
                ? 'Select saved session'
                : 'No saved sessions'
            }
            name="id"
            rhfControllerProps={{ control }}
            selectProps={{
              showSearch: true,
              disabled:
                userSavedSessionData &&
                Array.isArray(userSavedSessionData.data) &&
                userSavedSessionData.data.length > 0 &&
                !userSavedSessionError
                  ? false
                  : true,
              optionFilterProp: 'children',
            }}
            options={
              userSavedSessionData &&
              Array.isArray(userSavedSessionData.data) &&
              userSavedSessionData.data.length > 0
                ? userSavedSessionData.data.map((item) => ({ value: item.id, label: item.name }))
                : []
            }
            customStyles={{ width: 310 }}
            onChange={(rhfOnChange, value) => {
              setSelectedSessionId(value);
              if (value) {
                setIsModalVisible(true);
              }
            }}
          />
        </FormItem>

        <div
          style={{
            textAlign: 'center',
            marginTop: 30,
            marginLeft: 40,
            marginRight: 50,
          }}
        >
          <span style={{ fontSize: 16 }}>{`Session Type : ${
            currentSessionType ? getCurrentSessionName() : '-'
          }`}</span>
        </div>

        {userSavedSessionError &&
        userSavedSessionError.name === 'Error' &&
        userSavedSessionError.message === 'Failed to fetch' ? (
          <ShowWarningComponent
            message="Cannot recall saved session as device is offline."
            containerStyle={{ marginTop: 27, marginLeft: 50 }}
          />
        ) : null}
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 38 }}
        className="hideWhilePrinting"
      >
        <FormItem
          label={
            <div style={{ display: 'flex' }}>
              <div>Type of Pig weight entered</div>
              <div style={{ cursor: 'pointer', marginLeft: 5, alignItems: 'flex-end' }}>
                <Tooltip
                  color="white"
                  title={
                    <div style={{ color: 'black' }}>
                      Choose whether you are using total pig weight or average pig weight as input
                    </div>
                  }
                  overlayInnerStyle={{ borderRadius: 5 }}
                >
                  <span>
                    <InfoCircleTwoTone />
                  </span>
                </Tooltip>
              </div>
            </div>
          }
          inputColSpan={17}
          labelStyle={{ fontSize: 16 }}
          labelColSpan={7}
          customStyle={{ width: 800 }}
        >
          <RadioGroup
            name="pigWtType"
            rhfControllerProps={{
              control,
            }}
            options={[
              { label: 'Total Pig weight', value: 'totalPigWt' },
              { label: 'Average Pig weight', value: 'avgPigWt' },
            ]}
          />
        </FormItem>
      </div>
      <div
        className={
          showCloseoutSummaryPrintPreview ? styles.printPreviewContainer : styles.container
        }
      >
        {showSessionNameModal ? (
          <SessionNameModalComponent
            isModalVisible={showSessionNameModal}
            handleSave={(sessionName) => {
              handleAddNewSession(sessionName);
            }}
            handleCancel={() => {
              setShowSessionNameModal(false);
              setDuplicateSessionNameError(undefined);
            }}
            saveToDbBtnLoading={saveToDbBtnLoading}
            duplicateSessionNameError={duplicateSessionNameError}
            setDuplicateSessionError={setDuplicateSessionNameError}
          />
        ) : null}

        {!isCloseOutSummaryVisible ? (
          <>
            <div className={styles.topContainer}>
              <div className={styles.heading}>
                General Information
                <span style={{ marginLeft: 10, cursor: 'pointer' }}>
                  <Tooltip
                    color="white"
                    title={
                      <div style={{ color: 'black' }}>
                        “Producer Name” and “Group ID” are required cells. “Group ID” must be a
                        unique identifier.
                      </div>
                    }
                    overlayInnerStyle={{ borderRadius: 5 }}
                  >
                    <span>
                      <InfoCircleTwoTone />
                    </span>
                  </Tooltip>
                </span>
              </div>
              <Row>
                <Col>
                  <FormItem
                    label="Producer Name"
                    labelColSpan={7}
                    inputColSpan={17}
                    labelStyle={{ textAlign: 'start' }}
                    errorText={errors.producerName ? errors.producerName.message : undefined}
                    errorTextStyle={{ textAlign: 'start' }}
                    isRequired
                  >
                    <Input
                      name="producerName"
                      rhfControllerProps={{ control }}
                      placeholder="Please enter producer name"
                      customStyles={{
                        ...generalInformationInputStyle,
                        width: 300,
                      }}
                    />
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Location" labelColSpan={5} inputColSpan={19}>
                    <Input
                      name="location"
                      rhfControllerProps={{ control }}
                      placeholder="Please enter location"
                      customStyles={generalInformationInputStyle}
                    />
                  </FormItem>
                </Col>
                <Col>
                  <FormItem
                    label="Group ID"
                    labelColSpan={4}
                    inputColSpan={20}
                    errorText={errors.groupId ? errors.groupId.message : undefined}
                    errorTextStyle={{ textAlign: 'start' }}
                    isRequired
                  >
                    <Input
                      name="groupId"
                      rhfControllerProps={{ control }}
                      placeholder="Please enter group ID"
                      customStyles={generalInformationInputStyle}
                    />
                  </FormItem>
                </Col>
              </Row>
            </div>
            <div className={styles.bottomContainer}>
              <div className="dataClass">
                <Tabs
                  defaultActiveKey="1"
                  type="card"
                  onChange={(activeKey) => {
                    setActiveTabKey(Number(activeKey));
                  }}
                  activeKey={activeTabKey.toString()}
                >
                  <TabPane tab="Pigs & Barn: PIGS IN" key={1}>
                    <PigsAndBarnPigsInSheet
                      sheetData={pigsAndBarnPigsInSheetData}
                      updateSheetData={setPigsAndBarnPigsInSheetData}
                      pigsAndBarnPigsSoldSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? pigsAndBarnPigsSoldSheetData
                          : pigsAndBarnPigsSoldSheetDataForNurserySession
                      }
                      updatePigsAndBarnPigsSoldSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldSheetData
                          : setPigsAndBarPigsSoldSheetDataForNurserySession
                      }
                      deathLossSheetData={deathLossSheetData}
                      updateDeathLossSheetData={setDeathLossSheetData}
                      productionExpensesSheetData={productionExpensesSheetData}
                      updateProductionExpensesSheetData={setProductionExpensesSheetData}
                      feedDeliverySheetData={feedDeliverySheetData}
                      updateFeedDeliverySheetData={setFeedDeliverySheetData}
                      pigsAndBarnPigsInValidationErrors={pigsAndBarnPigsInValidationErrors}
                      setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
                      setPigsAndBarnPigsSoldValidationErrors={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldValidationErrors
                          : setPigsAndBarnPigsSoldValidationErrorsForNurserySession
                      }
                      setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
                      setProductionExpenseSheetValidationErrors={
                        setProductionExpenseSheetValidationErrors
                      }
                      setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
                      {...commonPropsPassedToSheets}
                    />
                  </TabPane>
                  <TabPane
                    tab="Pigs & Barn: PIGS SOLD"
                    key={2}
                    className="pigsAndBarnPigsSoldTableWidth"
                  >
                    <PigsAndBarnPigsSoldSheet
                      sheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? pigsAndBarnPigsSoldSheetData
                          : pigsAndBarnPigsSoldSheetDataForNurserySession
                      }
                      updateSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldSheetData
                          : setPigsAndBarPigsSoldSheetDataForNurserySession
                      }
                      pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
                      deathLossSheetData={deathLossSheetData}
                      productionExpensesSheetData={productionExpensesSheetData}
                      feedDeliverySheetData={feedDeliverySheetData}
                      updateFeedDeliverySheetData={setFeedDeliverySheetData}
                      updateProductionExpensesSheetData={setProductionExpensesSheetData}
                      updateDeathLossSheetData={setDeathLossSheetData}
                      updatePigsAndBarnPigsInSheetData={setPigsAndBarnPigsInSheetData}
                      pigsAndBarnPigsSoldValidationErrors={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? pigsAndBarnPigsSoldValidationErrors
                          : pigsAndBarnPigsSoldValidationErrorsForNurserySession
                      }
                      setPigsAndBarnPigsSoldValidationErrors={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldValidationErrors
                          : setPigsAndBarnPigsSoldValidationErrorsForNurserySession
                      }
                      setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
                      setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
                      setProductionExpenseSheetValidationErrors={
                        setProductionExpenseSheetValidationErrors
                      }
                      setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
                      {...commonPropsPassedToSheets}
                    />
                  </TabPane>
                  <TabPane tab="Death Loss" key={3}>
                    <DeathLossSheet
                      sheetData={deathLossSheetData}
                      updateSheetData={setDeathLossSheetData}
                      pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
                      pigsAndBarnPigsSoldSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? pigsAndBarnPigsSoldSheetData
                          : pigsAndBarnPigsSoldSheetDataForNurserySession
                      }
                      productionExpensesSheetData={productionExpensesSheetData}
                      feedDeliverySheetData={feedDeliverySheetData}
                      updateFeedDeliverySheetData={setFeedDeliverySheetData}
                      updateProductionExpensesSheetData={setProductionExpensesSheetData}
                      updatePigsAndBarnPigsInSheetData={setPigsAndBarnPigsInSheetData}
                      updatePigsAndBarnPigsSoldSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldSheetData
                          : setPigsAndBarPigsSoldSheetDataForNurserySession
                      }
                      deathLossSheetValidationErrors={deathLossSheetValidationErrors}
                      setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
                      setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
                      setPigsAndBarnPigsSoldValidationErrors={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldValidationErrors
                          : setPigsAndBarnPigsSoldValidationErrorsForNurserySession
                      }
                      setProductionExpenseSheetValidationErrors={
                        setProductionExpenseSheetValidationErrors
                      }
                      setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
                      {...commonPropsPassedToSheets}
                    />
                  </TabPane>
                  <TabPane tab="Production Expenses" key={4}>
                    <ProductionExpensesSheet
                      sheetData={productionExpensesSheetData}
                      updateSheetData={setProductionExpensesSheetData}
                      updateFeedDeliverySheetData={setFeedDeliverySheetData}
                      updatePigsAndBarnPigsInSheetData={setPigsAndBarnPigsInSheetData}
                      updatePigsAndBarnPigsSoldSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldSheetData
                          : setPigsAndBarPigsSoldSheetDataForNurserySession
                      }
                      updateDeathLossSheetData={setDeathLossSheetData}
                      pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
                      feedDeliverySheetData={feedDeliverySheetData}
                      deathLossSheetData={deathLossSheetData}
                      pigsAndBarnPigsSoldSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? pigsAndBarnPigsSoldSheetData
                          : pigsAndBarnPigsSoldSheetDataForNurserySession
                      }
                      productionExpenseSheetValidationErrors={
                        productionExpenseSheetValidationErrors
                      }
                      setProductionExpenseSheetValidationErrors={
                        setProductionExpenseSheetValidationErrors
                      }
                      setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
                      setPigsAndBarnPigsSoldValidationErrors={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldValidationErrors
                          : setPigsAndBarnPigsSoldValidationErrorsForNurserySession
                      }
                      setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
                      setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
                      {...commonPropsPassedToSheets}
                    />
                  </TabPane>
                  <TabPane tab="Feed Delivery" key={5}>
                    <FeedDeliverySheet
                      sheetData={feedDeliverySheetData}
                      updateSheetData={setFeedDeliverySheetData}
                      productionExpensesSheetData={productionExpensesSheetData}
                      updatePigsAndBarnPigsInSheetData={setPigsAndBarnPigsInSheetData}
                      updatePigsAndBarnPigsSoldSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldSheetData
                          : setPigsAndBarPigsSoldSheetDataForNurserySession
                      }
                      updateDeathLossSheetData={setDeathLossSheetData}
                      pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
                      updateProductionExpensesSheetData={setProductionExpensesSheetData}
                      deathLossSheetData={deathLossSheetData}
                      pigsAndBarnPigsSoldSheetData={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? pigsAndBarnPigsSoldSheetData
                          : pigsAndBarnPigsSoldSheetDataForNurserySession
                      }
                      feedDeliverySheetValidationErrors={feedDeliverySheetValidationErrors}
                      setFeedDeliverySheetValidationErrors={setFeedDeliverySheetValidationErrors}
                      setPigsAndBarnPigsInValidationErrors={setPigsAndBarnPigsInValidationErrors}
                      setPigsAndBarnPigsSoldValidationErrors={
                        currentSessionType !== Enum_Session_Type_Enum.Nursery
                          ? setPigsAndBarnPigsSoldValidationErrors
                          : setPigsAndBarnPigsSoldValidationErrorsForNurserySession
                      }
                      setDeathLossSheetValidationErrors={setDeathLossSheetValidationErrors}
                      setProductionExpenseSheetValidationErrors={
                        setProductionExpenseSheetValidationErrors
                      }
                      formControl={control}
                      {...commonPropsPassedToSheets}
                    />
                  </TabPane>
                </Tabs>
              </div>
              <div
                style={{ position: 'fixed', bottom: 20, left: activeTabKey === 5 ? '25%' : '28%' }}
              >
                <Tooltip
                  color="white"
                  title={
                    <div style={{ color: 'black' }}>
                      Save the current session when not connected to the internet. This allows you
                      to recall the current producer file and complete at later time. If you begin a
                      new producer file and are not connected to the internet, the previous profile
                      will be overwritten and lost. Save Locally only allows one producer file to be
                      saved to local device. You cannot save multiple producer files locally.
                    </div>
                  }
                  overlayInnerStyle={{ borderRadius: 5 }}
                >
                  <Button
                    style={{ color: 'white', ...buttonStyle }}
                    className="buttonColorRed"
                    size="large"
                    loading={saveToSessionBtnLoading}
                    icon={<InfoCircleTwoTone />}
                    onClick={() => {
                      if (currentSessionType) {
                        if (
                          pigsAndBarnPigsInValidationErrors.length > 0 ||
                          (currentSessionType !== Enum_Session_Type_Enum.Nursery
                            ? pigsAndBarnPigsSoldValidationErrors.length > 0
                            : pigsAndBarnPigsSoldValidationErrorsForNurserySession.length > 0) ||
                          deathLossSheetValidationErrors.length > 0 ||
                          productionExpenseSheetValidationErrors.length > 0 ||
                          feedDeliverySheetValidationErrors.length > 0
                        ) {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          message.error('Please resolve all errors');
                        } else {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          handleSubmit(() => {
                            handleSaveToDb(true, 'saveSession');
                          })();
                        }
                      } else {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        message.error(
                          `Please select session type from 'New session' and try again`,
                        );
                      }
                    }}
                  >
                    Save Locally
                  </Button>
                </Tooltip>
                <Tooltip
                  color="white"
                  overlayInnerStyle={{ borderRadius: 5 }}
                  placement="left"
                  title={
                    <div style={{ color: 'black' }}>
                      Clears current entries across all the pages and begins new session with a new
                      producer. Drop down menu allows selection of production flow type: Nursery,
                      Grow-Finish, Wean-Finish, Nursery to Grow-Finish. Nursery to Grow-Finish uses
                      Nursery PIGS OUT data for a Grow-Finish PIGS IN data inputs for same producer.
                    </div>
                  }
                >
                  <Space>
                    <Dropdown
                      overlay={buttonDropDownMenu}
                      trigger={['click']}
                      overlayStyle={{ width: 150 }}
                      placement="topCenter"
                    >
                      <Button
                        style={{ backgroundColor: '#2b78e4', ...buttonStyle, color: 'white' }}
                        size="large"
                        icon={<InfoCircleTwoTone />}
                      >
                        New Session <CaretDownOutlined style={{ color: 'white' }} />
                      </Button>
                    </Dropdown>
                  </Space>
                </Tooltip>

                <Button
                  style={{ color: '#2B79E4', ...buttonStyle }}
                  size="large"
                  loading={activeTabKey === 5 && saveToDbBtnLoading}
                  disabled={activeTabKey === 5 && saveToDbBtnLoading}
                  onClick={() => {
                    if (activeTabKey !== 5) {
                      setActiveTabKey((prevData) => prevData + 1);
                    }
                  }}
                >
                  Next
                </Button>

                <Tooltip
                  color="white"
                  title={
                    <div style={{ color: 'black' }}>
                      If you are connected to the internet, you can save the producer file data to
                      the Cloud. You can save multiple, unfinished producer files if you are
                      connected to the internet to recall and finish later once you are connected to
                      the internet again.
                    </div>
                  }
                  overlayInnerStyle={{ borderRadius: 5 }}
                >
                  <Button
                    style={{ color: 'white', backgroundColor: '#2b78e4', ...buttonStyle }}
                    icon={<InfoCircleTwoTone />}
                    size="large"
                    loading={saveToDbBtnLoading}
                    onClick={() => {
                      callToSaveToDbFunc();
                    }}
                  >
                    Save to DB
                  </Button>
                </Tooltip>
                {activeTabKey === 5 ? (
                  <Tooltip
                    color="white"
                    title={
                      <div style={{ color: 'black' }}>
                        Generate a single page, Closeout report summary
                      </div>
                    }
                    overlayInnerStyle={{ borderRadius: 5 }}
                  >
                    <Button
                      className="buttonColorRed"
                      size="large"
                      style={{
                        border: '1px solid #2B78E4',
                        borderRadius: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        marginLeft: 3,
                      }}
                      icon={<InfoCircleTwoTone />}
                      onClick={() => {
                        if (
                          calculateTotalHeadOut(
                            currentSessionType !== Enum_Session_Type_Enum.Nursery
                              ? pigsAndBarnPigsSoldSheetData
                              : pigsAndBarnPigsSoldSheetDataForNurserySession,
                          ) > calculateTotalHeadIn(pigsAndBarnPigsInSheetData)
                        ) {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          message.error(
                            'Total Head Out cannot be greater than Total Head In. Please recheck and try again',
                          );
                        } else if (
                          calculateTotalHeadOut(
                            currentSessionType !== Enum_Session_Type_Enum.Nursery
                              ? pigsAndBarnPigsSoldSheetData
                              : pigsAndBarnPigsSoldSheetDataForNurserySession,
                          ) <
                          0.5 * calculateTotalHeadIn(pigsAndBarnPigsInSheetData)
                        ) {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          message.error(
                            'Total Head Out cannot be less than 50% of Total Head In. Please recheck and try again',
                          );
                        } else {
                          makeCloseOutSummaryPageVisible();
                        }
                      }}
                    >
                      Closeout Summary
                    </Button>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <CloseOutSummaryScreen
            pigsAndBarnPigsInSheetData={pigsAndBarnPigsInSheetData}
            pigsAndBarnPigsSoldSheetData={
              currentSessionType !== Enum_Session_Type_Enum.Nursery
                ? pigsAndBarnPigsSoldSheetData
                : pigsAndBarnPigsSoldSheetDataForNurserySession
            }
            productionSheetData={productionExpensesSheetData}
            feedDeliverySheetData={feedDeliverySheetData}
            makeCloseOutSummaryPageHidden={makeCloseOutSummaryPageHidden}
            inputData={{
              producerName: watch('producerName'),
              location: watch('location'),
              groupId: watch('groupId'),
              sessionType: currentSessionType,
              feedLeft: watch('feedLeft'),
            }}
            deathLossSheetData={deathLossSheetData}
            showCloseoutSummaryPrintPreview={showCloseoutSummaryPrintPreview}
            setShowCloseOutSummaryPrintPreview={setShowCloseOutSummaryPrintPreview}
          />
        )}
      </div>
    </>
  );
};

export default DataScreen;
