import React from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import CloseOutSummaryComponent from '../components/CloseOutSummaryComponent';
import { CloseOutSummaryType } from '../utils/types';
import styles from './CloseOutSummaryScreen.module.scss';

/* React functional component */
const CloseOutSummaryScreen = (props: CloseOutSummaryType): JSX.Element => {
  return (
    <div className="closeoutSummaryPrint" style={{ paddingBottom: 75 }}>
      <div className="backBtn hideWhilePrinting">
        <Button
          type="text"
          icon={<LeftOutlined />}
          onClick={() => {
            if (props.showCloseoutSummaryPrintPreview) {
              props.setShowCloseOutSummaryPrintPreview(false);
            } else {
              props.makeCloseOutSummaryPageHidden();
            }
          }}
          style={{ marginLeft: 20, marginTop: 15, fontSize: 16 }}
        >
          {props.showCloseoutSummaryPrintPreview ? 'Back' : 'Back to Inputs'}
        </Button>
      </div>

      <CloseOutSummaryComponent
        {...props}
        calledFrom={
          props.showCloseoutSummaryPrintPreview
            ? 'closeOutSummaryPrintPreview'
            : 'closeOutSummaryPage'
        }
      />
      <div
        className={styles.commonButtonStyle}
        style={{ left: props.showCloseoutSummaryPrintPreview ? '51em' : '49em' }}
      >
        <Button
          className={`${
            props.showCloseoutSummaryPrintPreview ? styles.printButton : 'buttonColorRed'
          } hideWhilePrinting`}
          size="large"
          onClick={() => {
            if (props.showCloseoutSummaryPrintPreview) {
              window.print();
            } else {
              props.setShowCloseOutSummaryPrintPreview(true);
            }
          }}
        >
          {props.showCloseoutSummaryPrintPreview ? 'Print' : 'Print Preview'}
        </Button>
      </div>
    </div>
  );
};

export default CloseOutSummaryScreen;
