import React, { useState } from 'react';
import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import XLSX from 'xlsx';
import dayjs from 'dayjs';
import { Modal, Button, Upload, message } from 'antd';
import {
  DeathLossSheetIndividualGridElement,
  FeedDeliverySheetIndividualGridElement,
  PigsAndBarnPigsInSheetGridElement,
  PigsAndBarnPigsInSheetIndividualGridElement,
  PigsAndBarnPigsSoldSheetGridElement,
  PigsAndBarnPigsSoldSheetIndividualGridElement,
  ProductionExpensesSheetGridElement,
  ProductionExpensesSheetIndividualGridElement,
  FeedDeliverySheetGridElement,
  DeathLossSheetGridElement,
  CommonBulkUploadComponentType,
  ValidationErrorFunctionsType,
} from '../utils/types';
import {
  renderFeedDeliverySheetRows,
  renderPigsAndBarnPigsInSheetRows,
  renderPigsAndBarnPigsSoldSheetRows,
  renderDeathLossSheetRows,
  renderProductionExpensesSheetRows,
  getSerialNumberOfDate,
  removeCommaFromNumericValues,
  removeEmptyEntriesFromSheetData,
  getHeaderData,
  validationForDateColumn,
  renderPigsAndBarnPigsSoldSheetRowsForNurserySession,
} from '../utils/sheetHelpers';
import {
  dateColumnFormat,
  feedTypeOptionsValueLabelData,
  deathReasons,
  chartOfAccounts,
  bulkUploadModalStyle,
} from '../utils/sheetGlobals';
import { Enum_Session_Type_Enum } from '../graphql/graphql-types';

/* Type for selected file details to upload */
type UploadFileDetailsType = {
  file: any;
  fileList: Array<UploadFile>;
};

/* common bulk upload props type */
type CommonBulkUploadPropsType = Omit<
  CommonBulkUploadComponentType,
  | 'showCommonBulkUploadModal'
  | 'setShowCommonBulkUploadModal'
  | 'showBulkUploadModal'
  | 'setShowBulkUploadModal'
  | 'activeTabKey'
> &
  ValidationErrorFunctionsType;

/* template file url */
const commonFileDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/ecp38_bulkUploadInputData.xlsx';

const { Dragger } = Upload;

/* React functional component */
const CommonBulkUploadComponent = ({
  isCommonModalVisible,
  updateIsCommonModalVisible,
  pigsAndBarnPigsInSheetData,
  updatePigsAndBarnPigsInSheetData,
  pigsAndBarnPigsSoldSheetData,
  updatePigsAndBarnPigsSoldSheetData,
  deathLossSheetData,
  updateDeathLossSheetData,
  productionExpensesSheetData,
  updateProductionExpensesSheetData,
  feedDeliverySheetData,
  updateFeedDeliverySheetData,
  sessionType,
  setDeathLossSheetValidationErrors,
  setPigsAndBarnPigsInValidationErrors,
  setPigsAndBarnPigsSoldValidationErrors,
  setProductionExpenseSheetValidationErrors,
  setFeedDeliverySheetValidationErrors,
  selectedPigWtType,
}: CommonBulkUploadPropsType): JSX.Element => {
  /* State to store selected file details to upload */
  const [uploadFileDetails, setUploadFileDetails] = useState<UploadFileDetailsType>({
    file: null,
    fileList: [],
  });

  /* function to remove empty rows from data */
  const gridDataWithOutEmptyRows = (
    data: (
      | PigsAndBarnPigsInSheetGridElement
      | PigsAndBarnPigsSoldSheetGridElement
      | ProductionExpensesSheetGridElement
      | FeedDeliverySheetGridElement
      | DeathLossSheetGridElement
    )[][],
  ) => {
    /* Variable to store sheet data in local variable */
    const grid = data.map((row) => [...row]);
    return removeEmptyEntriesFromSheetData(grid);
  };

  /* to handle file upload data */
  const handleFileUploadData = (file: RcFile) => {
    /* File reader instance (read the contents of files stored on device) */
    const fileReader = new FileReader();

    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      /* Variable to store raw data parsed using 'xlsx' file */
      const rawData = event.target?.result;

      /* Variable to store read xlsx workbook data */
      const excelWorkBookData = XLSX.read(rawData, {
        type: 'array',
        cellDates: true,
      });

      /* pigs in sheet data converted to json form */
      const jsonFormattedSheetDataForPigsIn: PigsAndBarnPigsInSheetIndividualGridElement[] =
        excelWorkBookData.SheetNames.includes('pigsIn')
          ? XLSX.utils.sheet_to_json(excelWorkBookData.Sheets.pigsIn, {
              /* Default value of the cell if data is not present */
              defval: undefined,
              header: getHeaderData('pigsIn'),
              raw: false,
            })
          : [];

      /* pigs sold sheet data converted to json form */
      const jsonFormattedSheetDataForPigsSold: PigsAndBarnPigsSoldSheetIndividualGridElement[] =
        excelWorkBookData.SheetNames.includes('pigsSold')
          ? XLSX.utils.sheet_to_json(excelWorkBookData.Sheets.pigsSold, {
              /* Default value of the cell if data is not present */
              defval: undefined,
              header: getHeaderData('pigsSold'),
              raw: false,
            })
          : [];

      /* death loss sheet data converted to json form */
      const jsonFormattedSheetDataForDeathLoss: DeathLossSheetIndividualGridElement[] =
        excelWorkBookData.SheetNames.includes('deathLoss')
          ? XLSX.utils.sheet_to_json(excelWorkBookData.Sheets.deathLoss, {
              /* Default value of the cell if data is not present */
              defval: undefined,
              header: getHeaderData('deathLoss'),
              raw: false,
            })
          : [];

      /* production expense sheet data converted to json form */
      const jsonFormattedSheetDataForProductionExpense: ProductionExpensesSheetIndividualGridElement[] =
        excelWorkBookData.SheetNames.includes('productionExpenses')
          ? XLSX.utils.sheet_to_json(excelWorkBookData.Sheets.productionExpenses, {
              /* Default value of the cell if data is not present */
              defval: undefined,
              header: getHeaderData('productionExpenses'),
              raw: false,
            })
          : [];

      /* feed delivery sheet data converted to json form */
      const jsonFormattedSheetDataForFeedDelivery: FeedDeliverySheetIndividualGridElement[] =
        excelWorkBookData.SheetNames.includes('feedDelivery')
          ? XLSX.utils.sheet_to_json(excelWorkBookData.Sheets.feedDelivery, {
              /* Default value of the cell if data is not present */
              defval: undefined,
              header: getHeaderData('feedDelivery'),
              raw: false,
            })
          : [];

      /* pigs and barn pigs in data used to calculate some columns in pigs sold and death sheet data */
      let gridDataForFurtherCalc = pigsAndBarnPigsInSheetData;

      /* pigs and barn pigs in */
      if (
        Array.isArray(jsonFormattedSheetDataForPigsIn) &&
        jsonFormattedSheetDataForPigsIn.length > 0
      ) {
        /* const to calculate number of rows(non empty) in pigs in sheet data */
        const numberOfRowsInPigsInSheet = pigsAndBarnPigsInSheetData
          ? removeEmptyEntriesFromSheetData(pigsAndBarnPigsInSheetData).length
          : 0;

        /* to store pigs in sheet data without header */
        const sheetDataWithoutHeaderForPigsIn = jsonFormattedSheetDataForPigsIn
          .slice(1)
          .map((item, index) => {
            /* Variable to store 'head' column input from sheet */
            const head = item.head ? removeCommaFromNumericValues(item.head) : undefined;

            /* Variable to store 'avgWt' column input from sheet */
            let avgWt: number | string | undefined = undefined;
            /* Variable to store 'weight' column input from sheet */
            let weight: number | string | undefined = undefined;
            /* Variable to store 'totalVal' column input from sheet */
            let totalVal: number | string | undefined = undefined;
            /* Variable to store 'avg' column input from sheet */
            let avg: number | string | undefined = undefined;

            if (Number(head) <= 0) {
              setPigsAndBarnPigsInValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsInSheet + index, columnIndex: 1 },
              ]);
            }

            if (!Number.isInteger(Number(head))) {
              if (Number(head) > 0) {
                setPigsAndBarnPigsInValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: numberOfRowsInPigsInSheet + index, columnIndex: 1 },
                ]);
              }
            }

            /* Below calculations are added based selected pig weight for 'weight' & 'avgWt' & 'totalVal' & 'avg' */
            if (selectedPigWtType === 'totalPigWt') {
              weight = item.weight ? removeCommaFromNumericValues(item.weight) : undefined;
              avgWt = head && weight ? Number(weight) / Number(head) : undefined;
              totalVal = item.totalVal ? removeCommaFromNumericValues(item.totalVal) : undefined;
              avg = head && totalVal ? Number(totalVal) / Number(head) : undefined;
            }

            if (selectedPigWtType === 'avgPigWt') {
              avgWt = item.avgWt ? removeCommaFromNumericValues(item.avgWt) : undefined;
              weight = head && avgWt ? Number(head) * Number(avgWt) : undefined;
              avg = item.avg ? removeCommaFromNumericValues(item.avg) : undefined;
              totalVal = head && avg ? Number(head) * Number(avg) : undefined;
            }

            if (Number(weight) <= 0) {
              setPigsAndBarnPigsInValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsInSheet + index, columnIndex: 2 },
              ]);
            }

            if (Number(totalVal) < 0) {
              setPigsAndBarnPigsInValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsInSheet + index, columnIndex: 4 },
              ]);
            }

            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              head: Number(head) || undefined,
              weight: Number(weight) || undefined,
              avgWt: Number(avgWt) || undefined,
              totalVal: Number(totalVal) || undefined,
              avg: Number(avg) || undefined,
            };
          });

        gridDataForFurtherCalc = [
          ...gridDataForFurtherCalc,
          ...sheetDataWithoutHeaderForPigsIn.map((item) => [
            ...renderPigsAndBarnPigsInSheetRows(item),
          ]),
        ];
        // Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
        // the sheet
        const gridDataForPigsIn = [
          ...gridDataWithOutEmptyRows(pigsAndBarnPigsInSheetData),
          ...sheetDataWithoutHeaderForPigsIn.map((item) => [
            ...renderPigsAndBarnPigsInSheetRows(
              item,
              selectedPigWtType === 'avgPigWt' ? [2, 4] : undefined,
            ),
          ]),
        ];
        updatePigsAndBarnPigsInSheetData(gridDataForPigsIn);
      }

      /* pigs and barn pigs sold */
      if (
        Array.isArray(jsonFormattedSheetDataForPigsSold) &&
        jsonFormattedSheetDataForPigsSold.length > 0
      ) {
        /* const to calculate number of rows(non empty) in pigs sold sheet data */
        const numberOfRowsInPigsSoldSheet = pigsAndBarnPigsSoldSheetData
          ? removeEmptyEntriesFromSheetData(pigsAndBarnPigsSoldSheetData).length
          : 0;

        /* to store pigs sold sheet data without header */
        const sheetDataWithoutHeaderForPigsSold = jsonFormattedSheetDataForPigsSold
          .slice(1)
          .map((item, index) => {
            /* Variable to store 'hdOut' column input from sheet */
            const hdOut = item.hdOut ? removeCommaFromNumericValues(item.hdOut) : undefined;

            if (Number(hdOut) <= 0) {
              setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInPigsSoldSheet + index, columnIndex: 2 },
              ]);
            }
            if (!Number.isInteger(Number(hdOut))) {
              if (Number(hdOut) > 0) {
                setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: numberOfRowsInPigsSoldSheet + index, columnIndex: 2 },
                ]);
              }
            }

            /* carcass wt */
            let carcassWt: string | undefined = undefined;

            if (sessionType !== Enum_Session_Type_Enum.Nursery) {
              /* Variable to store 'carcassWt' column input from sheet */
              carcassWt = item.carcassWt ? removeCommaFromNumericValues(item.carcassWt) : undefined;

              if (Number(carcassWt) <= 0) {
                setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: numberOfRowsInPigsSoldSheet + index, columnIndex: 3 },
                ]);
              }
            }

            /* Variable to store 'liveWt' column input from sheet */
            let liveWt: number | string | undefined = undefined;
            /* Variable to store 'avgWt' column input from sheet */
            let avgWt: number | string | undefined = undefined;
            /* Variable to store 'totalVal' column input from sheet */
            let totalVal: number | string | undefined = undefined;
            /* Variable to store 'avg' column input from sheet */
            let avg: number | string | undefined = undefined;

            /* Below calculations are added based selected pig weight for 'liveWt' & 'avgWt' & 'totalVal' & 'avg' */
            if (selectedPigWtType === 'totalPigWt') {
              liveWt = item.liveWt ? removeCommaFromNumericValues(item.liveWt) : undefined;
              avgWt = liveWt && hdOut ? Number(liveWt) / Number(hdOut) : undefined;
              totalVal = item.totalVal ? removeCommaFromNumericValues(item.totalVal) : undefined;
              avg = totalVal && hdOut ? Number(totalVal) / Number(hdOut) : undefined;
            }

            if (selectedPigWtType === 'avgPigWt') {
              avgWt = item.avgWt ? removeCommaFromNumericValues(item.avgWt) : undefined;
              liveWt = avgWt && hdOut ? Number(avgWt) * Number(hdOut) : undefined;
              avg = item.avg ? removeCommaFromNumericValues(item.avg) : undefined;
              totalVal = avg && hdOut ? Number(avg) * Number(hdOut) : undefined;
            }

            if (Number(liveWt) <= 0) {
              setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                ...prevState,
                {
                  rowIndex: numberOfRowsInPigsSoldSheet + index,
                  columnIndex: sessionType !== Enum_Session_Type_Enum.Nursery ? 4 : 3,
                },
              ]);
            }

            if (Number(totalVal) < 0) {
              setPigsAndBarnPigsSoldValidationErrors((prevState) => [
                ...prevState,
                {
                  rowIndex: numberOfRowsInPigsSoldSheet + index,
                  columnIndex: sessionType !== Enum_Session_Type_Enum.Nursery ? 7 : 5,
                },
              ]);
            }

            /* variable to store is cull column from excel sheet */
            const isCull = item.isCull === 'Y' ? 'true' : null;

            /* variable to store date after checking whether user has entered valid date */
            const date =
              validationForDateColumn(item.date) && dayjs(item.date).isValid() ? item.date : '';

            /* common pigs sold sheet values */
            const pigsSoldValues = {
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              isCull: isCull,
              hdOut: Number(hdOut) || undefined,
              liveWt: Number(liveWt) || undefined,
              avgWt: Number(avgWt) || undefined,
              totalVal: Number(totalVal) || undefined,
              avg: Number(avg) || undefined,
            };

            if (sessionType !== Enum_Session_Type_Enum.Nursery) {
              return {
                ...pigsSoldValues,
                carcassWt: Number(carcassWt),
                yield: carcassWt && liveWt ? (Number(carcassWt) * 100) / Number(liveWt) : undefined,
              };
            }
            return pigsSoldValues;
          });

        // Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
        // the sheet(gridDataWithoutEmptyRows)
        const gridDataForPigsSold =
          sessionType !== Enum_Session_Type_Enum.Nursery
            ? [
                ...gridDataWithOutEmptyRows(pigsAndBarnPigsSoldSheetData),
                ...sheetDataWithoutHeaderForPigsSold.map((item) => [
                  ...renderPigsAndBarnPigsSoldSheetRows(
                    item,
                    selectedPigWtType === 'avgPigWt' ? [4, 5, 7] : undefined,
                  ),
                ]),
              ]
            : [
                ...gridDataWithOutEmptyRows(pigsAndBarnPigsSoldSheetData),
                ...sheetDataWithoutHeaderForPigsSold.map((item) => [
                  ...renderPigsAndBarnPigsSoldSheetRowsForNurserySession(
                    item,
                    selectedPigWtType === 'avgPigWt' ? [4, 5, 7] : undefined,
                  ),
                ]),
              ];
        updatePigsAndBarnPigsSoldSheetData(gridDataForPigsSold);
      }

      /* death loss */
      if (
        Array.isArray(jsonFormattedSheetDataForDeathLoss) &&
        jsonFormattedSheetDataForDeathLoss.length > 0
      ) {
        /* const to calculate number of rows(non empty) in death loss sheet data */
        const numberOfRowsInDeathLossSheet = deathLossSheetData
          ? removeEmptyEntriesFromSheetData(deathLossSheetData).length
          : 0;

        // stores the value  from pigsAndBarnPigsInSheetData required for calculation
        let valueCalculatedFromInSheet = 0;

        if (Array.isArray(gridDataForFurtherCalc) && gridDataForFurtherCalc.length > 0) {
          // stores the value of sum Product of date and head
          let sumProductOfDateAndHead = 0;

          // stores the value of sum of head
          let sumOfHead = 0;

          gridDataForFurtherCalc.forEach((item) => {
            if (item[0].value !== null && item[1].value !== null) {
              const dateSeries = getSerialNumberOfDate(item[0].value as string);
              sumProductOfDateAndHead += dateSeries * Number(item[1].value);
              sumOfHead += Number(item[1].value);
            }
            valueCalculatedFromInSheet = sumProductOfDateAndHead / sumOfHead;
          });
        }
        /* to store death loss sheet data without header */
        const sheetDataWithoutHeaderForDeathLoss = jsonFormattedSheetDataForDeathLoss
          .slice(1)
          .map((item, index) => {
            // finds if reason entered is correct
            const reason = deathReasons.find(
              (val) => val.toLowerCase() === item.reason?.toString().toLowerCase(),
            );

            /* Variable to store 'dead' column input from sheet */
            const dead = item.dead ? removeCommaFromNumericValues(item.dead) : undefined;
            if (Number(dead) <= 0) {
              setDeathLossSheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInDeathLossSheet + index, columnIndex: 1 },
              ]);
            }
            if (!Number.isInteger(Number(dead))) {
              if (Number(dead) > 0) {
                setDeathLossSheetValidationErrors((prevState) => [
                  ...prevState,
                  { rowIndex: numberOfRowsInDeathLossSheet + index, columnIndex: 1 },
                ]);
              }
            }

            /* Variable to store 'weight' column input from sheet */
            const weight = item.weight ? removeCommaFromNumericValues(item.weight) : undefined;

            if (Number(weight) <= 0) {
              setDeathLossSheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInDeathLossSheet + index, columnIndex: 2 },
              ]);
            }

            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              dead: Number(dead) || undefined,
              weight: Number(weight) || undefined,
              reason: reason ? item.reason : undefined,
              deathWeek: (getSerialNumberOfDate(item.date) - valueCalculatedFromInSheet) / 7,
            };
          });
        // Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
        // the sheet(gridDataWithoutEmptyRows)
        const gridDataForDeathLoss = [
          ...gridDataWithOutEmptyRows(deathLossSheetData),
          ...sheetDataWithoutHeaderForDeathLoss.map((item) => [...renderDeathLossSheetRows(item)]),
        ];
        updateDeathLossSheetData(gridDataForDeathLoss);
      }

      /* production expense  */
      if (
        Array.isArray(jsonFormattedSheetDataForProductionExpense) &&
        jsonFormattedSheetDataForProductionExpense.length > 0
      ) {
        /* const to calculate number of rows(non empty) in production sheet data */
        const numberOfRowsInProductionSheet = productionExpensesSheetData
          ? removeEmptyEntriesFromSheetData(productionExpensesSheetData).length
          : 0;

        /* to store production expense sheet data without header */
        const sheetDataWithoutHeaderForProductionExpenses =
          jsonFormattedSheetDataForProductionExpense.slice(1).map((item, index) => {
            /* Variable to store 'totalCost' column input from sheet */
            const totalCost = item.totalCost
              ? removeCommaFromNumericValues(item.totalCost)
              : undefined;

            if (Number(totalCost) < 0) {
              setProductionExpenseSheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInProductionSheet + index, columnIndex: 3 },
              ]);
            }

            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              description: item.description || '',
              account: item.account && chartOfAccounts.includes(item.account) ? item.account : '',
              totalCost: Number(totalCost) || undefined,
            };
          });
        // Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
        // the sheet(gridDataWithOutEmptyRows)
        const gridDataForProductionExpenses = [
          ...gridDataWithOutEmptyRows(productionExpensesSheetData),
          ...sheetDataWithoutHeaderForProductionExpenses.map((item) => [
            ...renderProductionExpensesSheetRows(item),
          ]),
        ];
        updateProductionExpensesSheetData(gridDataForProductionExpenses);
      }

      /* feed delivery */
      if (
        Array.isArray(jsonFormattedSheetDataForFeedDelivery) &&
        jsonFormattedSheetDataForFeedDelivery.length > 0
      ) {
        /* const to calculate number of rows(non empty) in feed delivery data */
        const numberOfRowsInFeedDeliverySheet = feedDeliverySheetData
          ? removeEmptyEntriesFromSheetData(feedDeliverySheetData).length
          : 0;

        /* to store feed delivery sheet data without header */
        const sheetDataWithoutHeaderForFeedDelivery = jsonFormattedSheetDataForFeedDelivery
          .slice(1)
          .map((item, index) => {
            /* selected feed type */
            const selectedFeedType = feedTypeOptionsValueLabelData.find(
              (val) => val.value.toLowerCase() === item.feedType?.toString().toLowerCase(),
            );

            /* Variable to store 'poundsDelivered' column input from sheet */
            const poundsDelivered = item.poundsDelivered
              ? removeCommaFromNumericValues(item.poundsDelivered)
              : undefined;

            if (Number(poundsDelivered) <= 0) {
              setFeedDeliverySheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInFeedDeliverySheet + index, columnIndex: 4 },
              ]);
            }

            /* Variable to store 'actualFeedCost' column input from sheet */
            const actualFeedCost = item.actualFeedCost
              ? removeCommaFromNumericValues(item.actualFeedCost)
              : undefined;

            if (Number(actualFeedCost) < 0) {
              setFeedDeliverySheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInFeedDeliverySheet + index, columnIndex: 5 },
              ]);
            }

            /* variable to store 'pounds of corn' column input from sheet */
            const poundsOfCorn = item.poundsOfCorn
              ? item.poundsOfCorn.toString().replace(',', '')
              : undefined;

            if (Number(poundsOfCorn) <= 0) {
              setFeedDeliverySheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInFeedDeliverySheet + index, columnIndex: 6 },
              ]);
            }

            /* variable to store 'cost of corn' column input from sheet */
            const costOfCorn = item.costOfCorn
              ? item.costOfCorn.toString().replace(',', '')
              : undefined;

            if (Number(costOfCorn) < 0) {
              setFeedDeliverySheetValidationErrors((prevState) => [
                ...prevState,
                { rowIndex: numberOfRowsInFeedDeliverySheet + index, columnIndex: 7 },
              ]);
            }

            /* variable to store date after checking whether user has entered valid date */
            const date =
              dayjs(item.date).isValid() && validationForDateColumn(item.date) ? item.date : '';

            return {
              // delivery: item.delivery
              //   ? Number(item.delivery) + deathLossSheetData.length - 1
              //   : index + deathLossSheetData.length,
              date: date ? dayjs(date).format(dateColumnFormat) : '',
              feedType: selectedFeedType ? item.feedType : '',
              poundsDelivered: Number(poundsDelivered) || undefined,
              actualFeedCost: Number(actualFeedCost) || undefined,
              feedDetails: item.feedDetails || '',
              poundsOfCorn: Number(poundsOfCorn) || undefined,
              costOfCorn: Number(costOfCorn) || undefined,
            };
          });
        // Following logic ensures that no empty rows will be present in the sheet after user uses bulk upload to add data to
        // the sheet(gridDataWithoutEmptyRows)
        const gridDataForFeedDelivery = [
          ...gridDataWithOutEmptyRows(feedDeliverySheetData),
          ...sheetDataWithoutHeaderForFeedDelivery.map((item) => [
            ...renderFeedDeliverySheetRows(item),
          ]),
        ];

        /* Code commented for future use */
        // gridDataForFeedDelivery.forEach((item, index) => {
        //   if (index !== 0) {
        //     item[0].value = index;
        //   }
        // });

        updateFeedDeliverySheetData(gridDataForFeedDelivery);
      }
      updateIsCommonModalVisible(false);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success('Data has been successfully uploaded.');
    };
    fileReader.readAsArrayBuffer(file);
  };

  return (
    <Modal
      visible={isCommonModalVisible}
      title="ALL OR CURRENT PAGE DATA UPLOAD"
      footer={null}
      closeIcon={<CloseOutlined style={{ fontSize: 18 }} />}
      centered
      destroyOnClose
      width="50%"
      onCancel={() => {
        updateIsCommonModalVisible(false);
        setUploadFileDetails({ file: null, fileList: [] });
      }}
      bodyStyle={bulkUploadModalStyle}
    >
      <div>
        <Button className="buttonColorRed" type="primary" href={commonFileDownloadUrl}>
          Download template file
        </Button>
        <div style={{ fontStyle: 'italic', marginTop: 15 }}>
          Supported file types are: <span style={{ fontWeight: 'bold' }}>.xls</span> &{' '}
          <span style={{ fontWeight: 'bold' }}>.xlsx</span>
        </div>
        <Dragger
          maxCount={1}
          className="draggerStyle"
          beforeUpload={(file) => {
            if (
              file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || // for .xlsx file
              file.type === 'application/vnd.ms-excel' // for .xsl file
            ) {
              handleFileUploadData(file);
            } else {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error('Only .xls & .xlsx files are allowed');
            }
            return false;
          }}
          onRemove={() => {
            setUploadFileDetails({ file: null, fileList: [] });
            return false;
          }}
          fileList={uploadFileDetails.fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
      </div>
    </Modal>
  );
};

export default CommonBulkUploadComponent;
